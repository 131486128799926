import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import SaleSectionsMenu from "./SaleSectionsMenu";

export default function MoreMenuPopover({
  isOpen,
  onOpen,
  onClose,
  anchorEl,
  selectedRow,
  sections,
  handleMoveSection,
}) {
  const moveToRef = React.useRef();
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
  const handleClose = () => {
    onClose();
  };
  const handleOpenSubMenu = (e, task, index) => {
    setSubMenuAnchorEl(e.currentTarget);
    setIsSubMenuOpen(true);
  };
  const handleCloseSubMenu = () => {
    setIsSubMenuOpen(false);
    setSubMenuAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <List sx={{ maxHeight: "20rem" }}>
          <ListItem ref={moveToRef} sx={{ padding: ".2rem" }}>
            <ListItemButton onClick={handleOpenSubMenu}>
              <ListItemText>
                <Typography sx={{ fontSize: "0.8rem" }}>Move to</Typography>
              </ListItemText>
              <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                <ChangeCircleIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>

      <SaleSectionsMenu
        isOpen={isSubMenuOpen}
        onOpen={handleOpenSubMenu}
        onClose={handleCloseSubMenu}
        anchorEl={subMenuAnchorEl}
        sections={sections}
        handleMoveSection={handleMoveSection}
        closeParentPopover={handleClose}
        selectedRow={selectedRow}
      />
    </div>
  );
}
