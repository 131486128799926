import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack';
import Scrollbar from 'src/components/Scrollbar';
// material
import { Stack, Drawer, Divider, IconButton } from '@mui/material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { set_blank_target } from 'src/utils/DomUtils';
import moment from 'moment';
const useStyles = makeStyles(({ theme }) => ({
  textArea: {
    '&.Mui-focused': { outline: 'green' },
  },
  edit: {
    marginLeft: '.2rem',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  editCommentIcon: {
    fontSize: '1rem',
  },
  formMargin: {
    marginTop: 10,
  },
  cancelBtn: {
    width: '100%',
    marginBlock: '.15rem',
    alignSelf: 'center',
    marginRight: '.1rem',
    marginLeft: '.5rem',
    '&:hover': {
      cursor: 'pointer',
      color: 'green',
    },
  },
  noShadow: {
    boxShadow: 'none',
  },
}));
export default function ProjectDrawer({ projectDetail, onClose, isOpen }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const getHtml = (html) => {
    return { __html: html };
  };
  const applyBlankTargetOnAnchorTag = (id) => {
    set_blank_target(id);
  };
  useEffect(() => {
    if (projectDetail) {
      setTimeout(() => {
        applyBlankTargetOnAnchorTag('project-drawer-team');
      }, 200);
    }
  }, [projectDetail, isOpen]);
  return (
    <>
      <Drawer
        anchor='right'
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: { width: 380, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ px: 1 }}>
          <IconButton onClick={onClose}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar sx={{ height: 1 }}>
          <div style={{ marginTop: 10, padding: 10 }}>
            <div id='project-drawer-team'>
              <Typography className='mb-2 ' style={{ fontsize: 16, fontWeight: 'bold' }}>
                {projectDetail.project_name}
              </Typography>
              <div
                className='mb-2'
                dangerouslySetInnerHTML={getHtml(projectDetail.project_description)}
              ></div>
              <Typography className='mb-2 ' style={{ fontsize: 16, fontWeight: 'bold' }}>
                Start Date
              </Typography>
              <Typography className='mb-2 '>
                {moment(projectDetail.start_date).format('DD MMM, YYYY')}
              </Typography>
              <Typography className='mb-2 ' style={{ fontsize: 16, fontWeight: 'bold' }}>
                End Date
              </Typography>
              <Typography className='mb-2 '>
                {moment(projectDetail.end_date).format('DD MMM, YYYY')}
              </Typography>
            </div>
          </div>
        </Scrollbar>
        {/* confirmation dialog */}
      </Drawer>
    </>
  );
}
