import React, { useEffect, useMemo, useRef, useState } from "react";
import { logmodeis } from "src/config/config";
import {
  Typography,
  Grid,
  Collapse,
  Stack,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { Iconify, SaleCard } from "..";
import { makeStyles } from "@mui/styles";
import { noTask } from "src/assets";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import { allSalesListingDetail } from "src/DAL/Project/Project";

const icon_color = "#66727c";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const SaleSectionUnOptimized = ({
  section,
  query,
  setLoading,
  filters,
  onSectionAction,
  onSaleAction,
  sectionSales,
  associates,
  reRender,
  setReRender
}) => {
  const {
    expanded, // this is used to auto expand first sectionSales
    is_default,
    section_id,
    section_name,
    section_order,
    section_sales,
  } = section;
  const classes = useStyles();
  const [show, setShow] = useState(false); // here to add expand functionality
  const location = useLocation();
  const navigate = useNavigate();
  const sectionWrapperRef = useRef();
  const [saleSectionDetail, setSaleSectionDetail] = useState("");
  const [count, setCount] = useState("");
  const [iswaiting, setIswaiting] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(300);
  const { enqueueSnackbar } = useSnackbar();
  const locationState = location.state;
  if (process.env.NODE_ENV == logmodeis) {
    console.log(section, "section--");
  }
  /* <<--------------------------------------------------------->> */

  const fetchSalesBySection = async () => {
    const result = await allSalesListingDetail(
      section.section_id,
      page,
      rowsPerPage,
      query
    );
    if (result.code == 200) {
      console.log("required",reRender )
      setIswaiting(false);
      setSaleSectionDetail(result.section_sales_list);
      setCount(result.total_pages);
    } else {
      setIswaiting(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  
  const handleOnSaleAction = async (...props) => {
    const result = await onSaleAction(...props);
    if (result) {
      saleSectionDetail.map((saleSection, index) => {
        if (result.resp._id == saleSection.id) {
          saleSectionDetail[index][result.name] = result.resp[result.name];
          setSaleSectionDetail([...saleSectionDetail]);
        }
      });
    }
  };
  useEffect(() => {
    fetchSalesBySection();
  }, [show, page,rowsPerPage, query, reRender]);
  const handleExpand = () => {
    setShow(true);
  };
  const handleToggle = () => {
    setShow((prev) => !prev);
  };
  const handleCollapse = () => {
    setShow(false);
  };
  const handleScroll = () => {
    if (!locationState) {
      return;
    }
    if (locationState.display_section_id) {
      if (
        String(locationState.display_section_id) === String(section.section_id)
      ) {
        const el = sectionWrapperRef.current;
        el.scrollIntoView({
          block: "center",
        });
        el.style.transition = "all .5s linear";
        el.style.outline = "solid 3px rgb(10, 238, 124)";
        setTimeout(() => {
          el.style.outline = "none";
        }, 3000);
        navigate("/all-sales-listing/all", { replace: true, state: null });
      }
    }
  };
  /* <<--------------------------------------------------------->> */
  useEffect(() => {
    console.log(
      section,
      query,
      setLoading,
      filters,
      onSectionAction,
      onSaleAction,
      sectionSales,
      associates,
      "Sales"
    );
    setTimeout(() => {
      handleScroll();
    }, 500);
  }, []);

  
  
  /* <<--------------------------------------------------------->> */
  return (
    <div ref={sectionWrapperRef} className="section-wrapper">
      {/* {console.log(section,"ccccc")} */}
      <Grid
        alignItems="center"
        container
        className="pointer"
        onClick={() => handleToggle()}
      >
        <Grid item xs={8} sm={9} md={10}>
          <Typography className="theme-text-color sale-section-name">
            {section.section_name}
          </Typography>
        </Grid>
        
        <Grid item xs={12} sm={3} md={2}>
          <Stack
            spacing={1}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <span className="def-sale-section-count">
              {section.section_sales}
            </span>
            <div style={{ transform: "translateY(-2px)" }}>
              <Iconify
                style={{ color: icon_color }}
                icon={show ? "ep:arrow-up-bold" : "ep:arrow-down-bold"}
              />
            </div>
          </Stack>
        </Grid>
      </Grid>
      <Collapse in={show}>
        <Stack spacing={2} pt={2}>
          {console.log(saleSectionDetail, "saleSectionDetail---")}
          {iswaiting && <CircularProgress className={classes.loading} color="primary" />}
          {saleSectionDetail.length > 0 &&
            saleSectionDetail?.map((sale) => (
              <SaleCard
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                onSaleAction={handleOnSaleAction}
                count={count}
                saleSectionDetail={saleSectionDetail}
                associates={associates}
                // onSaleAction={onSaleAction}
                key={sale.id}
                sale={sale}
              />
            ))}
          <TablePagination
            className="pagination-select"
            component="div"
            rowsPerPageOptions={[
              300,
              600,
              900,
              section.section_sales > 900 ? section.section_sales : 1000,
            ]}
            count={section.section_sales}
            page={page}
            onPageChange={() => {
              setPage(page + 1);
            }}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(e.target.value);
            }}
          />
          {sectionSales.length === 0 && (
            <div className="col-12 mt-5">
              <img
                src={noTask}
                alt="no task"
                width="95px"
                style={{ margin: "auto" }}
              />
              <Typography
                style={{
                  textAlign: "center",
                  paddingLeft: 50,
                  paddingTop: 10,
                }}
              >
                No Sales Found
              </Typography>
            </div>
          )}
        </Stack>
      </Collapse>
    </div>
  );
};
const SaleSection = ({
  section,
  query,
  filters,
  onSectionAction,
  onSaleAction,
  associates,
  saleSectionDetail,
  reRender,
  setReRender
}) => {
  // console.log(filters, "filters--");
  const sectionSales = useMemo(
    () => handleFilter(),
    [query, filters, associates, saleSectionDetail,reRender,setReRender]
  );
  function handleFilter() {
    return section;
  }

  // function handleFilter() {
  //   let filtered = section.section_sales.filter(
  //     (item) =>
  //       String(item.sale_title)
  //         .toLocaleLowerCase()
  //         .indexOf(String(query).toLocaleLowerCase()) !== -1
  //   );

  //   if (String(filters.sorting.value) === "due-date") {
  //     const getDate = (item) => {
  //       if (!item.sale_target_date) {
  //         return "";
  //       }
  //       if (!item) {
  //         return "";
  //       }
  //       return item.sale_target_date;
  //     };
  //     const sorted = [...filtered].sort((a, b) => {
  //       if (!a.sale_target_date || b.sale_target_date === "") {
  //         return 1;
  //       }
  //       let targetDateA = getDate(a);
  //       let targetDateB = getDate(b);
  //       let result = moment(targetDateA).isAfter(targetDateB);
  //       if (result === true) {
  //         return 1;
  //       }
  //       if (result === false) {
  //         return -1;
  //       }
  //       return 0;
  //     });
  //     filtered = [...sorted];
  //   }
  //   if (filters.mySale === true) {
  //     let mine_only = [...filtered].filter(
  //       (item) => item.is_sale_assigned === true
  //     );
  //     filtered = [...mine_only];
  //   }

  //   if (filters.selectedProducts.length > 0) {
  //     let matched = [];

  //     //   filters.selectedProducts.forEach((prod) => {
  //     //     let match = filtered.find(
  //     //       (item) => String(item.product_id) === String(prod.product_id)
  //     //     );
  //     //     if (match) {
  //     //       matched.push(match);
  //     //     }
  //     //   });

  //     //   const items_set = new Set([...matched]);
  //     //   const unique = [...items_set];
  //     //   filtered = [...unique];
  //     // }

  //     filters.selectedProducts.forEach((prod) => {
  //       let match = filtered.filter(
  //         (item) => String(item.product_id) === String(prod.product_id)
  //       );
  //       if (match.length != 0) {
  //         matched = [...matched, ...match];
  //       }
  //     });
  //     filtered = [...matched];
  //   }

  //   return filtered;
  // }
  const optimized = useMemo(
    () => (
      <SaleSectionUnOptimized
        section={section}
        query={query}
        filters={filters}
        onSectionAction={onSectionAction}
        onSaleAction={onSaleAction}
        associates={associates}
        sectionSales={sectionSales}
        saleSectionDetail={saleSectionDetail}
        reRender={reRender}
        setReRender={setReRender}
      />
    ),
    [section, associates, sectionSales, query]
  );
  /* <<--------------------------------------------------------->> */
  /* <<--------------------------------------------------------->> */
  return optimized;
};

export default SaleSection;
