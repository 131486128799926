import { Icon } from '@iconify/react';
import { Tooltip } from '@mui/material';
import { REACTION_ICONS } from 'src/constants';

const Emojis = ({ onEmojiClick }) => {
  return (
    <div className='emoji-bar'>
      {REACTION_ICONS.map((emoji, i) => {
        return (
          <Tooltip key={i} title={emoji.label}>
            <div className='emoji-container pointer'>
              <Icon
                style={{ margin: '0.3rem' }}
                height={'1rem'}
                width='1rem'
                icon={emoji.icon}
                key={emoji.key}
                onClick={() => onEmojiClick(emoji)}
              />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};
export default Emojis;
