import {
  Button,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/material/styles";
import { logmodeis } from "src/config/config";
import { tooltipClasses } from "@mui/material/Tooltip";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: `rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset`,
    // boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  zIndex: "5",
}));
/* <<--------------------------------------------------------->> */
/* <<--------------------------------------------------------->> */
const TaskTimePicker = (props) => {
  /* <<--------------------------------------------------------->> */
  const { disabled, endTime, onSave, task } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  /* <<--------------------------------------------------------->> */
  const handleOpen = () => {
    if (disabled) {
      return;
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dimColor = () => {
    return false;
    // if (task.isCreator === true) {
    //   if (!task.targetDate) {
    //     return true;
    //   }
    //   if (task.targetDate) {
    //     return false;
    //   }
    // }
    // if (task.isCreator === false) {
    //   if (task.isAssigned === true) {
    //     if (!task.targetDate) {
    //       return true;
    //     }
    //     if (task.targetDate) {
    //       return false;
    //     }
    //   }
    // }
    // if (task.isAssigned === false) {
    //   return true;
    // }
  };
  const getFormattedStartEndTime = () => {
    if (!endTime) {
      return "Select time";
    }
    const f_end = moment(endTime).toObject();

    if (!f_end) {
      if(process.env.NODE_ENV == logmodeis){console.log("No time !");}
      return "Select time";
    }
    let hours = moment(endTime).format("LT");

    return `${hours}`;
  };
  const handleSaveTime = (end) => {
    const target_date = task.targetDate;
    if(process.env.NODE_ENV == logmodeis){console.log(target_date, "task-target-date");}
    const e_time = moment(end).isValid();
    if(process.env.NODE_ENV == logmodeis){console.log(e_time, "e_time");}
    if (!e_time) {
      enqueueSnackbar("Invalid time", { variant: "error" });
      return;
    }
    const target_date_obj = moment(target_date).toObject();
    const end_time = moment(end).toISOString();
    if(process.env.NODE_ENV == logmodeis){console.log(end_time, "original iso");}
    const clone_end_time = { ...moment(end).toObject() };
    if(process.env.NODE_ENV == logmodeis){console.log(clone_end_time, "clone_end_time");
    console.log(target_date_obj, "target_date_obj");}

    const manipulated = moment(end_time)
      .year(target_date_obj.years)
      .month(target_date_obj.months)
      .date(target_date_obj.date);
    const formatted_manipulated_end_date = moment(manipulated).toISOString();
    if(process.env.NODE_ENV == logmodeis){console.log(formatted_manipulated_end_date, "manip");}
    /* pass this manipulated to api */
    if (!end_time) {
      if(process.env.NODE_ENV == logmodeis){console.log("No time !");}
      return;
    }
    if (end_time === "Invalid date") {
      if(process.env.NODE_ENV == logmodeis){console.log("Invalid time !");}
      enqueueSnackbar("Invalid time", { variant: "error" });
      return;
    }
    if(process.env.NODE_ENV == logmodeis){console.log("CONTINUE");}
    onSave(formatted_manipulated_end_date);
    handleClose();
  };
  const showPicker = () => {
    if (task.isCreator) {
      return true;
    }
    if (task.isAssigned === true) {
      return true;
    }
    if (task.isAssigned === false) {
      if (!task.end_time) {
        return false;
      }
      return true;
    }
    return false;
  };
  /* <<--------------------------------------------------------->> */
  return (
    <div>
      {showPicker() ? (
        <span style={{ width: "fit-content" }}>
          <LightTooltip
            open={open}
            title={
              <>
                <PickerComponent
                  endTime={endTime}
                  onSave={handleSaveTime}
                  onClose={handleClose}
                  disabled={disabled}
                />
              </>
            }
          >
            <span>
              <Tooltip
                title={
                  !task.targetDate
                    ? "Please select target date first!"
                    : "Target time"
                }
              >
                <Typography
                  sx={{
                    width: "fit-content",
                    fontSize: "12px",
                    mt: "4px",
                    color: dimColor()
                      ? (theme) => theme.palette.text.disabled
                      : "rgb(0, 171, 85)",
                  }}
                  onClick={handleOpen}
                  className="time-display"
                >
                  {getFormattedStartEndTime()}
                </Typography>
              </Tooltip>
            </span>
          </LightTooltip>
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TaskTimePicker;

const PickerComponent = (props) => {
  const [endTime, setEndTime] = useState(props.endTime);
  /* <<--------------------------------------------------------->> */

  const onChangeEndTime = (newValue) => {
    setEndTime(newValue);
  };
  /* <<--------------------------------------------------------->> */

  return (
    <div id="task-time-picker">
      <Stack spacing={3} sx={{ px: 2, py: 1.5 }}>
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Icon
            onClick={props.onClose}
            className="pointer"
            icon="akar-icons:cross"
            height="0.8rem"
            width="0.8rem"
          />
        </Stack>
        <div className="end-time">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopTimePicker
              disabled={props.disabled}
              label="End Time"
              views={["hours", "minutes"]}
              value={endTime}
              onChange={(newValue) => {
                onChangeEndTime(newValue);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Button
            onClick={() => props.onSave(endTime)}
            size="small"
            variant="contained"
            disabled={props.disabled}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};
