import {
  Container,
  IconButton,
  MenuItem,
  Typography,
  TextField,
  Select,
  InputLabel,
  FormControl,
  Grid,
  FormLabel,
} from '@mui/material';
import { logmodeis } from 'src/config/config';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect } from 'react';
import Page from 'src/components/Page';
import { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import CkEditor from 'src/components/editor/CKEditor';
import Loader from 'src/components/Loader';
import {
  activeProductListing,
  addSaleByTeam,
  saleSectionListingForAddSale,
} from 'src/DAL/Project/Project';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useSocket } from 'src/Hooks/socketContext';
const AddSale = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { getUserProfileData } = useSocket();
  const user = getUserProfileData();
  const [inputs, setInputs] = useState({
    associateEmail: '',
    clientEmail: '',
    associateId: '',
    productId: '',
    sectionId: '',
    saleDescription: '',
    firstName: '',
    lastName: '',
    paidStatus: '',
    status: 'true',
    phone: '',
  });
  const [productList, setProductList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [loaders, setLoaders] = useState({
    productList: false,
    sectionList: false,
    userProfile: false,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    let is_allow = user?.sales_option;
    if (!is_allow) {
      navigate('/project');
      return;
    }
    if (String(is_allow) === '0') {
      navigate('/project');
      return;
    }
    const formData = new FormData();
    let client_email = inputs.clientEmail;
    client_email = client_email.trim();
    client_email = client_email.toLowerCase();
      
    formData.append('first_name', inputs.firstName);
    formData.append('last_name', inputs.lastName);
    formData.append('client_email', client_email);
    formData.append('product_id', inputs.productId);
    formData.append('status', inputs.status);
    formData.append('paid_status', inputs.paidStatus);
    formData.append('section_id', inputs.sectionId);
    formData.append('sale_description', inputs.saleDescription);
    formData.append('phone', inputs.phone);
    if(process.env.NODE_ENV == logmodeis){console.log(...formData, 'add-sale-req');}
    setIsAdding(true);
    const result = await addSaleByTeam(formData);
    if (result.code === 200) {
      setIsAdding(false);
      navigate(-1);
      enqueueSnackbar('Sale added', { variant: 'success' });
    } else {
      setIsAdding(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const handleChange = (e) => {
    const { target } = e;
    setInputs({ ...inputs, [target.id]: target.value });
  };
  const getActiveSectionList = async () => {
    setLoaders({ ...loaders, ['sectionList']: true });
    // const formData = new FormData();
      
    const result = await saleSectionListingForAddSale();
    if (result.code === 200) {
      setLoaders({ ...loaders, ['sectionList']: false });
      if(process.env.NODE_ENV == logmodeis){console.log(result, 'section-list');}
      //   let filtered = result.products_list.filter((product) => String(product.status) !== '0');
      setSectionList(result.active_sale_section);
    } else {
      setLoaders({ ...loaders, ['sectionList']: false });
      if(process.env.NODE_ENV == logmodeis){console.log(result, 'section-list');}
    }
  };
  const getActiveProductList = async () => {
    setLoaders({ ...loaders, ['productList']: true });
    // const formData = new FormData();
      
    const result = await activeProductListing();
    if (result.code === 200) {
      setLoaders({ ...loaders, ['productList']: false });
      if(process.env.NODE_ENV == logmodeis){console.log(result, 'product-list');}
      let filtered = result.products_list.filter((product) => String(product.status) !== '0');
      setProductList(filtered);
    }
  };
  const handleChangeNumber = (e) => {
    let target_text = e.target.value;
    if (target_text === ' ') {
      return;
    }
    const specialChars = new RegExp(/[`!@#$%^&*()_\=\[\]{};':"\\|,.<>\/?~]/);
    // const specialChars = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
    const special_char_result = specialChars.test(target_text);
    // console.log(special_char_result, 'rez-special');
    const reg = new RegExp(/[a-zA-Z]+/g);
    const result = reg.test(target_text);
    if (result === false && special_char_result === false) {
      setInputs({ ...inputs, ['phone']: target_text.trim() });
    }
  };
  useEffect(() => {
    getActiveSectionList();
    getActiveProductList();
  }, []);
  let is_allow = user.sales_option;
  if (!is_allow) {
    return <Navigate to='/project' />;
  }
  if (String(is_allow) === '0') {
    return <Navigate to='/project' />;
  }
  if (loaders.productList === true || loaders.sectionList === true) {
    return <Loader />;
  }
  return (
    <Page title='Add Sale'>
      <Container maxWidth='xl'>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h4' sx={{ mb: 5 }} style={{ textAlign: 'center' }}>
          Add Sale
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin='dense'
                required
                value={inputs.firstName}
                onChange={(e) => handleChange(e)}
                id='firstName'
                label='Client First Name'
                type='text'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin='dense'
                required
                value={inputs.lastName}
                onChange={(e) => handleChange(e)}
                id='lastName'
                label='Client Last Name'
                type='text'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin='dense'
                required
                value={inputs.clientEmail}
                onChange={(e) => handleChange(e)}
                id='clientEmail'
                label='Client Email'
                type='email'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin='dense'
                value={inputs.phone}
                // onChange={(e) => handleChange(e)}
                onChange={(e) => handleChangeNumber(e)}
                id='phone'
                label='Phone'
                type='text'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth required>
                <InputLabel id='demo-simple-select-label'>Product</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={inputs.productId}
                  label='Product'
                  MenuProps={{ sx: { maxHeight: '20rem' } }}
                  onChange={(e) => setInputs({ ...inputs, ['productId']: e.target.value })}
                >
                  {productList.map((product, index) => (
                    <MenuItem key={index} value={product.product_id}>
                      {product.product_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth required>
                <InputLabel id='demo-simple-select-label'>Section</InputLabel>
                <Select
                  id='sectionId'
                  value={inputs.sectionId}
                  label='Product'
                  name='Product'
                  onChange={(e) => setInputs({ ...inputs, ['sectionId']: e.target.value })}
                >
                  {sectionList.map((x, i) => (
                    <MenuItem key={i} value={x.sale_section_id}>
                      {x.sale_section_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id='demo-simple-select-label'>Paid Status</InputLabel>
                <Select
                  id='paidStatus'
                  value={inputs.paidStatus}
                  MenuProps={{ sx: { maxHeight: '20rem' } }}
                  label='Paid Status'
                  name='Paid Status'
                  onChange={(e) => setInputs({ ...inputs, ['paidStatus']: e.target.value })}
                >
                  <MenuItem value='0'>Free</MenuItem>
                  <MenuItem value='1'>Paid</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Sale Description :</FormLabel>
              <div className='product-description w-100'>
                <CkEditor
                  content={inputs.saleDescription}
                  setContent={(value) => setInputs({ ...inputs, ['saleDescription']: value })}
                />
              </div>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <LoadingButton
                size='medium'
                type='submit'
                color='primary'
                variant='contained'
                loading={isAdding}
              >
                Add Sale
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
};

export default AddSale;
