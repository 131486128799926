import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';
import { status_color_and_value } from 'src/constants';

const VerifiedIcon = ({ anchorRef, forIconButton }) => {
  const getVerifiedColor = () => {
    let option = status_color_and_value.find((option) => String(option.value) === String(5));
    return option.color;
  };
  if (forIconButton) {
    return (
      <CheckCircleIcon
        ref={anchorRef ? anchorRef : null}
        htmlColor={getVerifiedColor()}
        style={{ fontSize: '.8rem', height: '0.8rem', width: '0.8rem' }}
      />
    );
  }
  return <CheckCircleIcon ref={anchorRef ? anchorRef : null} htmlColor={getVerifiedColor()} />;
};

VerifiedIcon.propTypes = {
  ref: PropTypes.any,
  forIconButton: PropTypes.bool.isRequired,
};
export default VerifiedIcon;
