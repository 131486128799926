import { Icon } from "@iconify/react";
import { useState } from "react";
import searchFill from "@iconify/icons-eva/search-fill";
// material
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Input,
  Slide,
  Button,
  InputAdornment,
  ClickAwayListener,
  IconButton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { debounce } from "lodash";
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 45;
const APPBAR_DESKTOP = 45;

const SearchbarStyle = styled("div")(({ theme }) => ({
  width: "60%",
  borderRadius: "10px",
  alignItems: "center",
  height: APPBAR_MOBILE,
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up("md")]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------
const debouncedUpdateSearch = debounce((query, cb) => {
  cb(query);
}, 150);
const TopSearchbar = ({ onChange }) => {
  const [input, setInput] = useState("");
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setInput("");
    setOpen(false);
    debouncedUpdateSearch("", onChange);
  };
  const handleChange = (e) => {
    setInput(e.target.value);
    debouncedUpdateSearch(e.target.value, onChange);
  };

  return (
    <ClickAwayListener onClickAway={() => null}>
      <div>
        <IconButton onClick={handleOpen}>
          <Icon icon={searchFill} width={20} height={20} />
        </IconButton>

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <div className="floating-search">
            <SearchbarStyle value={input} onChange={handleChange}>
              <Input
                autoFocus
                fullWidth
                disableUnderline
                placeholder="Search…"
                startAdornment={
                  <InputAdornment position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{ color: "text.disabled", width: 20, height: 20 }}
                    />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    <Tooltip title="close">
                      <IconButton onClick={handleClose}>
                        <CloseIcon style={{ width: 20, height: 20 }} />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
                sx={{ mr: 1, fontWeight: "fontWeightBold" }}
              />
              {/* <Button variant="contained" onClick={handleClose}>
              Search
            </Button> */}
            </SearchbarStyle>
          </div>
        </Slide>
      </div>
    </ClickAwayListener>
  );
};
export default TopSearchbar;
