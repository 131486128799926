import * as React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import PropTypes from "prop-types";
import { filter } from "lodash";
// material
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import { Container, Stack, Typography, Button, Box } from "@mui/material";
import StyledSearch from "./components/search/StyledSearch";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import "@szhsin/react-menu/dist/index.css";
import moment from "moment";
// components
import Page from "../../components/Page";
import { EditTaskSidebar } from "../../components/_dashboard/projects";
import Sekeleton from "../../components/Sekeleton";
import FilterByProject from "./components/FilterByProject";
import { useSocket } from "../../Hooks/socketContext";
import TablePagination from "@mui/material/TablePagination";
import {
  deleteTask,
  changeTaskDetail,
  allTeamTasks,
} from "../../DAL/Project/Project";
import { makeStyles } from "@mui/styles";
import CommentsDrawer from "./components/CommentsDrawer";
import ProjectDrawer from "./components/ProjectDrawer";
import AllTasksFilter from "./components/AllTasksFilter";
import AllTasksSort from "./components/AllTasksSort";
import TaskComponent from "src/components/TaskComponent";
import { isAfter, parseISO } from "date-fns";
import { logmodeis } from "../../config/config";
import Loader from "src/components/Loader";
import debounce from "src/utils/debounce";

// ----------------------------------------------------------------------

const status_array = [
  {
    value: 0,
    label: "Pending",
    color: "#FFC107",
    isChecked: false,
    // icon: '/static/icons/ic_flag_en.svg'
  },
  {
    value: 1,
    label: "In Progress",
    color: "#3366FF",
    isChecked: false,
    // icon: '/static/icons/ic_flag_de.svg'
  },
  // {
  //   value: 2,
  //   label: 'In Review',
  //   color: 'warning'
  //   // icon: '/static/icons/ic_flag_fr.svg'
  // },
  // {
  //   value: 3,
  //   label: 'Completed',
  //   color: '#00AB55',
  //   isChecked: false,
  //   // icon: '/static/icons/ic_flag_fr.svg'
  // },
  // {
  //   value: 4,
  //   label: 'Cancelled',
  //   color: '#FF4842',
  //   isChecked: false,
  //   // icon: '/static/icons/ic_flag_fr.svg'
  // },
];
const priority_array = [
  {
    value: 0,
    label: "Critical",
    color: "error",
    isChecked: false,
    // icon: '/static/icons/ic_flag_en.svg'
  },
  {
    value: 1,
    label: "High",
    color: "primary",
    isChecked: false,
    // icon: '/static/icons/ic_flag_de.svg'
  },
  {
    value: 2,
    label: "Medium",
    color: "success",
    isChecked: false,
    // icon: '/static/icons/ic_flag_fr.svg'
  },
  {
    value: 3,
    label: "Low",
    color: "info",
    isChecked: false,
    // icon: '/static/icons/ic_flag_fr.svg'
  },
];

const useStyles = makeStyles(() => ({
  taskTypeRadio: {
    "& label": {
      margin: "0px",
    },
    // '& span': {
    //   marginTop: '0.1rem',
    // },
  },
}));

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function CircularProgressWithLabel(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        {...props}
        style={{ height: 35, width: 35 }}
      />
      <Tooltip title="Progress">
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            border: "1px solid #c5c5c5",
            borderRadius: "50%",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
}
export default function AllTasks(props) {
  const useStyles = makeStyles(() => ({
    loading: {
      marginLeft: "50%",
      marginTop: "20%",
    },
  }));
  const classes = useStyles();
  const { sendSocketNotification, showSocketNotification } = useSocket();
  const navigate = useNavigate();
  // const location = useLocation();
  const [location, setLocation] = useState(useLocation());
  const [projectStats, setProjectStats] = useState({
    all: 0,
    pending: 0,
    completed: 0,
  });
  const [priorityArray, setPriorityArray] = useState(priority_array);
  const [statusArray, setStatusArray] = useState(status_array);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setIswaiting] = useState(true);
  const [taskStats, setTaskStats] = useState({});
  const [projectDetail, setProjectDetail] = useState({});
  const [personal, setPersonal] = useState(false);
  const [personalTasks, setPersonalTasks] = useState([]);
  const [sidebarFilterData, setSidebarFilterData] = useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedProjectIndex, setSelectedProjectIndex] = React.useState(0);
  const [isProjectDrawerOpen, setIsProjectDrawerOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [projectOptions, setProjectOptions] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedRow, setRowData] = useState({});
  const [opendialog, setOpenDialog] = React.useState(false);
  const [openstatus, setOpenStatus] = useState(false);
  const [openpriority, setOpenPriority] = useState(false);
  const [projectMembers, setProjectMembers] = useState([]);
  const [openTotalMemberListing, setOpenTotalMemberListing] = useState(false);
  const [taskdata, setTaskData] = React.useState([]);
  const [openEditTask, setOpenEditTask] = useState(false);
  const [isMyTodoOn, setIsMyTodoOn] = useState(false);
  const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [taskSortStatus, setTaskSortStatus] = useState({
    type: "date",
    value: "newest",
    status: true,
  });
  const [isTaskDetailUpdating, setIsTaskDetailUpdating] = useState(false);
  // pagination start
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [pageCount, setPageCount] = React.useState("");

  //pagination close

  const closeProjectDrawer = () => {
    setIsProjectDrawerOpen(false);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  // const [startDate, endDate] = dateRange;

  const getTaskType = (task) => {
    task = task.trim();
    let length = task.length;
    let char = task.charAt(length - 1);
    if (String(char) === ":") {
      if (String(localStorage.getItem("allowDrag")) === String(1)) {
        return "2";
      }
      return "1";
    }
    return "1";
  };

  const handleSubmitUpdateTask = async (name, description, id) => {
    if (name.trim() === "") {
      enqueueSnackbar("Name can't be empty", { variant: "error" });

      return;
    }
    setIsTaskDetailUpdating(true);
    const taskType = getTaskType(name);
    const formData = new FormData();
    formData.append("task_text", name);
    formData.append("description", description);
    formData.append("task_type", taskType);
    if (process.env.NODE_ENV == logmodeis) {
      console.log(...formData, "update-task-req-all-tasks");
    }
    const result = await changeTaskDetail(formData, id);
    if (result.code === 200) {
      setIswaiting(false);
      handleCloseUpdate();
      fetchTaskListing();
      enqueueSnackbar("Task detail updated", { variant: "success" });
      setIsTaskDetailUpdating(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsTaskDetailUpdating(false);
    }
  };

  const handleOpenEditTask = (data) => {
    setOpenEditTask(true);
    setRowData(data);
  };

  const handleCloseEditTask = () => {
    setOpenEditTask(false);
  };

  const handleOpenUpdate = () => {
    setOpenEdit(true);
  };

  const handleCloseUpdate = () => {
    setOpenEdit(false);
  };

  const handleDeleteTask = async () => {
    // console.log(selectedRow, "row-data");
    const formData = new FormData();

    // formData.append("task_id", selectedRow.task_id);
    formData.append("project_id", id);
    formData.append("task_order", selectedRow.taskOrder);
    formData.append("vasibility_status", selectedRow.visibilityStatus);
    if (process.env.NODE_ENV == logmodeis) {
      console.log(...formData, "delete-task-req");
    }

    const result = await deleteTask(selectedRow.task_id);
    if (result.code === 200) {
      setIswaiting(false);
      fetchTaskListing();
      handleCloseDialog();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    const _arr = taskdata.filter((x) => x.id !== id);
    setTaskData(_arr);
  };

  const handleResetEditTask = () => {};

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getAssignedTask = (task) => {
    const userId = localStorage.getItem("user_id");
    const members = task.assign_member;

    const findAssign = members.find(
      (t) => String(t.user_id) === String(userId)
    );
    if (findAssign) {
      return true;
    }
    if (String(task.task_creator_id) === String(userId)) {
      return true;
    }
    return false;

    // return result;
  };

  const fetchTaskListing = async () => {
    const formData = new FormData();
    formData.append("search", searchValue);
    const result = await allTeamTasks(formData, page, rowsPerPage);
    if (process.env.NODE_ENV == logmodeis) {
      console.log(result, "all-tasks");
    }
    if (result.code === 200) {
      setIswaiting(false);
      setPageCount(result.total_pages);
      const userId = localStorage.getItem("user_id");
      const tasks = result.all_task_list.map((x, i) => {
        let isAllowed = false;
        if (String(userId) === String(x.task_creator_id)) {
          isAllowed = true;
        }
        return {
          task_id: x.task_id,
          task: x.task_name,
          task_creator_id: x.task_creator_id,
          targetDate: x.target_date === "0000-00-00" ? "" : x.target_date,
          // targetDate: moment(new Date(x.target_date)).format('DD.MM.YYYY HH:mm'),
          endDate: "",
          status: x.task_status,
          priority: x.task_priority,
          taskOrder: x.task_order,
          detailVisible: true,
          members: x.assign_member,
          isAssigned: getAssignedTask(x),
          visibilityStatus: x.vasibility_status,
          isAllowed: isAllowed,
          isCreator: isAllowed,
          taskCreatorName: x.task_creator_name,
          taskCreatorImage: x.task_creator_image,
          commentsCount: x.comment_count,
          createdDate: x.created_at,
          taskType: x.task_type,
          projectDetail: x.project_detail,
          projectId: x.project_id.id,
          description: x.task_distribution,
        };
      });
      let filtered = tasks.filter(
        (task) => String(task.taskType) !== String(2)
      );
      if (process.env.NODE_ENV == logmodeis) {
        console.log(filtered, "filtered-all-tasks");
      }
      setTaskData(filtered);
      // setTaskData(tasks);
      setIswaiting(false);
      getProjectNames(tasks);
    } else {
      setIswaiting(false);

      enqueueSnackbar(result.message, { variant: "error" });
      // setIswaiting(false);
    }
  };

  React.useEffect(() => {
    let todoStatus = localStorage.getItem("todoStatus");
    todoStatus = JSON.parse(todoStatus);
    // console.log(todoStatus, "tudu");
    if (todoStatus === true) {
      setIsMyTodoOn(true);
    }
  }, []);

  const handleLocalTaskSearch = (input, array) => {
    const task_search_with_team_names = (task) => {
      //
      let merged_names = task.task;
      task.members.map((member) => {
        merged_names =
          merged_names + " " + member.first_name + " " + member.last_name;
      });
      return merged_names;
      // return task.task;
    };
    let filtered = filter(
      array,
      (_task) =>
        task_search_with_team_names(_task)
          .toLowerCase()
          .indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const getFilteredTasks = (data) => {
    let filtered_tasks = [];
    let priority_checked = false;
    let status_checked = false;
    let local_selected_projects = localStorage.getItem("filteredProjects");
    if (JSON.parse(local_selected_projects)) {
      if (JSON.parse(local_selected_projects).length > 0) {
        let projects = JSON.parse(local_selected_projects);
        if (projects.length > 0) {
          let unique = [];
          let project_tasks = [];
          projects.map((project_, index_) => {
            if (project_.isSelected === true) {
              projects.map((project, index) => {
                taskdata.filter((task, index) => {
                  if (String(task.projectDetail.id) === String(project_.id)) {
                    if (!unique.includes(task.task_id)) {
                      unique.push(task.task_id);
                      project_tasks.push(task);
                    }
                  }
                });
              });
              data = [...project_tasks];
            }
          });
        }
      }
    } else {
      if (projectOptions.length > 0) {
        let unique = [];
        let project_tasks = [];
        projectOptions.map((project_, index_) => {
          if (project_.isSelected === true) {
            projectOptions.map((project, index) => {
              taskdata.filter((task, index) => {
                if (String(task.projectDetail.id) === String(project_.id)) {
                  if (!unique.includes(task.task_id)) {
                    unique.push(task.task_id);
                    project_tasks.push(task);
                  }
                }
              });
            });
            data = [...project_tasks];
          }
        });
      }
    }
    if (searchValue !== "") {
      const filtered = handleLocalTaskSearch(searchValue, data);
      data = [...filtered];
    }
    if (taskSortStatus.status === true) {
      if (process.env.NODE_ENV == logmodeis) {
        console.log(taskSortStatus, "sts");
      }
      const sorted = handleSort(
        taskSortStatus.type,
        taskSortStatus.value,
        data
      );
      if (process.env.NODE_ENV == logmodeis) {
        console.log(sorted, "srtd");
      }
      data = Array.from(sorted);
      // data = [...sorted];
    }

    statusArray.map((status, index) => {
      if (status.isChecked === true) {
        status_checked = true;
      }
    });

    priorityArray.map((status, index) => {
      if (status.isChecked === true) {
        priority_checked = true;
      }
    });

    if (!priority_checked && !status_checked) {
      return data;
    }

    if (priority_checked && status_checked) {
      data.map((task) => {
        let allow_by_priority = false;
        let allow_by_status = false;
        priorityArray.map((priority, index) => {
          if (priority.isChecked) {
            if (String(priority.value) === String(task.priority)) {
              allow_by_priority = true;
            }
          }
        });
        statusArray.map((status, index) => {
          if (status.isChecked) {
            if (String(status.value) === String(task.status)) {
              allow_by_status = true;
            }
          }
        });

        if (allow_by_priority && allow_by_status) {
          filtered_tasks.push(task);
        }
      });
    }

    if (priority_checked && !status_checked) {
      data.map((task) => {
        let allow = false;
        priorityArray.map((priority, index) => {
          if (priority.isChecked) {
            if (String(priority.value) === String(task.priority)) {
              allow = true;
            }
          }
        });

        if (allow) {
          filtered_tasks.push(task);
        }
      });
    }

    if (!priority_checked && status_checked) {
      data.map((task) => {
        let allow = false;
        statusArray.map((status, index) => {
          if (status.isChecked) {
            if (String(status.value) === String(task.status)) {
              allow = true;
            }
          }
        });

        if (allow) {
          filtered_tasks.push(task);
        }
      });
    }
    if (process.env.NODE_ENV == logmodeis) {
      console.log(filtered_tasks, "filtered_tasks");
    }
    return filtered_tasks;
  };
  const handleSort = (type, option, data) => {
    if (type === "date") {
      let sorted = data.sort((a, b) => {
        let a_created_at = a.createdDate;
        let b_created_at = b.createdDate;
        let a_due_date = a.targetDate;
        let b_due_date = b.targetDate;

        if (option === "newest") {
          return newestSorter(a, b);
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(a_due_date).isAfter(b_due_date);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ["isDateGreater"]: res1 };
          let dateBResult = moment(b_due_date).isAfter(a_due_date);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ["isDateGreater"]: res2 };
          return b.isDateGreater - a.isDateGreater;
        }
        if (option === "oldest") {
          return oldestSorter(a, b);
        }
        if (option === "due-date") {
          return dueDateSorter(a, b);
        }
        if (option === "actual-due-date") {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(a.targetDate).isAfter(b.targetDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ["isDateGreater"]: res1 };
          let dateBResult = moment(b.targetDate).isAfter(a.targetDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ["isDateGreater"]: res2 };
          return a.isDateGreater - b.isDateGreater;
        }
      });
      return sorted;
    }

    if (type === "priority") {
      let sorted = data.sort((a, b) => {
        let t1Priority = a.priority;
        t1Priority = parseInt(t1Priority);
        a = { ...a, ["intPriority"]: t1Priority };
        let t2Priority = b.priority;
        t2Priority = parseInt(t2Priority);
        b = { ...b, ["intPriority"]: t2Priority };
        if (option === "priorityDesc") {
          return a.intPriority - b.intPriority;
        }
        return b.intPriority - a.intPriority;
      });

      return sorted;
    }
  };
  const newestSorter = (a, b) => {
    const a_target_date = a.createdDate;
    const b_target_date = b.createdDate;
    const a_after_b = isAfter(parseISO(a_target_date), parseISO(b_target_date));
    if (!a_target_date || String(a_target_date) === "") {
      return 1;
    }
    if (a_after_b === true) {
      return -1;
    }
    if (a_after_b === false) {
      return 1;
    }
    return 0;
  };
  const oldestSorter = (a, b) => {
    const a_target_date = a.createdDate;
    const b_target_date = b.createdDate;
    const a_after_b = isAfter(parseISO(a_target_date), parseISO(b_target_date));
    if (!a_target_date || String(a_target_date) === "") {
      return 1;
    }
    if (a_after_b === true) {
      return 1;
    }
    if (a_after_b === false) {
      return -1;
    }
    return 0;
  };
  const dueDateSorter = (a, b) => {
    const a_target_date = a.targetDate;
    const b_target_date = b.targetDate;
    const a_after_b = isAfter(parseISO(a_target_date), parseISO(b_target_date));
    if (!a_target_date || String(a_target_date) === "") {
      return 1;
    }
    if (a_after_b === true) {
      return 1;
    }
    if (a_after_b === false) {
      return -1;
    }
    return 0;
  };
  const handleSortChange = (type, value) => {
    let status = true;
    // console.log(type, "tape");
    if (type === "default") {
      status = false;
    }
    setTaskSortStatus({
      ...taskSortStatus,
      ["status"]: status,
      ["type"]: type,
      ["value"]: value,
    });
  };

  const closeCommentsDrawer = () => {
    setIsCommentDrawerOpen(false);
  };
  const handleOpenCommentDrawer = (data) => {
    setRowData(data);
    setIsCommentDrawerOpen(true);
  };

  const handleChangeInFilter = (
    option,
    checked,
    targetIndex,
    targetArray,
    type
  ) => {
    // console.log(option, checked, targetIndex, "change");
    targetArray.map((item, index) => {
      if (index === targetIndex) {
        if (type === "status") {
          let data = [...targetArray];
          data[index] = { ...data[index], isChecked: checked };
          setStatusArray(data);
        }
        if (type === "priority") {
          let data = [...targetArray];
          data[index] = { ...data[index], isChecked: checked };
          setPriorityArray(data);
        }
      }
    });
  };
  const handleResetTaskFilter = () => {
    setStatusArray((statusData) =>
      statusData.map((item) => {
        return { ...item, ["isChecked"]: false };
      })
    );
    setPriorityArray((statusData) =>
      statusData.map((item) => {
        return { ...item, ["isChecked"]: false };
      })
    );
  };
  const getTaskCount = (type, data) => {
    let count = 0;
    if (String(type) === "pending") {
      data.map((task, index) => {
        if (String(task.status) === String(0)) {
          count = count + 1;
        }
      });
    }
    if (String(type) === "inprogress") {
      data.map((task, index) => {
        if (String(task.status) === String(1)) {
          count = count + 1;
        }
      });
    }
    if (String(type) === "total") {
      data.map((task, index) => {
        count = count + 1;
      });
    }
    return count;
  };
  const getProjectsCount = (data) => {
    let unique = [];
    data.forEach((task) => {
      if (!unique.includes(String(task.projectId))) {
        unique.push(String(task.projectId));
      }
    });
    return unique.length;
  };

  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const getProjectNames = (data) => {
    if (process.env.NODE_ENV == logmodeis) {
      console.log(data, "data,");
    }
    let options = [];
    let unique = [];
    if (data.length > 0) {
      data.forEach((task) => {
        if (!unique.includes(String(task.projectDetail.id))) {
          unique.push(String(task.projectDetail.id));
          options.push({
            id: task.projectDetail.id,
            label: task.projectDetail.project_name,
            isSelected: false,
          });
        }
      });
      setProjectOptions(options);
    }
  };
  const handleSelectProject = (value, data) => {
    const { id } = value;
    projectOptions.map((project, index) => {
      if (String(project.id) === String(id)) {
        let options = [...projectOptions];
        options[index] = { ...project, ["isSelected"]: true };
        localStorage.setItem("filteredProjects", JSON.stringify(options));
        setProjectOptions(options);
      }
    });
  };
  const handleUnselectProject = (value, data) => {
    const { id } = value;
    projectOptions.map((project, index) => {
      if (String(project.id) === String(id)) {
        let options = [...projectOptions];
        options[index] = { ...project, ["isSelected"]: false };
        localStorage.setItem("filteredProjects", JSON.stringify(options));
        setProjectOptions(options);
      }
    });
  };
  React.useEffect(() => {
    debounce(fetchTaskListing);
  }, [page, rowsPerPage, searchValue]);

  return (
    <Page title="All Tasks | Support Task Portal">
      <Container maxWidth="xl" style={{ backgroundColor: "#fff" }}>
        <div className="row main-header">
          <div
            className="col-lg-2 col-md-12 col-sm-12  align-items-center"
            style={{ display: "flex" }}
          >
            <span>
              <Typography style={{ fontsize: 16, fontWeight: "bold" }}>
                All tasks
              </Typography>
            </span>
          </div>
          <div className="col header-task justify-content-end">
            <div className="row w-100 justify-content-end">
              <div className="col-md-5 d-flex align-items-center justify-content-end  mb-2 mb-md-0">
                <div className="mr-1 d-flex">
                  <Typography
                    sx={{ cursor: "pointer", fontSize: 12 }}
                    className="date-css"
                  >
                    Active Projects&nbsp;
                  </Typography>
                  <Tooltip title="Active projects">
                    <div
                      className="status-circle"
                      style={{
                        backgroundColor: "#08ad5a",
                        marginInline: "2px",
                      }}
                    >
                      <Typography sx={{ cursor: "pointer", fontSize: 12 }}>
                        {getProjectsCount(taskdata)}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                <div className="mr-1 d-flex">
                  <Typography
                    sx={{ cursor: "pointer", fontSize: 12 }}
                    className="date-css"
                  >
                    Pending&nbsp;
                  </Typography>
                  <Tooltip title="Pending Tasks">
                    <div
                      className="status-circle"
                      style={{
                        backgroundColor: " #ffc107",
                        marginInline: "2px",
                      }}
                    >
                      <Typography sx={{ cursor: "pointer", fontSize: 12 }}>
                        {getTaskCount("pending", taskdata)}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="col-md-4 d-flex align-items-center justify-content-end">
                <Typography
                  sx={{ cursor: "pointer", fontSize: 12 }}
                  className="date-css"
                >
                  &nbsp;In progress&nbsp;
                </Typography>
                <Tooltip title="In progress">
                  <div
                    className="status-circle"
                    style={{
                      backgroundColor: "#3366FF",
                      marginInline: "2px",
                    }}
                  >
                    <Typography sx={{ cursor: "pointer", fontSize: 12 }}>
                      {getTaskCount("inprogress", taskdata)}
                    </Typography>
                  </div>
                </Tooltip>
                <div className="mr-1 d-flex">
                  <Typography
                    sx={{ cursor: "pointer", fontSize: 12 }}
                    className="date-css"
                  >
                    &nbsp;Total&nbsp;
                  </Typography>
                  <Tooltip title="Total Tasks">
                    <div
                      className="status-circle"
                      style={{
                        backgroundColor: "#c5c5c5",
                        marginInline: "2px",
                      }}
                    >
                      <Typography sx={{ cursor: "pointer", fontSize: 12 }}>
                        {getTaskCount("total", taskdata)}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent={"flex-end"}
          style={{ marginTop: "1.2rem" }}
        >
          <div className="mr-auto">
            <StyledSearch
              value={searchValue}
              onChange={handleChangeSearchValue}
              placeHolder="Search Task..."
            />
          </div>
          <Stack
            direction="row"
            alignItems={"center"}
            spacing={1}
            flexShrink={0}
          >
            <AllTasksFilter
              status_array={statusArray}
              priority_array={priorityArray}
              handleChangeInFilter={handleChangeInFilter}
              onCloseFilter={handleCloseFilter}
              onOpenFilter={handleOpenFilter}
              isOpenFilter={openFilter}
              onResetFilter={handleResetTaskFilter}
            />
            <FilterByProject
              onSelectProject={handleSelectProject}
              onUnselectProject={handleUnselectProject}
              projectList={projectOptions}
            />
            <AllTasksSort
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              onSortChange={handleSortChange}
              // data={null}
            />

            <EditTaskSidebar
              isOpenEditTask={openEditTask}
              isTaskData={selectedRow}
              onResetEditTask={handleResetEditTask}
              onOpenEditTask={handleOpenEditTask}
              onCloseEditTask={handleCloseEditTask}
              openUpdate={handleOpenUpdate}
              openEdit={openEdit}
              SubmitTask={handleSubmitUpdateTask}
              isUpdating={isTaskDetailUpdating}
            />

            <CommentsDrawer
              isTaskData={selectedRow}
              openEdit={openEdit}
              SubmitTask={handleSubmitUpdateTask}
              getTaskList={fetchTaskListing}
              isCommentOpen={isCommentDrawerOpen}
              onCloseComment={closeCommentsDrawer}
            />
            <ProjectDrawer
              onClose={closeProjectDrawer}
              isOpen={isProjectDrawerOpen}
              projectDetail={projectDetail}
            />
          </Stack>
        </Stack>
        {iswaiting && <Loader />} 
       { !iswaiting ? (
        <TaskComponent
          //passing states as props
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          pageCount={pageCount}
          projectDetail={projectDetail}
          taskSortStatus={taskSortStatus}
          searchValue={searchValue}
          isMyTodoOn={isMyTodoOn}
          setOpenEditTask={setOpenEditTask}
          setRowData={setRowData}
          setIsCommentDrawerOpen={setIsCommentDrawerOpen}
          projectMembers={projectMembers}
          taskdata={taskdata}
          setTaskData={setTaskData}
          sidebarFilterData={sidebarFilterData}
          setSidebarFilterData={setSidebarFilterData}
          setOpenStatus={setOpenStatus}
          setOpenPriority={setOpenPriority}
          setOpenTotalMemberListing={setOpenTotalMemberListing}
          //functions
          fetchTaskListing={fetchTaskListing}
          getFilteredTasks={getFilteredTasks}
          //conditions
          verifiedListing={false}
          allTaskListing={true}
          isDraggable={false}
          showMenu={false}
          showMembersListing={false}
          showAddMembers={false}
          projectButton={true}
          canOpenSidebar={true}
          canOpenStatus={true}
          canOpenPriority={true}
          showCopy={true}
          showCheckbox={true}
          showMoveTo={true}
          iswaiting={iswaiting}
          setIswaiting={setIswaiting}
        />
        ) : (
        <Sekeleton totalTask={projectStats.all} />
        )}
        {/* ========================== delete task dialog========================= */}
        <Dialog open={opendialog} onClose={handleCloseDialog}>
          <DialogTitle>Are you sure you want to delete this task ?</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleDeleteTask}>Agree</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
