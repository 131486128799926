import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// material
import {
  Box,
  Card,
  Typography,
  Stack,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import '@szhsin/react-menu/dist/index.css';
// utils
//
import { baseUri } from '../../config/config';

// ----------------------------------------------------------------------

const ProjectImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

CategoryListCard.propTypes = {
  product: PropTypes.object,
};

export default function CategoryListCard({ category }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [projectData, setProjectData] = React.useState({});
  // const { id, name, cover } = project;
  // console.log(project, 'project-detail');
  // const handleOpenDialig = (data) => {
  //   setOpenDialog(true);
  //   setProjectData(data);
  // };
  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };
  const handleNav = (id) => {
    navigate(`/projects/project/${id}`);
  };
  return (
    <>
      <Tooltip
        placement='right'
        arrow
        title={
          <React.Fragment>
            {category.projects.length > 0 ? (
              <>
                <Typography fontWeight='bold'>Category Projects:</Typography>
                {category.projects.map((item, i) => (
                  <Typography
                    onClick={() => handleNav(item.id)}
                    role='button'
                    sx={{ my: '5px' }}
                    key={i}
                  >
                    {item.project_name}
                  </Typography>
                ))}
              </>
            ) : (
              <>
                <Typography>No Projects</Typography>
              </>
            )}
          </React.Fragment>
        }
      >
        <Card>
          <Box sx={{ pt: '100%', position: 'relative' }}>
            {/* <Link color="inherit" underline="hover" component={RouterLink}> */}
            {category.image !== '' ? (
              <ProjectImgStyle
                // alt={name}
                style={{ cursor: 'pointer' }}
                src={baseUri + category.image}
                className='image-hov'
                onClick={() =>
                  navigate(`/projects/categories/${category.category_id}`, {
                    state: category,
                  })
                }
              />
            ) : (
              <ProjectImgStyle
                // alt={name}
                style={{ cursor: 'pointer' }}
                src='https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg'
                className='image-hov'
                onClick={() =>
                  navigate(`/projects/categories/${category.category_id}`, {
                    state: category,
                  })
                }
              />
            )}
            {/* <Avatar className="image-hov">N</Avatar> */}
            {/* </Link> */}
          </Box>

          <Stack spacing={2} sx={{ p: 3 }}>
            {/* <Link color="inherit" underline="hover" component={RouterLink}> */}
            <Typography
              variant='subtitle2'
              noWrap
              style={{ cursor: 'pointer' }}
              onClick={() =>
                navigate(`/project-categories-list/project-category/${category.category_id}`, {
                  state: category,
                })
              }
            >
              {category.catagory_name}
            </Typography>
            {/* </Link> */}
            {/* <Typography variant="subtitle2" noWrap>
          {id}
        </Typography> */}
          </Stack>
        </Card>
      </Tooltip>
    </>
  );
}
