const _set_data_in_localStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const _get_data_from_localStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

const _set_project_categories_in_localStorage = (data) => {
  localStorage.setItem("selected_categories", JSON.stringify(data));
};

const _get_project_categories_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("selected_categories"));
};

module.exports = {
  _get_data_from_localStorage,
  _set_data_in_localStorage,
  _set_project_categories_in_localStorage,
  _get_project_categories_from_localStorage,
};
