import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
// import Iconify from "./Iconify";
import PropTypes from "prop-types";
import Scrollbar from "./Scrollbar";
import { Iconify } from ".";
// import Scrollbar from "./Scrollbar";

const MuiDrawer = ({
  isOpen,
  onClose,
  sx,
  paperProps,
  boxProps,
  title,
  children,
  bottomComponent,
  headerComponent,
  noDismiss,
}) => {
  return (
    <Drawer
      disableEnforceFocus
      anchor="right"
      open={isOpen}
      onClose={noDismiss ? () => {} : onClose}
      sx={{ ...sx }}
      PaperProps={{
        sx: {
          width: { xs: 1, sm: 440, md: 500 },
          border: "none",
          ...paperProps,
        },
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
        <IconButton onClick={onClose}>
          <Iconify icon={"clarity:window-close-line"} />
        </IconButton>
        {title && (
          <Typography noWrap sx={{ ml: 1 }} variant="h6">
            {title}
          </Typography>
        )}
      </Stack>
      <Divider />
      {headerComponent && headerComponent}
      <Scrollbar
        id="scroll-bar-"
        sx={{
          height: 1,
          "& .simplebar-content": {
            height: 1,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            px: 2.5,
            py: 1.5,
            ...boxProps,
          }}
        >
          {children}
        </Box>
      </Scrollbar>
      {bottomComponent && bottomComponent}
    </Drawer>
  );
};

export default MuiDrawer;
MuiDrawer.propTypes = {
  sx: PropTypes.object,
  paperProps: PropTypes.object,
  title: PropTypes.string,
  bottomComponent: PropTypes.node,
  noDismiss: PropTypes.bool,
};
