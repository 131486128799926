import CryptoJS from "crypto-js";
import { logmodeis } from "src/config/config";

// Cryptojs
const dc_token = () =>{


let  decryptedDataString='';
let  decrypted_token = '';
if(localStorage.getItem("token")){
  const SECRET_KEY = "cristianoRonaldoIsTheGreatestPlayerOfAllTime";
  const encryptedData = localStorage.getItem("token");
  if (process.env.NODE_ENV == logmodeis){console.log(encryptedData,"encryptedData---")}
  decryptedDataString = CryptoJS.AES.decrypt( encryptedData, SECRET_KEY ).toString(CryptoJS.enc.Utf8);
  decrypted_token = JSON.parse(decryptedDataString);
  if (process.env.NODE_ENV == logmodeis){console.log(decrypted_token,"decrypted_token---")}
}
return decrypted_token;

}
export default dc_token;