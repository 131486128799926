import * as React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { filter } from "lodash";
// material
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  Container,
  Stack,
  Typography,
  Button,
  Box,
  IconButton,
  RadioGroup,
  Radio,
  AvatarGroup,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Avatar,
  Popover,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import "@szhsin/react-menu/dist/index.css";
import moment from "moment";
// components
import Page from "../../components/Page";
import {
  ProjectFilterSidebar,
  EditTaskSidebar,
} from "../../components/_dashboard/projects";
import Sekeleton from "../../components/Sekeleton";
import { useSocket } from "../../Hooks/socketContext";
import {
  addTask,
  deleteTask,
  taskOrder,
  changeTaskDetail,
  taskListCollapsable,
  taskSectionsList,
  addTaskSection,
  updateTaskSectionOrder,
  taskFilterTeam,
  moveTaskToSection,
  detailProjectV2,
} from "../../DAL/Project/Project";
import CommentsDrawer from "./components/CommentsDrawer";
import ProjectDrawer from "./components/ProjectDrawer";
import PopoverSectionMenu from "./components/PopoverSectionMenu";

import { alpha, styled } from "@mui/material/styles";
import { baseUri, logmodeis } from "src/config/config";
import { LoadingButton } from "@mui/lab";
import SectionSelectV2 from "./components/SectionSelectV2";
import AccordionMenu from "./components/AccordionMenu";
import TaskSortV2 from "./components/TaskSortV2";
import TaskSectionAccordion from "./components/TaskSectionAccordion";
import SectionEditSidebar from "./components/SectionEditSidebar";
import ProjectSetting from "src/components/_dashboard/projects/ProjectSetting";
import TopSearchbar from "src/layouts/dashboard/TopSearchbar";
import TaskCreateField from "./components/TaskCreateField";
import Loader from "src/components/Loader";
// ----------------------------------------------------------------------

const filterStatus = [
  {
    label: "Pending",
    value: "pending",
    color: "warning",
    filterValue: "0",
    type: "status",
  },
  {
    label: "In Progress",
    value: "in_progress",
    color: "secondary",
    filterValue: "1",
    type: "status",
  },
  // { label: 'In Review', value: 'in_review', color: 'warning', filterValue: '2', type: 'status' },
  {
    label: "Completed",
    value: "completed",
    color: "primary",
    filterValue: "3",
    type: "status",
  },
  {
    label: "Cancelled",
    value: "cancelled",
    color: "error",
    filterValue: "4",
    type: "status",
  },
  {
    label: "Critical",
    value: "critical",
    color: "error",
    filterValue: "0",
    type: "priority",
  },
  {
    label: "High",
    value: "high",
    color: "primary",
    filterValue: "1",
    type: "priority",
  },
  {
    label: "Medium",
    value: "medium",
    color: "success",
    filterValue: "2",
    type: "priority",
  },
  {
    label: "Low",
    value: "low",
    color: "info",
    filterValue: "3",
    type: "priority",
  },
];
CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function CircularProgressWithLabel(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        {...props}
        style={{ height: 35, width: 35 }}
      />
      <Tooltip title="Progress">
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            border: "1px solid #c5c5c5",
            borderRadius: "50%",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
}
const ArrowStyle = styled("span")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: "absolute",
    borderRadius: "0 0 4px 0",
    transform: "rotate(-135deg)",
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));
export default function CollapsableTaskListing(props) {
  const { sendSocketNotification, getUserProfileData } = useSocket();
  const navigate = useNavigate();
  const [location, setLocation] = useState(useLocation());
  const [projectStats, setProjectStats] = useState({
    all: 0,
    pending: 0,
    completed: 0,
  });
  const { id } = useParams();
  const params = useParams();

  const user = getUserProfileData();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setIswaiting] = useState(true);
  const [taskStats, setTaskStats] = useState({});
  const [projectDetail, setProjectDetail] = useState({});
  const [personal, setPersonal] = useState(false);
  const anchorTotalMembers = useRef(null);
  const [sidebarFilterData, setSidebarFilterData] = useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedSectionIndexPublic, setSelectedSectionIndexPublic] =
    React.useState(0);
  const [selectedSection, setSelectedSection] = React.useState(null);
  const [selectedSectionIndexPrivate, setSelectedSectionIndexPrivate] =
    React.useState(0);
  const [isProjectDrawerOpen, setIsProjectDrawerOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isDeletingTask, setIsDeletingTask] = useState(false);
  const [isUpdatingTask, setIsUpdatingTask] = useState(false);
  const [isSectionSelectDisabled, setIsSectionSelectDisabled] = useState(false);
  const [taskType, setTaskType] = useState(null);
  /*  */
  const [taskSectionsListing, setTaskSectionsListing] = useState([]);
  const [taskSectionsData, setTaskSectionsData] = useState([]);
  const [currentSelectedSection, setCurrentSelectedSection] = useState(null);
  const [isAccordionMenuOpen, setIsAccordionMenuOpen] = useState(false);
  const [isSectionSidebarOpen, setIsSectionSidebarOpen] = useState(false);
  const [accordionMenuAnchorEl, setAccordionMenuAnchorEl] = useState(null);
  const [selectedTargetSection, setSelectedTargetSection] = useState(null);
  const [isMoveUpButtonDisabled, setIsMoveUpButtonDisabled] = useState(false);
  const [isMoveDownButtonDisabled, setIsMoveDownButtonDisabled] =
    useState(false);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
  const prevSections = useRef([]);
  const openProjectDrawer = () => {
    setIsProjectDrawerOpen(true);
  };
  const closeProjectDrawer = () => {
    setIsProjectDrawerOpen(false);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };
  const handleOpenFilterSetting = () => {
    setOpenFilterSetting(true);
  };
  const handleCloseFilterSetting = () => {
    setOpenFilterSetting(false);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const handleChangeFilterData = (value, key) => {
    setFilterData({
      ...filterData,
      [key]: value,
    });
  };

  const handleFilterData = async (data, chipdata) => {
    const _arr = [];
    let _status = 1;
    if (process.env.NODE_ENV == logmodeis) {
      console.log(taskType, "taskTypetaskTypetaskType");
    }
    if (taskType === true) {
      _status = 1;
    }
    if (taskType === false) {
      _status = 0;
    }

    const updatedStatus = chipdata.map((status) => {
      if (status.value === true) {
        _arr.push(filterStatus.find((x) => x.value === status.key));
      }
      return status;
    });
    localStorage.setItem(
      `project_${id}`,
      JSON.stringify({
        myFilter: JSON.parse(localStorage.getItem(`project_${id}`)).myFilter,
        filter: _arr,
        chipData: chipdata,
        filterData: data,
      })
    );
    const formData = new FormData();

    // formData.append("token", localStorage.getItem("token"));
    formData.append("project_id", id);
    formData.append("filter_status", _status);
    let status_array = [];
    let priority_array = [];
    const filter = _arr.map((y, j) => {
      if (process.env.NODE_ENV == logmodeis) {
        console.log(j, "j");
      }
      if (y.type === "status") {
        status_array.push(y.filterValue);
      }
      if (y.type === "priority") {
        priority_array.push(y.filterValue);
      }
      return y;
    });
    const new_status_array = status_array.map((val) => {
      return val;
    });
    const new_priority_array = priority_array.map((val) => {
      return val;
    });
    formData.append(`status`, JSON.stringify(new_status_array));
    formData.append(`priority`, JSON.stringify(new_priority_array));
    if (process.env.NODE_ENV == logmodeis) {
      console.log(...formData, "task-filter-req");
    }
    const result = await taskFilterTeam(formData);
    if (process.env.NODE_ENV == logmodeis) {
      console.log(result, "filtered-tasks");
    }
    if (result.code === 200) {
      let all_expand = true;
      localStorage.setItem("taskFilter", "1");
      let section_data = result.tasks_sections.map((section, index) => {
        return {
          ...section,
          expanded: isExpanded(section.section_id, index),
          section_tasks: get_modified(section.section_tasks),
        };
      });
      if (process.env.NODE_ENV == logmodeis) {
        console.log(section_data, "modified-filtered-tasks");
      }
      localStorage.setItem("localSectionsData", JSON.stringify(section_data));
      setTaskSectionsData(section_data);
      localStorage.setItem("firstExpand", false);
      const userId = localStorage.getItem("user_id");
      let isAllowed = false;
      setFilterData(data);
      setFilterChip(chipdata);

      setIsFilterOn(true);
    } else {
      setIsFilterOn(false);
      enqueueSnackbar(result.message, { variant: "error" });
      // setIswaiting(false);
    }
  };

  const handleFilterSettingData = async (data, chipdata) => {
    const _arr = [];
    let _status = 1;
    if (taskType === true) {
      _status = 1;
    }
    if (taskType === false) {
      _status = 0;
    }

    const updatedStatus = chipdata.map((status) => {
      if (status.value === true) {
        _arr.push(filterStatus.find((x) => x.value === status.key));
      }
      return status;
    });
    localStorage.setItem(
      `project_${id}`,
      JSON.stringify({
        myFilter: JSON.parse(localStorage.getItem(`project_${id}`)).myFilter,
        filter: _arr,
        chipData: chipdata,
        filterData: data,
      })
    );
    const formData = new FormData();
    // formData.append("token", localStorage.getItem("token"));
    formData.append("project_id", id);
    formData.append("filter_status", _status);
    let status_array = [];
    let priority_array = [];
    const filter = _arr.map((y, j) => {
      if (process.env.NODE_ENV == logmodeis) {
        console.log(j, "j");
      }
      if (y.type === "status") {
        status_array.push(y.filterValue);
      }
      if (y.type === "priority") {
        priority_array.push(y.filterValue);
      }
      return y;
    });
    const new_status_array = status_array.map((val) => {
      return val;
    });
    const new_priority_array = priority_array.map((val) => {
      return val;
    });
    formData.append(`status`, JSON.stringify(new_status_array));
    formData.append(`priority`, JSON.stringify(new_priority_array));
    if (process.env.NODE_ENV == logmodeis) {
      console.log(...formData, "task-filter-req");
    }
    const result = await taskFilterTeam(formData);
    if (process.env.NODE_ENV == logmodeis) {
      console.log(result, "filtered-tasks");
    }
    if (result.code === 200) {
      let all_expand = true;
      localStorage.setItem("taskFilter", "1");
      let section_data = result.tasks_sections.map((section, index) => {
        return {
          ...section,
          expanded: isExpanded(section.section_id, index),
          section_tasks: get_modified(section.section_tasks),
        };
      });
      if (process.env.NODE_ENV == logmodeis) {
        console.log(section_data, "modified-filtered-tasks");
      }
      localStorage.setItem("localSectionsData", JSON.stringify(section_data));
      setTaskSectionsData(section_data);
      localStorage.setItem("firstExpand", false);
      const userId = localStorage.getItem("user_id");
      let isAllowed = false;
      // setIsFilterOn(true)
      setFilterSettingData(data);
      setFilterSettingChip(chipdata);
      setIsFilterSettingOn(true);
    } else {
      setIsFilterSettingOn(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleResetFilter = () => {
    let _status = 1;
    // if (taskType === true) {
    //   _status = 1;
    // }
    if (taskType === false) {
      _status = 0;
    }
    setIsFilterOn(false);
    setFilterData([]);
    // setFilterData({
    //   critical: false,
    //   high: false,
    //   medium: false,
    //   low: false,
    //   pending: false,
    //   in_progress: false,
    //   in_review: false,
    //   completed: false,
    //   cancelled: false,
    // });
    localStorage.setItem(
      `project_${id}`,
      JSON.stringify({
        myFilter: false,
        filter: [],
        chipData: [],
      })
    );
    setMyFilter(false);
    const tempState = [...filterChip];
    setFilterChip(
      tempState.map((x) => {
        return {
          ...x,
          value: false,
        };
      })
    );
    fetchTaskListing(0, _status);
    // handleCloseFilter();
    setOpenFilter(false);
  };

  const handleResetFilterSetting = () => {
    let _status = 1;
    if (taskType === true) {
      _status = 1;
    }
    if (taskType === false) {
      _status = 0;
    }
    setIsFilterSettingOn(false);
    setFilterSettingData({
      critical: false,
      high: false,
      medium: false,
      low: false,
      pending: false,
      in_progress: false,
      in_review: false,
      completed: false,
      cancelled: false,
    });
    localStorage.setItem(
      `project_${id}`,
      JSON.stringify({
        myFilter: false,
        filter: [],
        chipData: [],
      })
    );
    setMyFilterSetting(false);
    const tempState = [...filterChip];
    setFilterSettingChip(
      tempState.map((x) => {
        return {
          ...x,
          value: false,
        };
      })
    );
    fetchTaskListing(0, _status);
    // handleCloseFilter();
    setOpenFilterSetting(false);
  };
  const [openFilter, setOpenFilter] = useState(false);
  const [openFilterSetting, setOpenFilterSetting] = useState(false);
  const [myFilter, setMyFilter] = useState(false);
  const [myFilterSetting, setMyFilterSetting] = useState(false);

  const [filterData, setFilterData] = useState({
    critical: false,
    high: false,
    medium: false,
    low: false,
    pending: false,
    in_progress: false,
    in_review: false,
    completed: false,
    cancelled: false,
  });
  const [filterSettingData, setFilterSettingData] = useState({
    critical: false,
    high: false,
    medium: false,
    low: false,
    pending: false,
    in_progress: false,
    in_review: false,
    completed: false,
    cancelled: false,
  });
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [isFilterSettingOn, setIsFilterSettingOn] = useState(false);
  const [filterChip, setFilterChip] = useState([]);
  const [filterSettingChip, setFilterSettingChip] = useState([]);
  const [taskfield, setTaskField] = React.useState(false);
  const [taskName, setTaskName] = React.useState("");
  const [selectedRow, setRowData] = useState({});
  const [opendialog, setOpenDialog] = React.useState(false);
  const [openstatus, setOpenStatus] = useState(false);
  const [openpriority, setOpenPriority] = useState(false);
  const [projectMembers, setProjectMembers] = useState([]);
  const [openTotalMemberListing, setOpenTotalMemberListing] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [taskdata, setTaskData] = React.useState([]);
  const [taskSections, setTaskSections] = React.useState([]);
  const [openEditTask, setOpenEditTask] = useState(false);
  const [isMyTodoOn, setIsMyTodoOn] = useState(false);
  const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState(false);
  const shouldScroll = useRef(true);
  const [isSectionMenuOpen, setIsSectionMenuOpen] = useState(false);
  const [sectionMenuAnchorEl, setSectionMenuAnchorEl] = useState(null);
  const [defaultExpand, setDefaultExpand] = useState(false);
  const [UserType, setUserType] = useState("")
  const selectedSortVal = useRef("0");
  let allowDrag = false;
  if (localStorage.getItem("allowDrag") === "1") {
    allowDrag = true;
  }
  const [openEdit, setOpenEdit] = React.useState(false);

  const [taskSortStatus, setTaskSortStatus] = useState({
    type: "",
    value: "",
    status: false,
  });
  const removeColon = (text) => {
    let target_text = text;
    for (let i = 0; i < text.length; i++) {
      target_text = target_text.replace(":", "");
    }
    return target_text;
  };
  const getTaskType = (task) => {
    task = task.trim();
    let length = task.length;
    let char = task.charAt(length - 1);
    if (String(char) === ":") {
      if (user.allow_reorder_task === true) {
        return "2";
      }
      return "1";
    }
    return "1";
  };

  const createSection = async (taskName, _status) => {
    const removed_colon = removeColon(taskName.trim());
    const formData = new FormData();
    // formData.append("token", localStorage.getItem("token"));
    formData.append("section_name", removed_colon);
    formData.append("project_id", id);
    formData.append("description", "");
    formData.append("vasibility_status", taskType);
    console.log(...formData, "addSection-request");
    if (process.env.NODE_ENV == logmodeis) {
    }
    const result = await addTaskSection(formData);
    
    if (result.code === 200) {
      
      getTaskSections(_status);
      fetchTaskListing(0, _status);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    return result;
  };

  const createTask = async (taskName, task_Type, _status) => {
    const formData = new FormData();
    console.log(task_Type, "task_Type----");

    formData.append("task_name", taskName);
    formData.append("project_id", id);
    formData.append("vasibility_status", taskType); // true/false
    if (taskSectionsListing) {
      if (taskSectionsListing[selectedSectionIndex]) {
        let section = taskSectionsListing[selectedSectionIndex];
        if (process.env.NODE_ENV == logmodeis) {
          console.log(section, "target-section");
        }
        formData.append("task_id", section.id);
      }
    }
    formData.append("task_type", task_Type); // 1/0
    console.log(...formData, "addTask-request");

    const result = await addTask(formData);
    if (result.code === 200) {
      console.log("collapsable");
      // fetchTaskListing(0, _status);
      
      if (taskType === true) {
        _status = 1;
      }
      if (taskType === false) {
        _status = 0;
      }
      fetchTaskListing(0, _status);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    return result;
  };

  const handleAddTask = async (taskName) => {
    let _status = "";
    // if (taskType === true) {
    //   _status = 1;
    // }
    // if (taskType === false) {
    //   _status = 0;
    // }
    const task_Type = getTaskType(taskName);
    if (process.env.NODE_ENV == logmodeis) {
      console.log(task_Type, "typeeee---");
    }
    if (String(task_Type) === "2") {
      // _status = 1;
      return await createSection(taskName, 1);
    }
    if (String(task_Type) === "1") {
      // _status = 0;
    
      return await createTask(taskName, task_Type, 0);
    }
  };
  const handleSubmitUpdateTask = async (name, description, id) => {
    let _status = 1;
    if (taskType === true) {
      _status = 1;
    }
    if (taskType === false) {
      _status = 0;
    }
    const task_type = getTaskType(name);
    const formData = new FormData(); 

    formData.append("task_text", name);
    formData.append("description", description);
    formData.append("task_type", task_type);
    console.log(...formData, "update-task-req-collapseable-task-listing");
    
    setIsUpdatingTask(true);
    const result = await changeTaskDetail(formData, id); //update task detail
    if (result.code === 200) {
      setIsUpdatingTask(false);
      handleCloseUpdate();
      fetchTaskListing(0, _status);
      enqueueSnackbar("Task detail updated", { variant: "success" });
    } else {
      setIsUpdatingTask(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleOpenEditTask = (data) => {
    setOpenEditTask(true);
    setRowData(data);
  };

  const handleOpenUpdate = () => {
    setOpenEdit(true);
  };

  const handleCloseUpdate = () => {
    setOpenEdit(false);
  };

  const handleDeleteTask = async () => {
    if (process.env.NODE_ENV == logmodeis) {
      console.log(selectedRow, "row-data");
    }
    const formData = new FormData();
    // formData.append("token", localStorage.getItem("token"));
    // formData.append("task_id", selectedRow.task_id);
    formData.append("project_id", id);
    formData.append("task_order", selectedRow.taskOrder);
    formData.append("vasibility_status", selectedRow.visibilityStatus);
    if (process.env.NODE_ENV == logmodeis) {
      console.log(...formData, "delete-task-req");
    }
    let _status = 1;
    if (String(selectedRow.visibilityStatus) === "1") {
      _status = 1;
    }
    if (String(selectedRow.visibilityStatus) === "0") {
      _status = 0;
    }

    setIsDeletingTask(true);
    handleCloseSectionMenu();
    const result = await deleteTask(selectedRow.task_id);
    if (result.code === 200) {
      setIsDeletingTask(false);
      handleCloseSectionMenu();
      handleCloseDialog();
      fetchTaskListing(0, _status);
      enqueueSnackbar("Task deleted", { variant: "success" });
      selectedRow.members.map((z, k) => {
        if (String(localStorage.getItem("user_id")) !== String(z.user_id)) {
          const payload = {
            receiver_id: z.user_id,
            message: "Your Task has been Deleted",
            project_id: id,
            task_id: selectedRow.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          if (process.env.NODE_ENV == logmodeis) {
            console.log(payload, "team-notification-payload");
          }
          sendSocketNotification(payload);
        }
      });
    } else {
      setIsDeletingTask(false);
      handleCloseDialog();
      handleCloseSectionMenu();
      fetchTaskListing(0, _status);
      enqueueSnackbar(result.message, { variant: "error" });
    }
    const _arr = taskdata.filter((x) => x.id !== id);
    setTaskData(_arr);
  };

  const handleResetEditTask = () => {};

  const handleOpenDialog = (data, i) => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const fetchProjectDetail = async () => {
    const formData = new FormData();
    formData.append("project_id", id);
    console.log(...formData, "fetch-project-detail-req");
    if (process.env.NODE_ENV == logmodeis) {
      console.log(...formData, "fetch-project-detail-req");
    }

    const projectID = formData.get("project_id");
    if (process.env.NODE_ENV == logmodeis) {
      console.log(projectID, "projectID");
    }
    const result = await detailProjectV2(projectID);
    if (result.code === 200) {
      if (process.env.NODE_ENV == logmodeis) {
        console.log(result, "project-detail");
      }
      setTaskStats(result.task_stats);
      setProjectMembers(result.Team_Project);
      setProjectDetail(result.Team_Data);
      if (result.Team_Data.project_visibility_status === false) {
        setPersonal(true);
        setUserType("1")
      }else{
        setUserType("0")
      }
      setIswaiting(false);
    } else {
      navigate("/projects/categories", { replace: true });
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleOpenSectionMenu = (e, task, index) => {
    if (process.env.NODE_ENV == logmodeis) {
      console.log(task, "target-task");
      console.log(index, "target-index");
    }
    setSectionMenuAnchorEl(e.currentTarget);
    setIsSectionMenuOpen(true);
    setRowData(task);
  };
  const handleCloseSectionMenu = () => {
    setIsSectionMenuOpen(false);
    setSectionMenuAnchorEl(null);
  };
  const handleSectionAnchorEl = (e) => {
    setSectionMenuAnchorEl(e.currentTarget);
  };

  const handleMoveToSection = async (section, destination) => {
    let _status = 1;
    // if (taskType === true) {
    //   _status = 1;
    // }
    if (taskType === false) {
      _status = 0;
    }
    if (process.env.NODE_ENV == logmodeis) {
      console.log(section, "ss");
    }
    const formData = new FormData();
    // formData.append("token", localStorage.getItem("token"));
    formData.append("task_id", selectedRow.task_id);
    formData.append("task_section_id", section.id);
    if (process.env.NODE_ENV == logmodeis) {
      console.log(...formData, "move-task-req");
    }
    const resp = await moveTaskToSection(formData);
    if (resp.code === 200) {
      setSelectedSectionIndex(0);
      enqueueSnackbar(`Task moved to ${section.section_name}`, {
        variant: "success",
      });
      fetchTaskListing(0, _status);
    } else {
      fetchTaskListing(0, _status);
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };
  const handleOnDragEnd = async (result) => {
    if (process.env.NODE_ENV == logmodeis) {
      console.log(result, "res");
    }
    if (!result.destination) return;
    if (isMyTodoOn) return;
    //
    // console.log(public_sections, 'pubb');
    const items = Array.from(taskSectionsData);
    let destination_task = null;
    let source_task = null;
    let source_section_id = null;
    let source_section = null;
    items.map((section) => {
      section.section_tasks.map((task, index) => {
        if (String(task.task_id) === String(result.draggableId)) {
          source_task = task;
          source_section_id = task.sectionId;
        }
      });
      //
    });
    items.map((section) => {
      if (String(section.section_id) === String(source_section_id)) {
        source_section = section;
      }
    });

    source_section.section_tasks.map((task, index) => {
      if (String(index) === String(result.destination.index)) {
        destination_task = task;
      }
    });

    if (String(source_task.task_id) === String(destination_task.task_id)) {
      return;
    }
    let spliced_section_tasks = Array.from(source_section.section_tasks);
    if (process.env.NODE_ENV == logmodeis) {
      console.log(spliced_section_tasks, "spl");
    }
    const [splicedItem] = spliced_section_tasks.splice(result.source.index, 1);
    spliced_section_tasks.splice(result.destination.index, 0, splicedItem);
    let task_sections_data = Array.from(taskSectionsData).map((section) => {
      if (String(section.section_id) === String(source_section.section_id)) {
        return { ...section, ["section_tasks"]: spliced_section_tasks };
      }
      return section;
    });

    if (process.env.NODE_ENV == logmodeis) {
      console.log(task_sections_data, "new-data");
    }
    setTaskSectionsData(task_sections_data);

    if (process.env.NODE_ENV == logmodeis) {
      console.log(spliced_section_tasks, "re-ordered");
    }
    if (process.env.NODE_ENV == logmodeis) {
      console.log(source_task, "source-task");
    }
    if (process.env.NODE_ENV == logmodeis) {
      console.log(destination_task, "destination-task");
    }
    const formData = new FormData();
    // formData.append("token", localStorage.getItem("token"));
    // formData.append("task_id", result.draggableId);
    // formData.append("project_id", id);
    formData.append("task_order", destination_task.taskOrder);
    // formData.append("vasibility_status", splicedItem.visibilityStatus);
    console.log(...formData, "task-reorder-req");
    let _status = 1;
    if (String(splicedItem.visibilityStatus) === "1") {
      _status = 1;
    }
    if (String(splicedItem.visibilityStatus) === "0") {
      _status = 0;
    }
    const resp = await taskOrder(formData, result.draggableId);
    if (resp.code === 200) {
      fetchTaskListing(0, _status);
      enqueueSnackbar("Task order changed", { variant: "success" });
    } else {
      fetchTaskListing(0, _status);
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const getAssignedTask = (task) => {
    const userId = localStorage.getItem("user_id");
    const members = task.assign_member;

    const findAssign = members.find(
      (t) => String(t.user_id) === String(userId)
    );
    if (findAssign) {
      return true;
    }
    if (String(task.task_creator_id) === String(userId)) {
      return true;
    }
    return false;
  };
//fetchTaskListing*
  const fetchTaskListing = async (sortValue = 0, task_type = 1) => {
    if(taskType!=null){
    if (JSON.parse(localStorage.getItem(`project_${id}`)).filter.length > 0) {
      // let _status = "";
      // if (taskType === true) {
      //   _status = 1;
      // }
      // if (taskType === false) {
      //   _status = 0;
      // }
      const formData = new FormData();
      // formData.append("token", localStorage.getItem("token"));
      formData.append("project_id", id);
      formData.append("filter_status", task_type);
      let status_array = [];
      let priority_array = [];
      const filter = JSON.parse(
        localStorage.getItem(`project_${id}`)
      ).filter.map((y, j) => {
        if (y.type === "status") {
          status_array.push(y.filterValue);
        }
        if (y.type === "priority") {
          priority_array.push(y.filterValue);
        }
        return y;
      });
      const new_status_array = status_array.map((val) => {
        return val;
      });
      const new_priority_array = priority_array.map((val) => {
        return val;
      });
      formData.append(`status`, JSON.stringify(new_status_array));
      formData.append(`priority`, JSON.stringify(new_priority_array));
      if (process.env.NODE_ENV == logmodeis) {
        console.log(...formData, "task-filter-req");
      }
      const result = await taskFilterTeam(formData);
      console.log("if---",[...formData])

      if (process.env.NODE_ENV == logmodeis) {
        console.log(result, "filtered-task-listing");
      }
      if (result.code === 200) {
        // setFilterData(data);
        const userId = localStorage.getItem("user_id");
        setFilterChip(
          JSON.parse(localStorage.getItem(`project_${id}`)).chipData
        );
        setFilterData(
          JSON.parse(localStorage.getItem(`project_${id}`)).filterData
        );

        let all_expand = true;
        localStorage.setItem("taskFilter", "1");
        let section_data = result.tasks_sections.map((section, index) => {
          return {
            ...section,
            expanded: isExpanded(section.section_id, index),
            section_tasks: get_modified(section.section_tasks),
          };
        });
        localStorage.setItem("localSectionsData", JSON.stringify(section_data));
        setTaskSectionsData(section_data);
        localStorage.setItem("firstExpand", false);
        setIsFilterOn(true);

        localStorage.setItem("taskFilter", "1");
      } else {
        localStorage.setItem("taskFilter", "0");
        enqueueSnackbar(result.message, { variant: "error" });
        setIsFilterOn(false);
      }
    } else {
      // let _status = 1;
      if (process.env.NODE_ENV == logmodeis) {
        console.log(taskType, "type----");
      }
     
      // if (taskType === false) {
      //   _status = 0;
      // }
      localStorage.setItem("taskFilter", "0");

      const formData = new FormData();

      formData.append("project_id", id);
      formData.append("sort_value", selectedSortVal.current);
      
      // formData.append("status", task_type);
      formData.append("status", UserType);
      console.log(UserType, "task_type---");
      if (process.env.NODE_ENV == logmodeis) {
        console.log([...formData], "task-list-req-else");
      }
      console.log([...formData], "api/task/task_list_for_team if---else");
      const result = await taskListCollapsable(formData);
      console.log(result, "res after new task");
      if (result.code === 200) {
        let section_data = result.tasks_sections.map((section, index) => {
          return {
            ...section,
            expanded: isExpanded(section.section_id, index),
            section_tasks: get_modified(section.section_tasks),
          };
        });
        if (process.env.NODE_ENV == logmodeis) {
          console.log(section_data, "sdata");
        }
        localStorage.setItem("localSectionsData", JSON.stringify(section_data));
        setTaskSectionsData(section_data);
        localStorage.setItem("firstExpand", false);
        setIswaiting(false);
        setTimeout(() => {
          if (shouldScroll.current === true) {
            if (!location.state) return;
            if (!location.state.task_id) return;
            if (location.state.task_id === null) return;
            let taskId = location.state.task_id;
            section_data.map((section) => {
              section.section_tasks.map((task) => {
                if (String(task.task_id) === String(taskId)) {
                  if (process.env.NODE_ENV == logmodeis) {
                    console.log(section, "sss");
                  }
                  onlyExpandSection(section.section_id);
                }
              });
            });
            handleScrollWithExpand();
          }
        }, 500);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIswaiting(false);
      }
    }
  }
  };
  const get_modified = (data) => {
    const userId = localStorage.getItem("user_id");
    let modified_tasks = data.map((x, i) => {
      let isAllowed = false;
      if (String(userId) === String(x.task_creator_id)) {
        isAllowed = true;
      }
      return {
        task_id: x.task_id,
        task: x.task_name,
        task_creator_id: x.task_creator_id,
        targetDate: x.target_date === "0000-00-00" ? "" : x.target_date,
        endDate: "",
        end_time: x.end_time,
        status: x.task_status,
        priority: x.task_priority,
        taskOrder: x.task_order,
        detailVisible: true,
        members: x.assign_member,
        isAssigned: getAssignedTask(x),
        visibilityStatus: x.vasibility_status,
        isAllowed: isAllowed,
        isCreator: isAllowed,
        taskCreatorName: x.task_creator_name,
        taskCreatorImage: x.task_creator_image,
        commentsCount: x.comment_count,
        createdDate: x.created_at,
        taskType: x.task_type,
        sectionId: x.section_id,
        description: x.task_distribution,
        unreadComments: x.unread_comment_count,
      };
    });
    return modified_tasks;
  };
  const handleScrollWithExpand = () => {
    if (process.env.NODE_ENV == logmodeis) {
      console.log(location.state, "at-scroll");
    }
    if (!location.state) return;
    if (!location.state.task_id) return;
    if (location.state.task_id === null) return;
    let taskId = location.state.task_id;
    let task = document.getElementById(taskId);
    if (!task) {
      if (process.env.NODE_ENV == logmodeis) {
        console.log("no element found");
      }
      return;
    }
    if (task) {
      setTimeout(() => {
        const rects = task.getBoundingClientRect();
        const yAxis = rects.y;
        const height = rects.height;
        const xAxis = rects.x;
        // task.scrollIntoView({ block: 'end', inline: 'nearest' });
        task.style.transition = "all .5s linear";
        task.style.border = "solid 3px rgb(10, 238, 124)";
        window.scrollTo(xAxis, yAxis - height);
        shouldScroll.current = false;
        setTimeout(() => {
          task.style.border = "solid 1px transparent ";
        }, 3000);
      }, 500);
    }
  };
  const getDefaultExpand = () => {
    let states = localStorage.getItem("localExpandStates");
    let proj_id = params.id;
    if (!states) {
      setDefaultExpand(false);
      return;
    }
    if (states) {
      states = JSON.parse(states);
      if (process.env.NODE_ENV == logmodeis) {
        console.log(states, "states--");
      }
      states.map((item) => {
        if (String(item.id) === String(proj_id)) {
          if (item.expand_all === true) {
            setDefaultExpand(true);
          }
          if (item.expand_all === false) {
            setDefaultExpand(false);
          }
        }
      });
    }
    //
  };
  const handleDefaultExpand = (e) => {
    let proj_id = params.id;
    if (process.env.NODE_ENV == logmodeis) {
      console.log(projectDetail, "project-detail-");
    }
    let local_expand_states = localStorage.getItem("localExpandStates");
    let states = [];
    let already_exist = false;
    if (local_expand_states) {
      local_expand_states = JSON.parse(local_expand_states);
      states = local_expand_states;
      states.map((item) => {
        if (String(item.id) === String(proj_id)) {
          already_exist = true;
        }
      });
    }

    if (e.target.checked === true) {
      if (already_exist) {
        //update
        let updated = states.map((item) => {
          if (String(item.id) === String(proj_id)) {
            return { ...item, expand_all: true };
          }
          return item;
        });
        if (process.env.NODE_ENV == logmodeis) {
          console.log(updated, "updated--");
        }
        localStorage.setItem("localExpandStates", JSON.stringify(updated));
      }
      if (!already_exist) {
        //push
        let item = { id: projectDetail.id, expand_all: true };
        states.push(item);
        localStorage.setItem("localExpandStates", JSON.stringify(states));
      }
      setDefaultExpand(e.target.checked);
      let section_data = taskSectionsData.map((section, index) => {
        return {
          ...section,
          expanded: true,
        };
      });
      setTaskSectionsData(section_data);
    }
    if (e.target.checked === false) {
      if (already_exist) {
        //update
        let updated = states.map((item) => {
          if (String(item.id) === String(proj_id)) {
            return { ...item, expand_all: false };
          }
          return item;
        });

        localStorage.setItem("localExpandStates", JSON.stringify(updated));
      }
      if (!already_exist) {
        //push
        let item = { id: proj_id, expand_all: false };
        states.push(item);
        localStorage.setItem("localExpandStates", JSON.stringify(states));
      }
      setDefaultExpand(e.target.checked);
      let section_data = taskSectionsData.map((section, index) => {
        return {
          ...section,
          expanded: index === 0 ? true : false,
        };
      });
      setTaskSectionsData(section_data);
    }
  };
  const isExpanded = (id, _index) => {
    let proj_id = params.id;
    let states = localStorage.getItem("localExpandStates");
    let should_expand = false;
    let found_item = false;
    if (states) {
      states = JSON.parse(states);
      states.map((item) => {
        if (String(item.id) === String(proj_id)) {
          if (item.expand_all === true) {
            found_item = true;
            should_expand = item.expand_all;
          }
        }
      });
    }
    if (found_item === true) {
      return should_expand;
    }

    const local_sections_data = localStorage.getItem("localSectionsData");
    const parsed_local_sections_data = JSON.parse(local_sections_data);
    if (parsed_local_sections_data?.length === 0) {
      if (_index === 0) {
        return true;
      } else {
        return false;
      }
    }
    const first_expand = localStorage.getItem("firstExpand");
    if (String(first_expand) === "false") {
      let parsed = JSON.parse(local_sections_data);
      let expand = false;
      parsed.map((item) => {
        if (String(item.section_id) === String(id)) {
          expand = item.expanded;
        }
      });
      return expand;
    }
    if (String(first_expand) === "true") {
      if (local_sections_data) {
        let parsed = JSON.parse(local_sections_data);
        let expand = false;
        parsed.map((item) => {
          if (String(item.section_id) === String(id)) {
            if (_index === 0) {
              expand = true;
            } else {
              expand = false;
            }
          }
        });
        return expand;
      }
      if (!local_sections_data) {
        let expand = false;

        if (_index === 0) {
          expand = true;
        } else {
          expand = false;
        }
        return expand;
      }
    }
  };
  const getUpperSection = () => {
    let section = undefined;
    let target_index = undefined;
    if (selectedTargetSection) {
      taskSectionsData.map((section, index) => {
        if (
          String(section.section_id) ===
          String(selectedTargetSection.section_id)
        ) {
          target_index = index - 1;
        }
      });
      section = taskSectionsData[target_index];
    }
    return section;
  };
  const getLowerSection = () => {
    let section = undefined;
    let target_index = undefined;
    if (selectedTargetSection) {
      taskSectionsData.map((section, index) => {
        if (
          String(section.section_id) ===
          String(selectedTargetSection.section_id)
        ) {
          target_index = index + 1;
        }
      });
      section = taskSectionsData[target_index];
    }
    return section;
  };
  const handleChangeSectionOrder = async (data, type) => {
    if (process.env.NODE_ENV == logmodeis) {
      console.log(selectedTargetSection, "target-row");
    }
    let order = undefined;
    let swap_section_id = undefined;
    if (type === 1) {
      const upper_section = getUpperSection();
      if (process.env.NODE_ENV == logmodeis) {
        console.log(upper_section, "needed");
      }
      order = Number(selectedTargetSection.section_order) - 1;
      swap_section_id = upper_section.section_id;
    }
    if (type === 2) {
      const lower_section = getLowerSection();
      if (process.env.NODE_ENV == logmodeis) {
        console.log(lower_section, "needed");
      }
      order = Number(selectedTargetSection.section_order) + 1;
      swap_section_id = lower_section.section_id;
    }
    let _status = 1;
    if (taskType === true) {
      _status = 1;
    }
    if (taskType === false) {
      _status = 0;
    }
    const formData = new FormData();
    // formData.append("token", localStorage.getItem("token"));
    formData.append("section_order", order);
    formData.append("swap_section_id", swap_section_id);
    formData.append("task_section_id", selectedTargetSection.section_id);
    if (process.env.NODE_ENV == logmodeis) {
      console.log(...formData, "move-section-req");
    }
    setIsAccordionMenuOpen(false);
    const result = await updateTaskSectionOrder(formData);
    if (result.code === 200) {
      if (process.env.NODE_ENV == logmodeis) {
        console.log(result, "move-to-res");
      }
      enqueueSnackbar(`Section moved ${type === 1 ? "up" : "down"}`, {
        variant: "success",
      });
      fetchTaskListing(0, _status);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getTitle = () => {
    let title = projectDetail.project_name;
    title = `${title} | Projects`;

    return title;
  };
  const getProjectName = () => {
    let name = projectDetail.project_name;
    return name;
    //-----
    // let count = 0;
    // Object.values(filterData).map((item) => {
    //   if (item === true) {
    //     count = count + 1;
    //   }
    // });

    // if (count > 0) {
    //   let truncated = truncateText(name, 33);
    //   return truncated;
    // }
    // return name;
  };

  const handleApplyLocalFilter = (e) => {
    setMyFilter(e.target.checked);
    localStorage.setItem(
      `project_${id}`,
      JSON.stringify({
        myFilter: e.target.checked,
        filter: JSON.parse(localStorage.getItem(`project_${id}`)).filter,
        chipData: JSON.parse(localStorage.getItem(`project_${id}`)).chipData,
        filterData: JSON.parse(localStorage.getItem(`project_${id}`))
          .filterData,
      })
    );
  };
  const handleApplyLocalFilterSetting = (e) => {
    setMyFilter(e.target.checked);
    localStorage.setItem(
      `project_${id}`,
      JSON.stringify({
        myFilter: e.target.checked,
        filter: JSON.parse(localStorage.getItem(`project_${id}`)).filter,
        chipData: JSON.parse(localStorage.getItem(`project_${id}`)).chipData,
        filterData: JSON.parse(localStorage.getItem(`project_${id}`))
          .filterData,
      })
    );
  };
  const toggleTaskList = (taskType) => {
    if (taskType === true) {
      let todoStatus = localStorage.getItem("todoStatus");
      todoStatus = JSON.parse(todoStatus);
      setPersonal(false);
      setMyFilter(false);
      getTaskSections(1);
      fetchTaskListing(0, 1);
      if (todoStatus === true) {
        setIsMyTodoOn(true);
        return;
      }
      setIsMyTodoOn(false);
    }
    if (taskType === false) {
      getTaskSections(0);
      let todoStatus = localStorage.getItem("todoStatus");
      todoStatus = JSON.parse(todoStatus);
      setPersonal(true);
      setIsMyTodoOn(false);
      fetchTaskListing(0, 0);
    }
  };
  const expandSection = (id) => {
    let changed_expand_state = taskSectionsData.map((item) => {
      if (String(item.section_id) === String(id)) {
        return {
          ...item,
          expanded: !item.expanded,
        };
      }
      return item;
    });
    prevSections.current = changed_expand_state;
    setTaskSectionsData((sections) =>
      sections.map((item) => {
        if (String(item.section_id) === String(id)) {
          return {
            ...item,
            expanded: !item.expanded,
          };
        }
        return item;
      })
    );
  };
  const onlyExpandSection = (id) => {
    let changed_expand_state = taskSectionsData.map((item) => {
      if (String(item.section_id) === String(id)) {
        return {
          ...item,
          expanded: true,
        };
      }
      return item;
    });
    prevSections.current = changed_expand_state;
    setTaskSectionsData((sections) =>
      sections.map((item) => {
        if (String(item.section_id) === String(id)) {
          return {
            ...item,
            expanded: true,
          };
        }
        return item;
      })
    );
  };
  const handleSearchTask = (val) => {
    setSearchValue(val);
  };
  const handleOpenAccordionMenu = (e, section, index) => {
    if (process.env.NODE_ENV == logmodeis) {
      console.log(section, "target-section");
    }
    setAccordionMenuAnchorEl(e.currentTarget);
    setIsAccordionMenuOpen(true);
    setSelectedTargetSection(section);
    let is_first = false;
    let is_last = false;
    taskSectionsData.map((_section, index) => {
      if (String(_section.section_id) === String(section.section_id)) {
        if (index === 0) {
          is_first = true;
        }
      }
    });
    if (is_first) {
      setIsMoveUpButtonDisabled(true);
    }
    if (!is_first) {
      setIsMoveUpButtonDisabled(false);
    }
    taskSectionsData.map((_section, index) => {
      if (String(_section.section_id) === String(section.section_id)) {
        if (index === taskSectionsData.length - 1) {
          is_last = true;
        }
      }
    });
    if (is_last) {
      setIsMoveDownButtonDisabled(true);
    }
    if (!is_last) {
      setIsMoveDownButtonDisabled(false);
    }
  };
  const calculateTaskStats = () => {
    let filtered = [];
    taskSectionsData.map((section) => {
      section.section_tasks.map((task) => {
        if (
          String(task.taskType) !== String(2) &&
          String(task.visibilityStatus) !== String(0)
        ) {
          filtered.push(task);
        }
      });
    });
    const all = filtered.length;
    let completed = 0;
    let pending = 0;
    filtered.forEach((task) => {
      if (String(task.status) === "3" || String(task.status) === "5") {
        completed += 1;
      }
    });
    pending = all - completed;

    setProjectStats({ all, pending, completed });
  };

  const setFirstLocalStorage = () => {
    if (localStorage.getItem(`project_${id}`)) {
      localStorage.setItem(
        `project_${id}`,
        JSON.stringify({
          myFilter: JSON.parse(localStorage.getItem(`project_${id}`)).myFilter,
          filter: JSON.parse(localStorage.getItem(`project_${id}`)).filter,
          chipData: JSON.parse(localStorage.getItem(`project_${id}`)).chipData,
          filterData: JSON.parse(localStorage.getItem(`project_${id}`))
            .filterData,
        })
      );
      setMyFilter(JSON.parse(localStorage.getItem(`project_${id}`)).myFilter);
    } else {
      localStorage.setItem(
        `project_${id}`,
        JSON.stringify({
          myFilter,
          filter: [],
          chipData: [],
          filterData: [],
        })
      );
    }
  };
  const handleTodoChange = (e) => {
    setIsMyTodoOn(e.target.checked);
    let status = "false";
    if (e.target.checked === true) {
      status = "true";
    }
    localStorage.setItem("todoStatus", status);
  };
  const handleLocalTaskSearch = (input, array) => {
    const task_search_with_team_names = (task) => {
      //
      let merged_names = task.task;
      task.members.map((member) => {
        merged_names =
          merged_names + " " + member.first_name + " " + member.last_name;
      });
      return merged_names;
      // return task.task;
    };
    let filtered = filter(
      array,
      (_task) =>
        task_search_with_team_names(_task)
          .toLowerCase()
          .indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const getFilteredList = (data) => {
    if (personal) {
      const filtered_ = handleLocalTaskSearch(searchValue, data);
      return filtered_;
    }
    if (isMyTodoOn) {
      let todo = [];
      data.map((task) => {
        if (
          task.isAssigned === true &&
          String(task.visibilityStatus) === String(1)
        ) {
          todo.push(task);
        }
      });
      const filtered_ = handleLocalTaskSearch(searchValue, todo);
      return filtered_;
    }
    if (!isMyTodoOn) {
      const filtered_ = handleLocalTaskSearch(searchValue, data);
      return filtered_;
    }
  };
  const getFilteredTasks = (data) => {
    data = [...taskdata];
    if (searchValue !== "") {
      const filtered = handleLocalTaskSearch(searchValue, data);
      data = [...filtered];
    }

    if (taskSortStatus.status === true && taskSortStatus.type !== "default") {
      const sorted = handleSort(
        taskSortStatus.type,
        taskSortStatus.value,
        data
      );
      if (process.env.NODE_ENV == logmodeis) {
        console.log(sorted, "sorted");
      }
      data = [...sorted];
    }

    if (isMyTodoOn) {
      let todo = [];
      data.map((task, index) => {
        if (
          task.isAssigned === true &&
          String(task.visibilityStatus) === String(1)
        ) {
          todo.push(task);
        }
        if (isFilterOn) {
          let filtered = [];
          sidebarFilterData.map((task, index) => {
            if (
              task.isAssigned === true &&
              String(task.visibilityStatus) === String(1)
            ) {
              filtered.push(task);
            }
          });

          return filtered;
        }
      });
      if (isFilterOn) {
        let my_todo = [];
        sidebarFilterData.map((task, index) => {
          if (
            task.isAssigned === true &&
            String(task.visibilityStatus) === String(1)
          ) {
            my_todo.push(task);
          }
        });

        return my_todo;
      }

      return todo;
    }
    if (personal) {
      let personal_tasks = [];
      data.map((task) => {
        console.log(task,"taskx---")
        if (
          String(task.visibilityStatus) === String(0) &&
          task.isCreator === true
        ) {
          task.isAssigned = true;
          personal_tasks.push(task);
        }
      });

      if (isFilterOn) {
        let filtered_personal = [];

        sidebarFilterData.map((task, index) => {
          if (
            String(task.visibilityStatus) === "0" &&
            task.isCreator === true
          ) {
            task.isAssigned = true;
            filtered_personal.push(task);
          }
        });

        return filtered_personal;
      }

      return personal_tasks;
    }
    if (!personal) {
      let projectTasks = [];
      data.map((task, index) => {
        if (String(task.visibilityStatus) === "1") {
          projectTasks.push(task);
        }
      });
      if (isFilterOn) {
        let filtered = [];
        sidebarFilterData.map((task, index) => {
          if (String(task.visibilityStatus) === "1") {
            filtered.push(task);
          }
        });

        return filtered;
      }

      return projectTasks;
    }
  };
  const handleSort = (type, option, data) => {
    if (type === "priority") {
      let sorted = data.sort((a, b) => {
        let t1Priority = a.priority;
        t1Priority = parseInt(t1Priority);
        a = { ...a, ["intPriority"]: t1Priority };
        let t2Priority = b.priority;
        t2Priority = parseInt(t2Priority);
        b = { ...b, ["intPriority"]: t2Priority };
        if (option === "priorityDesc") {
          return a.intPriority - b.intPriority;
        }
        return b.intPriority - a.intPriority;
      });
      return sorted;
    }
    if (type === "date") {
      let sorted = data.sort((a, b) => {
        let aDate = a.createdDate;
        let bDate = b.createdDate;
        if (option === "oldest") {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ["isDateGreater"]: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ["isDateGreater"]: res2 };
          return a.isDateGreater - b.isDateGreater;
        }
        if (option === "newest") {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ["isDateGreater"]: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ["isDateGreater"]: res2 };
          return b.isDateGreater - a.isDateGreater;
        }
      });

      return sorted;
    }
  };

  const handleSortChange = (type, value) => {
    let _status = 1;
    if (taskType === true) {
      _status = 1;
    }
    if (taskType === false) {
      _status = 0;
    }

    if (process.env.NODE_ENV == logmodeis) {
      console.log(type, value);
    }
    fetchTaskListing(value, _status);
  };
  const handleOpenTotalMemberListing = () => {
    setOpenTotalMemberListing(true);
  };
  function handleClose(id) {
    setOpenStatus(false);
    setOpenPriority(false);
    setOpenTotalMemberListing(false);
  }
  const closeCommentsDrawer = () => {
    setIsCommentDrawerOpen(false);
  };
  const markCommentAsRead = (taskId) => {
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: section.section_tasks.map((task) => {
            if (String(task.task_id) === String(taskId)) {
              console.log(task, 'add-member');
              return { ...task, unreadComments: 0 };
            }
            return task;
          }),
        };
      })
    );
  };
  const getSections = (data) => {
    if (data.lenght < 0) return [];
    let sections = [];
    let Data = [];
    let user_id = localStorage.getItem("user_id");
    if (personal && taskType === false) {
      data.map((task) => {
        if (
          String(task.visibilityStatus) === String(0) &&
          String(task.task_creator_id) === String(user_id)
        ) {
          Data.push(task);
        }
      });
    }
    if (!personal && taskType === true) {
      data.map((task) => {
        if (String(task.visibilityStatus) === String(1)) {
          Data.push(task);
        }
      });
    }
    Data.map((task, index) => {
      if (String(task.taskType) === String(2)) {
        sections.push(task);
      }
    });
    if (sections.length > 0) {
      if (personal) {
        setSelectedSection(sections[selectedSectionIndexPrivate]);
      }
      if (!personal) {
        setSelectedSection(sections[selectedSectionIndexPublic]);
      }
    }
    return sections;
  };
  const onSectionChange = (value) => {
    if (process.env.NODE_ENV == logmodeis) {
      console.log(value, "section-change");
    }
  };

  const isDragDisabled = () => {
    if (user.allow_reorder_task === false) {
      return true;
    }
    let disabled = false;
    if (searchValue !== "") {
      disabled = false;
    }
    if (String(localStorage.getItem("taskFilter")) === String(1)) {
      disabled = true;
    }
    if (String(localStorage.getItem("taskFilter")) === String(0)) {
      disabled = false;
    }

    if (taskSortStatus.status === true) {
      disabled = true;
    }
    if (isMyTodoOn) {
      disabled = true;
    }
    return disabled;
  };
  const getTaskSections = async (status) => {
    setIsSectionSelectDisabled(true);
    const formData = new FormData();

    formData.append("project_id", id);
    formData.append("status", status);

    const result = await taskSectionsList(formData);

    if (result.code === 200) {
      console.log(result,"taskSectionsList")
      setIsSectionSelectDisabled(false);
      setTaskSectionsListing(result.active_task_section);
      let def_section_index = undefined;
      let [def_section] = result.active_task_section.filter((item, index) => {
        if (item.is_default === true) {
          def_section_index = index;
          return item;
        }
      });
      if (process.env.NODE_ENV == logmodeis) {
        console.log(def_section, "def-section");
      }
      if (def_section) {
        setCurrentSelectedSection(def_section);
        setSelectedSectionIndex(def_section_index);
      } else {
        setCurrentSelectedSection(result.active_task_section[0]);
        setSelectedSectionIndex(0);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  React.useEffect(() => {
    localStorage.setItem("firstExpand", true);
    getDefaultExpand();
  }, []);

  React.useEffect(() => {
    let todoStatus = localStorage.getItem("todoStatus");
    todoStatus = JSON.parse(todoStatus);
    if (todoStatus === true) {
      setIsMyTodoOn(true);
    }
    if (process.env.NODE_ENV == logmodeis) {
      console.log(navigator, "navigator");
    }
  }, []);
  
  React.useEffect(() => {
    document.title = getTitle();
  }, [projectDetail]);

  React.useEffect(() => {
    
    setFirstLocalStorage();
    fetchProjectDetail();
    
    // fetchTaskListing(0, 1);
  }, []);
  React.useEffect(()=>{
    if(UserType!= ""){
  
    getTaskSections(UserType);
    fetchTaskListing(0, 1);
    }
  },[UserType])

  React.useEffect(() => {
    let sections_data = localStorage.getItem("localSectionsData");
    if (sections_data) {
      let parsed = JSON.parse(sections_data);
      setTaskSectionsData(parsed);
    }
    localStorage.setItem("localSectionsData", sections_data);
    return () => {
      localStorage.removeItem("localSectionsData");
    };
  }, []);
  React.useEffect(() => {
    //runs on every render
    let sections_data = JSON.stringify(taskSectionsData);
    localStorage.setItem("localSectionsData", sections_data);
  });
  React.useEffect(() => {
    calculateTaskStats();
  }, [taskSectionsData]);
  React.useEffect(() => {
    getDefaultExpand();
  }, [projectDetail]);
  React.useEffect(() => {
    setTaskSections(getSections(taskdata));
    if (taskSections.length > 0) {
      if (personal) {
        setSelectedSection(taskSections[selectedSectionIndexPrivate]);
      }
      if (!personal) {
        setSelectedSection(taskSections[selectedSectionIndexPublic]);
      }
    }
  }, [taskdata, taskType]);
  React.useEffect(() => {
    // if(project)
    setTaskType(String(projectDetail.project_visibility_status));
  }, [projectDetail]);
  React.useEffect(() => {
    if (personal) {
      getTaskSections(0);
      setTaskType(false);
    }
    if (!personal) {
      getTaskSections(1);
      setTaskType(true);
    }
  }, [personal]);
  React.useEffect(() => {
    if (isFilterOn) {
      getFilteredTasks(sidebarFilterData);
    }
  }, [sidebarFilterData]);

  return (
    <Page title="Tasks | Support Task Portal">
      <Container maxWidth="xl" style={{ backgroundColor: "#fff" }}>
        <div className="row main-header">
          <div
            className="col-lg-3 col-md-12 col-sm-12 pl-0 align-items-center"
            style={{ display: "flex", paddingRight: "0px" }}
          >
            <IconButton
              onClick={() =>
                location.state?.is_copied_link
                  ? navigate("/projects/categories")
                  : navigate(-1)
              }
            >
              <ArrowBackIcon />
            </IconButton>
            <Tooltip title="Click to View Description">
              <span role="button" onClick={() => openProjectDrawer()}>
                <Typography
                  style={{ fontsize: 16, fontWeight: "bold", paddingLeft: 8 }}
                >
                  {getProjectName()}
                </Typography>
              </span>
            </Tooltip>
          </div>
          <Stack
            direction="row"
            spacing={1}
            flexShrink={0}
            sx={{ my: 1 }}
            className="d-flex ml-auto"
          >
            <ProjectFilterSidebar
              TaskType={
                <React.Fragment>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={taskType}
                      onChange={(e) => setTaskType(e.target.value)}
                      row
                    >
                      <FormControlLabel
                        sx={{ alignSelf: "center" }}
                        value="1"
                        control={<Radio />}
                        label="Project Tasks"
                        onClick={() => {
                          toggleTaskList("1");
                        }}
                        color="inherit"
                      />
                      <FormControlLabel
                        sx={{ alignSelf: "center" }}
                        value="0"
                        control={<Radio />}
                        label="Personal Tasks"
                        onClick={() => {
                          // togglepPersonalTasks();
                          toggleTaskList("0");
                        }}
                        color="inherit"
                      />
                    </RadioGroup>
                  </FormControl>
                </React.Fragment>
              }
              // formik={formik}
              setMyFilter={setIsFilterOn}
              ifFilterOn={isFilterOn}
              isTaskPersonal={personal}
              isFilterOn={myFilter}
              onChangeSwitch={handleApplyLocalFilter}
              isOpenFilter={openFilter}
              isFilterData={filterData}
              isFilterChip={filterChip}
              onResetFilter={handleResetFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
              onFilterData={handleFilterData}
              onChangeFilterData={handleChangeFilterData}
            />
            <ProjectSetting
              MyTodo={
                <React.Fragment>
                  <FormControlLabel
                    value="end"
                    control={
                      <Switch
                        color="primary"
                        checked={isMyTodoOn}
                        onChange={(e) => handleTodoChange(e)}
                        sx={{ marginLeft: "10px" }}
                      />
                    }
                    label="Only my todo's"
                    labelPlacement="end"
                  />
                </React.Fragment>
              }
              onResetFilterSetting={handleResetFilterSetting}
              onOpenFilterSetting={handleOpenFilterSetting}
              onCloseFilterSetting={handleCloseFilterSetting}
              isTaskPersonal={personal}
              isFilterSettingOn={myFilterSetting}
              onChangeSettingSwitch={handleApplyLocalFilterSetting}
              isOpenFilterSetting={openFilterSetting}
              Expand={
                <React.Fragment>
                  <div>
                    <Tooltip arrow title="expand sections by default">
                      <FormControlLabel
                        value="end"
                        control={
                          <Switch
                            color="primary"
                            checked={defaultExpand}
                            onChange={(e) => handleDefaultExpand(e)}
                            sx={{ marginLeft: "10px" }}
                          />
                        }
                        label="Expand"
                        labelPlacement="end"
                      />
                    </Tooltip>
                  </div>
                </React.Fragment>
              }
              ProjectStats={
                <React.Fragment>
                  <div className="col-lg-3 col-md-6 col-sm-12 d-flex mb-1 mb-md-0">
                    <div
                      className="status-circle"
                      style={{
                        backgroundColor: "#3366FF",
                        marginRight: "20px",
                        marginBottom: "5px",
                      }}
                    >
                      <Typography sx={{ fontSize: 14 }}>
                        {projectStats.pending}
                      </Typography>
                    </div>
                    <Typography
                      sx={{ cursor: "pointer", fontSize: 14, mb: "5px" }}
                      className="date-css"
                    >
                      Pending&nbsp;
                    </Typography>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-12 d-flex mb-1 mb-md-0">
                    <div
                      className="status-circle"
                      style={{
                        marginRight: "20px",
                        marginBottom: "5px",
                        backgroundColor: "#00AB55",
                      }}
                    >
                      <Typography sx={{ fontSize: 14 }}>
                        {projectStats.completed}
                      </Typography>
                    </div>
                    <Typography
                      sx={{ cursor: "pointer", fontSize: 14 }}
                      className="date-css"
                    >
                      Complete&nbsp;&nbsp;
                    </Typography>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 d-flex mb-1 mb-md-0">
                    <div
                      className="status-circle"
                      style={{
                        marginRight: "20px",
                        marginBottom: "5px",
                        backgroundColor: "#c5c5c5",
                      }}
                    >
                      <Typography sx={{ fontSize: 14 }}>
                        {projectStats.all}
                      </Typography>
                    </div>
                    <Typography
                      sx={{ cursor: "pointer", fontSize: 14 }}
                      className="date-css"
                    >
                      Total&nbsp;&nbsp;&nbsp;
                    </Typography>
                  </div>
                </React.Fragment>
              }
            />

            <TaskSortV2
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              onSortChange={handleSortChange}
              selectedSortVal={selectedSortVal}
              data={null}
            />

            <EditTaskSidebar
              isOpenEditTask={openEditTask}
              isTaskData={selectedRow}
              onResetEditTask={handleResetEditTask}
              onOpenEditTask={handleOpenEditTask}
              onCloseEditTask={() => setOpenEditTask(false)}
              openUpdate={handleOpenUpdate}
              openEdit={openEdit}
              SubmitTask={handleSubmitUpdateTask}
              isUpdatingTask={isUpdatingTask}
            />
            <CommentsDrawer
              isTaskData={selectedRow}
              getTaskList={fetchTaskListing}
              projectDetail={projectDetail}
              isCommentOpen={isCommentDrawerOpen}
              onCloseComment={closeCommentsDrawer}
              markCommentAsRead={markCommentAsRead}
              verifiedListing={false}
            />
            <ProjectDrawer
              onClose={closeProjectDrawer}
              isOpen={isProjectDrawerOpen}
              projectDetail={projectDetail}
            />
            <CircularProgressWithLabel
              value={
                projectStats.completed !== 0 && projectStats.all !== 0
                  ? (projectStats.completed / projectStats.all) * 100
                  : 0
              }
            />
            <Tooltip title="Team Members">
              <AvatarGroup
                spacing={-2}
                max={3}
                style={{ cursor: "pointer", marginLeft: 4 }}
                ref={anchorTotalMembers}
                onClick={() => handleOpenTotalMemberListing(true)}
              >
                {projectMembers?.length > 0 &&
                  projectMembers?.map((member, index) => (
                    <span key={index}>
                      {member.image !== "" ? (
                        <Avatar
                          src={baseUri + member.image}
                          style={{ width: 30, height: 30 }}
                        />
                      ) : (
                        <Avatar style={{ width: 30, height: 30 }}>
                          {member.first_name.substring(0, 1)}
                        </Avatar>
                      )}
                    </span>
                  ))}
              </AvatarGroup>
            </Tooltip>
            <Popover
              open={openTotalMemberListing}
              onClose={() => handleClose()}
              anchorEl={anchorTotalMembers.current}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  mt: 1.5,
                  ml: 0.5,
                  width: 230,
                  height: 280,
                  overflow: "auto",
                  boxShadow: (theme) => theme.customShadows.z20,
                  border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`,
                },
              }}
            >
              <ArrowStyle className="arrow" />
              <List>
                <ListItem>
                  <ListItemText primary="Team Members" />
                </ListItem>
                {projectMembers?.length > 0
                  ? projectMembers?.map((member, index) => (
                      <div key={index}>
                        <ListItem>
                          <ListItemIcon>
                            {member.image !== "" ? (
                              <Avatar
                                alt="Remy Sharp"
                                src={baseUri + member.image}
                                style={{ height: 30, width: 30 }}
                              />
                            ) : (
                              <Avatar
                                alt="Remy Sharp"
                                style={{ height: 30, width: 30 }}
                              >
                                {member.first_name.substring(0, 1)}
                              </Avatar>
                            )}
                          </ListItemIcon>
                          <ListItemText>
                            {member.first_name} {member.last_name}
                          </ListItemText>
                        </ListItem>
                      </div>
                    ))
                  : ""}
              </List>
            </Popover>
          </Stack>

          <div className="mr-1 ">
            <TopSearchbar onChange={handleSearchTask} />
          </div>
        </div>
        <Stack
          mt={2}
          mb={2}
          flexWrap="wrap"
          direction="row"
          position="relative"
          justifyContent="space-between"
        >
          <TaskCreateField onCreateTask={handleAddTask} />

          {/* <div className="col-sm-9 px-0 mb-3 mb-sm-0">
            {taskfield === false ? (
              <div
                style={{
                  display: "flex",
                  border: "1px dashed black",
                  width: "100%",
                  padding: 15,
                  borderRadius: 10,
                }}
                role="button"
                tabIndex="0"
                onClick={() => showAddTask()}
                onKeyDown={() => showAddTask()}
              >
                <AddIcon style={{ color: "green" }} />
                <Typography sx={{ ml: 1 }}>Create Task</Typography>
              </div>
            ) : (
              <form className="w-100">
                <TextField
                  label="Task"
                  className="w-100"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  onKeyPress={(event) =>
                    event.key === "Enter" ? handleAddTask(event) : null
                  }
                  autoFocus
                  onBlur={() => {
                    handleTaskInputBlur();
                  }}
                />
              </form>
            )}
            {showAddTaskButton() && (
              <Tooltip title="Add task">
                <span
                  style={{ position: "absolute", zIndex: 2, right: 4, top: 8 }}
                >
                  <IconButton
                    disabled={isCreatingTask}
                    onClick={(e) => handleAddTask(e)}
                  >
                    {isCreatingTask ? (
                      <CircularProgress
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      <SendIcon />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </div> */}
          <div className="col-sm-3 pr-0 d-flex flex-col justify-content-end align-items-center">
            <SectionSelectV2
              isDisabled={isSectionSelectDisabled}
              data={taskSections}
              onChange={onSectionChange}
              selectedIndex={selectedSectionIndex}
              setSelectedIndex={setSelectedSectionIndex}
              setSelectedSection={setSelectedSection}
              taskSectionsListing={taskSectionsListing}
            />
          </div>
        </Stack>
        {iswaiting && <Loader />}
        {!iswaiting && (
          <div className="mt-3">
            {iswaiting === false ? (
              taskSectionsData.map((section, index) => {
                return (
                  <TaskSectionAccordion
                    projectMembers={projectMembers}
                    projectDetail={projectDetail}
                    fetchTaskListing={fetchTaskListing}
                    expandSection={expandSection}
                    setOpenEditTask={setOpenEditTask}
                    setIsSectionMenuOpen={setIsSectionMenuOpen}
                    setSectionMenuAnchorEl={setSectionMenuAnchorEl}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setTaskData={setTaskData}
                    taskSortStatus={taskSortStatus}
                    setRowData={setRowData}
                    setIsCommentDrawerOpen={setIsCommentDrawerOpen}
                    key={index}
                    handleOnDragEnd={handleOnDragEnd}
                    section={section}
                    // data={section.section_tasks}
                    personal={personal}
                    taskType={taskType}
                    isMyTodoOn={isMyTodoOn}
                    setOpenPriority={setOpenPriority}
                    setOpenStatus={setOpenStatus}
                    setOpenTotalMemberListing={setOpenTotalMemberListing}
                    data={getFilteredList(section.section_tasks)}
                    setTaskSectionsData={setTaskSectionsData}
                    openAccordionMenu={handleOpenAccordionMenu}
                    selectedIndex={selectedIndex}
                    taskSectionsData={taskSectionsData}
                  />
                );
              })
            ) : (
              <Sekeleton totalTask={projectStats.all} />
            )}
          </div>
        )}

        {/* ========================== delete task dialog========================= */}

        <Dialog open={opendialog} onClose={handleCloseDialog}>
          <DialogTitle>Are you sure you want to delete this task ?</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <LoadingButton loading={isDeletingTask} onClick={handleDeleteTask}>
              Agree
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {/* ==================  Popovers ==================== */}
        <PopoverSectionMenu
          isOpen={isSectionMenuOpen}
          onOpen={handleOpenSectionMenu}
          onClose={handleCloseSectionMenu}
          anchorEl={sectionMenuAnchorEl}
          setAnchorEl={handleSectionAnchorEl}
          handleOpenDialog={handleOpenDialog}
          sections={taskSections}
          handleMoveSection={handleMoveToSection}
          selectedRow={selectedRow}
          isPersonal={personal}
          isDragDisabled={isDragDisabled}
          showMoveTo={true}
          data={taskSectionsListing}
        />
        <AccordionMenu
          isOpen={isAccordionMenuOpen}
          onOpen={() => setIsAccordionMenuOpen(true)}
          onClose={() => setIsAccordionMenuOpen(false)}
          anchorEl={accordionMenuAnchorEl}
          selectedSection={selectedTargetSection}
          handleChangeSectionOrder={handleChangeSectionOrder}
          sectionList={taskSectionsData}
          isMoveUpButtonDisabled={isMoveUpButtonDisabled}
          isMoveDownButtonDisabled={isMoveDownButtonDisabled}
          fetchTaskListing={fetchTaskListing}
          getTaskSections={getTaskSections}
          taskType={taskType}
          setSelectedSectionIndex={setSelectedSectionIndex}
          openEditSection={() => {
            setIsSectionSidebarOpen(true);
            setIsAccordionMenuOpen(false);
          }}
        />
        {/* section edit sidebar */}
        <SectionEditSidebar
          isOpen={isSectionSidebarOpen}
          onClose={() => setIsSectionSidebarOpen(false)}
          isSectionData={selectedTargetSection}
          fetchTaskListing={fetchTaskListing}
          taskType={taskType}
        />
      </Container>
    </Page>
  );
}
