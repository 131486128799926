import dc_token from "../../utils/tokenDecryption"
import { invokeApi } from '../../bl_libs/invokeApi';

export const teamNotification = async () => {
  const requestObj = {
    path: `api/notify/notify_listing_for_team`,
    method: 'GET',
    headers: {
      'x-sh-auth': dc_token()
    },
    // postData: data
  };
  return invokeApi(requestObj);
};

export const teamNotificationStatusChange = async (notify_id,data) => {
  const requestObj = {
    path: `api/notify/change_notify_status/${notify_id}`,
    method: 'PUT',
    headers: {
      'x-sh-auth': dc_token()
    },
    postData: data
  };
  return invokeApi(requestObj);
};

export const teamNotificationAllRead = async () => {
  const requestObj = {
    path: `api/notify/mark_all_as_read`,
    method: 'GET',
    headers: {
      'x-sh-auth': dc_token()
    },
    // postData: data
  };
  return invokeApi(requestObj);
};
