import { Typography } from '@mui/material';
import React, { useState } from 'react';
import moment from 'moment';
import { baseUri } from 'src/config/config';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import Reaction from './Reaction';
import MessageReactions from './MessageReactions';
const ChatMessageItem = ({ item, onEdit, isLastItem, applyReaction }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const toggleTooltip = (value) => {
    setIsTooltipOpen(value);
  };

  const onEmojiClick = async (data) => {
    applyReaction(data, item);
  };
  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === 'Apple Computer, Inc.') {
      is_safari = true;
    }
    return is_safari;
  };
  const getActualTime = (date) => {
    if (!date) {
      return '';
    }
    let date_safari = date;
    for (let i = 0; i < date_safari.length; i++) {
      if (date_safari.charAt(i) === '-') {
        date_safari = date_safari.replace('-', '/');
      }
    }
    const serverDate = new Date(date);
    const difference = serverDate.getTimezoneOffset();
    const serverDate_safari = new Date(date_safari);
    const difference_safari = serverDate_safari.getTimezoneOffset();
    if (detectSafariBrowser()) {
      const setTime = moment(moment(date_safari).subtract(difference_safari, 'm')).format(
        'MMM,DD, hh:mm a'
      );
      return setTime;
    }
    if (!detectSafariBrowser()) {
      const setTime = moment(moment(serverDate).subtract(difference, 'm')).format(
        'MMM,DD, hh:mm a'
      );

      return setTime;
    }
  };
  const isImageMessage = (item) => {
    if (item.file_url === '') {
      return false;
    }

    return true;
  };
  const isInfoMessage = (message) => {
    if (!message) {
      return false;
    }
    if (String(message.message_type) === 'info_message') {
      return true;
    }
    return false;
  };
  const isGroupMessage = (message) => {
    if (String(message.chat_id) === '0') {
      return true;
    }
    return false;
  };
  if (item.isCreator) {
    return !isInfoMessage(item) ? (
      <>
        <div
          data-msg-id={item.id}
          className={`d-flex align-items-center justify-content-end message-margin ${
            isLastItem && 'mb-3'
          }`}
        >
          <div className='mr-1 sent-message  message-item'>
            {isGroupMessage(item) && (
              <div>
                <Typography fontWeight='500' fontSize='12px' color='rgb(0, 171, 85)'>
                  you
                </Typography>
              </div>
            )}
            {isImageMessage(item) ? (
              <>
                <img src={baseUri + item.file_url} alt={'image'} />
                <Typography variant='caption'>{item.message}</Typography>
              </>
            ) : (
              <Typography style={{ whiteSpace: 'pre-wrap' }} className='clickable-link'>
                {item.message}
              </Typography>
            )}
            <div>
              <Typography fontSize='11px' textAlign='end'>
                {getActualTime(item.created_at)}
              </Typography>
            </div>
            <MessageReactions data={item.reactions} />
          </div>
          <div>
            <IconButton size='small' disabled={false} onClick={(e) => onEdit(e, item)}>
              <MoreVertIcon height='10px' width='10px' />
            </IconButton>
          </div>
        </div>
      </>
    ) : (
      <>
        <div className='d-flex align-items-center justify-content-center'>
          <Typography
            component='span'
            className='info-message'
            textAlign='center'
            variant='subtitle2'
          >
            chat created
          </Typography>
        </div>
      </>
    );
  } else
    return !isInfoMessage(item) ? (
      <>
        <div
          data-msg-id={item.id}
          className={`d-flex align-items-center message-margin justify-content-start ${
            isLastItem && 'mb-3'
          }`}
        >
          <div className='ml-1 message-item received-message'>
            {isGroupMessage(item) && (
              <div>
                <Typography fontWeight='500' fontSize='12px' color='rgb(50, 132, 255)'>
                  {item.first_name + item.last_name}
                </Typography>
              </div>
            )}
            {isImageMessage(item) ? (
              <>
                <img src={baseUri + item.file_url} alt={'image'} />
                <Typography variant='caption'>{item.message}</Typography>
              </>
            ) : (
              <Typography style={{ whiteSpace: 'pre-wrap' }} className='clickable-link'>
                {item.message}
              </Typography>
            )}
            <div>
              <Typography fontSize='11px' textAlign='end'>
                {getActualTime(item.created_at)}
              </Typography>
            </div>
            <Reaction
              toggleTooltip={toggleTooltip}
              isTooltipOpen={isTooltipOpen}
              onEmojiClick={onEmojiClick}
            />
            <MessageReactions data={item.reactions} />
          </div>
        </div>
      </>
    ) : (
      <>
        <div className='d-flex align-items-center justify-content-center'>
          <Typography
            component='span'
            className='info-message'
            textAlign='center'
            variant='subtitle2'
          >
            chat created
          </Typography>
        </div>
      </>
    );
};

export default ChatMessageItem;
