import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
export default function MessageMenu({
  anchorEl,
  setAnchorEl,
  handleOnDel,
  toggle,
  isDelDialogOpen,
  toggleDelDialog,
  isDeletingMessage,
}) {
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onDel = (item) => {
    handleOnDel();
  };
  
  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <List>
          {/* <ListItem disabled={false} sx={{ padding: '.2rem' }}>
            <ListItemButton disabled={false} onClick={() => onEdit()}>
              <ListItemText>
                <Typography sx={{ fontSize: '0.8rem' }}>Edit</Typography>
              </ListItemText>
              <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                <EditIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem> */}

          <ListItem disabled={false} sx={{ padding: '.2rem' }}>
            <ListItemButton disabled={false} onClick={() => toggleDelDialog(true)}>
              <ListItemText>
                {/* Delete */}
                <Typography sx={{ fontSize: '0.8rem' }}>Delete</Typography>
              </ListItemText>
              <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                <DeleteForeverOutlinedIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {/* confirmation dialog */}
      <Dialog
        open={isDelDialogOpen}
        onClose={() => toggleDelDialog(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Delete message</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this message?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isDeletingMessage} onClick={() => onDel()}>
            Yes
          </LoadingButton>
          <Button
            onClick={() => toggleDelDialog(false)}
            //    autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
