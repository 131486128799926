import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import {
  Container,
  TextField,
  Grid,
  IconButton,
  CircularProgress,
  Button,
  Typography,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { teamDetail, editTeam } from '../../DAL/Team/Team';
import { departmentList } from '../../DAL/Department/Department';
import { baseUri } from '../../config/config';

const useStyles = makeStyles(() => ({
  img: {
    width: '100%',
    height: 300,
  },
  loading: {
    marginLeft: '50%',
    marginTop: '20%',
  },
}));

function EditTeam(props) {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setIswaiting] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [department, setDepartment] = useState('');
  const [departmentData, setDepartmentData] = useState([]);
  const [Image, setimage] = React.useState('');
  const [role, setRole] = useState('');
  const [file, setProfileImage] = React.useState('');
  const [status, setStatus] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let _status;
    if (status === true) {
      _status = 1;
    } else {
      _status = 0;
    }
    setIswaiting(true);
    const formData = new FormData();
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('role', role);
    formData.append('status', _status);
    formData.append('team_department', department);
    if (Image) {
      formData.append('team_image', Image);
    }
      
    formData.append('user_id', id);
    const result = await editTeam(formData);
    if (result.code === 200) {
      setIswaiting(false);
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      setIswaiting(false);
    }
  };

  const fileChangedHandler = async (e) => {
    // setiswaiting(true);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setimage(e.target.files[0]);
    // let formData = new FormData(); //formdata object
    // formData.append("directory", "images/");
    // formData.append("image", e.target.files[0]);
    // const upload_image_resp = await upload_image(formData);
    // console.log(upload_image_resp);
    // setimage(upload_image_resp.path);
    // if(upload_image_resp.code == 200){
    //   setiswaiting(false);
    // }
  };

  const fetchTeamDetail = async () => {
    const formData = new FormData();
      
    formData.append('user_id', id);
    const result = await teamDetail(formData);
    if (result.code === 200) {
      setIswaiting(false);
      setFirstName(result.Team_Data.first_name);
      setLastName(result.Team_Data.last_name);
      setEmail(result.Team_Data.email);
      if (result.Team_Data.status) {
        setStatus(true);
      } else {
        setStatus(false);
      }
      setRole(result.Team_Data.role);
      setimage(result.Team_Data.image);
      setDepartment(result.Team_Data.department_id);
      if (result.Team_Data.image) {
        setProfileImage(baseUri + result.Team_Data.image);
      } else {
        setProfileImage(
          'https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg'
        );
      }
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      setIswaiting(false);
    }
  };

  const fetchDepartment = async () => {
    const formData = new FormData();
      
    const result = await departmentList(formData);
    if (result.code === 200) {
      setDepartmentData(result.department_list);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchTeamDetail();
    fetchDepartment();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} color='primary' />;
  }

  return (
    <>
      <Container maxWidth='sm'>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h4' sx={{ mb: 5 }} style={{ textAlign: 'center' }}>
          Edit Team
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin='dense'
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                id='name'
                label='First Name'
                type='name'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                id='name'
                label='Last Name'
                type='name'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin='dense'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id='email'
                label='Email'
                type='email'
                fullWidth
                variant='outlined'
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                margin="dense"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
              />
            </Grid> */}
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id='demo-simple-select-label'>Department</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={department}
                  label='Department'
                  onChange={(e, value) => setDepartment(value.props.value)}
                >
                  {departmentData.map((x, i) => (
                    <MenuItem key={i} value={x.id}>
                      {x.department_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin='dense'
                required
                value={role}
                onChange={(e) => setRole(e.target.value)}
                id='role'
                label='Role'
                type='text'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              {file ? (
                <>
                  {' '}
                  <img className={classes.img} src={file} alt='team member' />{' '}
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <input
                accept='image/png,image/jpeg,image/jpg'
                hidden
                id='contained-button-file'
                multiple
                type='file'
                onChange={fileChangedHandler}
              />
              <label htmlFor='contained-button-file' style={{ display: 'flex' }}>
                <Button
                  component='span'
                  fullWidth
                  variant='outlined'
                  color='primary'
                  startIcon={<DriveFolderUploadIcon />}
                >
                  Upload Image
                </Button>
              </label>
            </Grid>

            <Grid item xs={6}>
              Status :
              <Switch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant='contained' type='submit'>
                Edit Team
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default EditTeam;
