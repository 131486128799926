import  dc_token  from "src/utils/tokenDecryption";
import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import {
  ProjectListing,
  AddProject,
  EditProject,
  TaskListing,
  ForgetPassword,
  UserProfile,
  Dashboard,
  CalenderTasks,
} from "./pages/index";
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import AllTasks from "./pages/Tasks/AllTasks";
import TaskLinkController from "./pages/Controller/TaskLinkController";
import VerifiedTasks from "./pages/Tasks/VerifiedTasks";
import AllCompletedTasks from "./pages/Tasks/AllCompletedTasks";
import AllTargetDatePassedTasks from "./pages/Tasks/AllTargetDatePassedTasks";
import AllSalesListing from "./pages/Sales/AllSalesListing";
import AddSale from "./pages/Sales/components/AddSale";
import CollapsableTaskListing from "./pages/Tasks/CollapsableTaskListing";
import ProjectCategoryList from "./pages/ProjectCategoryList/ProjectCategoryList";
import { TeamChat } from "./pages/teamChat";
import SaleSectionsListing from "./pages/SaleSectionsListing";
import AllNotes from "./pages/Notes/AllNotes";
import AddNotes from "./pages/Notes/AddNotes";

// ----------------------------------------------------------------------
const Authentication = () => {
  if (dc_token()) {
    return <Navigate to="/dashboard"> </Navigate>;
  }
  if (!dc_token()) {
    return <Navigate to="/login"> </Navigate>;
  }
};
const ChildrenLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/projects") {
      navigate("/projects/categories", { replace: true });
    }
  });
  return <Outlet />;
};
export default function Router() {
  return (
    <Routes>
      {/* <Route path="/task" element={<HomeLayout />}>
        <Route path="/task" element={<TaskListing />} />
      </Route> */}
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/calender" element={<CalenderTasks />} />
        <Route path="projects" element={<ChildrenLayout />}>
          <Route path="categories" element={<ProjectCategoryList />} />
          <Route path="categories/:id" element={<ProjectListing />} />
          <Route path="project/:id" element={<CollapsableTaskListing />} />
          <Route path="add-project" element={<AddProject />} />
          <Route path="edit-project/:id" element={<EditProject />} />
          <Route path="verified-tasks" element={<VerifiedTasks />} />
        </Route>
        {/* route for testing purpose */}
        <Route path="/test-collapse/:id" element={<TaskListing />} />
        <Route path="/user_profile" element={<UserProfile />} />
        <Route path="/all-tasks" element={<AllTasks />} />
        <Route path="/all-completed-tasks" element={<AllCompletedTasks />} />
        <Route
          path="/all-target-date-passed-tasks"
          element={<AllTargetDatePassedTasks />}
        />
        <Route
          path="/project/:project_id/:task_id"
          element={<TaskLinkController />}
        />
        {/* sales */}
        <Route path="/all-sales-listing/all" element={<AllSalesListing />} />
        <Route
          path="/all-sales-listing/sections"
          element={<SaleSectionsListing />}
        />
        <Route path="/add-sale" element={<AddSale />} />
        {/* chat */}
        <Route path="/chat/chat-messages" element={<TeamChat />} />
           {/* Notes Add */}
           <Route path="/Notes/AllNotes" element={<AllNotes />} />
        <Route path="/Notes/AddNotes" element={<AddNotes />} />

        {/*  */}
        {/* <Route path="/team" element={<Team />} />
        <Route path="/team/add_team" element={<AddTeam />} />
        <Route path="/team/edit_team/:id" element={<EditTeam />} />
        <Route path="/department" element={<Departments />} />
        <Route path="/department/add_department" element={<AddDepartment />} />
        <Route path="/department/edit_department/:id" element={<EditDepartment />} /> */}
        {/* <Route path="/user" element={<User />} />
        <Route path="/products" element={<Products />} />
        <Route path="/blog" element={<Blog />} />  */}
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      {/* <PublicRouteWithLayout component={Login} exact layout={LogoOnlyLayout} path="/login" />
      <PublicRouteWithLayout component={Register} exact layout={LogoOnlyLayout} path="/register" /> */}
      {/* --------------------------------------- Dashboard --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={DashboardApp}
        exact
        layout={DashboardLayout}
        path="/dashboard/app"
      /> */}
      {/* --------------------------------------- Change Password --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={Products}
        exact
        layout={DashboardLayout}
        path="/dashboard/products"
      /> */}
    </Routes>
  );
}
//   return useRoutes([
//     {
//       path: '/dashboard',
//       element: <DashboardLayout />,
//       children: [
//         { element: <Navigate to="/dashboard/app" replace /> },
//         { path: 'app', element: <DashboardApp /> },
//         { path: 'user', element: <User /> },
//         { path: 'products', element: <Products /> },
//         { path: 'blog', element: <Blog /> }
//       ]
//     },
//     {
//       path: '/',
//       element: <LogoOnlyLayout />,
//       children: [
//         { path: 'login', element: <Login /> },
//         { path: 'register', element: <Register /> },
//         { path: '404', element: <NotFound /> },
//         { path: '/', element: <Navigate to="/dashboard" /> },
//         { path: '*', element: <Navigate to="/404" /> }
//       ]
//     },
//     { path: '*', element: <Navigate to="/404" replace /> }
//   ]);
// }
