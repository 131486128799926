import React, { useState, useRef } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography, Tooltip, Button } from '@mui/material';
import chevronUpFill from '@iconify/icons-eva/chevron-up-fill';
import chevronDownFill from '@iconify/icons-eva/chevron-down-fill';
// components
import MenuPopover from '../../../components/MenuPopover';
import { Icon } from '@iconify/react';
function FilterByProject(props) {
  const anchorRef = useRef(null);
  const [openMemberSelect, setOpenMemberSelect] = useState(false);
  const handleClose = () => {
    setOpenMemberSelect(false);
  };

  const handleOpenMemberSelect = () => {
    setOpenMemberSelect(true);
  };

  const handleAdd = (value) => {
    props.onSelectProject(value, props.onId);
  };
  const handleDelete = (value) => {
    props.onUnselectProject(value, props.onId);
  };
  const truncateText = (string, maxLength) => {
    if (maxLength == string.length) {
      return string;
    }
    if (string.length > maxLength) {
      let result = string.slice(0, maxLength).concat(' ' + '...');

      return result;
    }
    return string;
  };
  const getSelectedNames = () => {
    let local_selected_projects = localStorage.getItem('filteredProjects');
    if (JSON.parse(local_selected_projects)) {
      if (JSON.parse(local_selected_projects).length > 0) {
        let selected = JSON.parse(local_selected_projects).filter((project) => project.isSelected);
        if (selected.length > 0) {
          let text = '';
          selected.map((project) => {
            text = `${text} ${project.label}`;
          });
          return truncateText(text, 14);
        }
        return '';
      } else {
      }
      let selected = props.projectList.filter((project) => project.isSelected);
      if (selected.length > 0) {
        let text = '';
        selected.map((project) => {
          text = `${text} ${project.label}`;
        });
        return truncateText(text, 14);
      }
      return '';
    }
  };
  const getSelectedProject = (projectList) => {
    let local_selected_projects = localStorage.getItem('filteredProjects');
    if (JSON.parse(local_selected_projects)) {
      if (JSON.parse(local_selected_projects).length > 0) {
        let projects = JSON.parse(local_selected_projects);
        return projects;
      }
    }
    //
    return projectList;
  };
  const getTooltipText = () => {
    let local_selected_projects = localStorage.getItem('filteredProjects');
    if (JSON.parse(local_selected_projects)) {
      if (JSON.parse(local_selected_projects).length > 0) {
        let selected = JSON.parse(local_selected_projects).filter((project) => project.isSelected);
        if (selected.length > 0) {
          let selected = JSON.parse(local_selected_projects).filter(
            (project) => project.isSelected
          );
          if (selected.length > 0) {
            let text = '';
            selected.map((product, index) => {
              let show_comma = selected.length === 1 ? false : true;
              let is_last = index === selected.length - 1 ? true : false;
              let comma_visible = false;
              if (!is_last && show_comma) {
                comma_visible = true;
              }
              text = `${text} ${product.label} ${comma_visible ? ',' : ''}`;
            });
            return text;
          }
          return 'No project selected';
        }
      } else {
      }
      let selected = props.projectList.filter((project) => project.isSelected);
      if (selected.length > 0) {
        let text = '';
        selected.map((product, index) => {
          let show_comma = selected.length === 1 ? false : true;
          let is_last = index === selected.length - 1 ? true : false;
          let comma_visible = false;
          if (!is_last && show_comma) {
            comma_visible = true;
          }
          text = `${text} ${product.label} ${comma_visible ? ',' : ''}`;
        });
        return text;
      }
      return 'No project selected';
    }
    return 'No project selected';
  };
  return (
    <>
      <Tooltip arrow title={getTooltipText()}>
        <Button
          ref={anchorRef}
          color='inherit'
          disableRipple
          onClick={() => handleOpenMemberSelect()}
          sx={{ backgroundColor: '#f6f7f8' }}
          endIcon={
            <Icon color='#00ab55' icon={openMemberSelect ? chevronUpFill : chevronDownFill} />
          }
        >
          <Typography component='span' sx={{ color: '#00ab55', fontWeight: '600' }}>
            Project&nbsp; : {getSelectedNames()}
          </Typography>
        </Button>
      </Tooltip>
      <MenuPopover open={openMemberSelect} onClose={handleClose} anchorEl={anchorRef.current}>
        <List>
          {getSelectedProject(props.projectList).length > 0
            ? getSelectedProject(props.projectList).map((project, index) => (
                <React.Fragment key={index}>
                  {project.isSelected === false ? (
                    <ListItem
                      onClick={() => handleAdd(project)}
                      style={{ cursor: 'pointer' }}
                      className='list-hov'
                    >
                      <ListItemText>{project.label}</ListItemText>
                    </ListItem>
                  ) : (
                    <ListItem
                      style={{
                        borderLeft: '2px solid #00AB55',
                        borderRadius: 3,
                        backgroundColor: 'rgba(0, 171, 85, 0.08)',
                        margin: 2,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDelete(project)}
                    >
                      <ListItemText>{project.label}</ListItemText>
                    </ListItem>
                  )}
                </React.Fragment>
              ))
            : 'No projects yet'}
        </List>
      </MenuPopover>
    </>
  );
}

export default FilterByProject;
