import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {useParams} from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
import closeFill from '@iconify/icons-eva/close-fill';
import SettingsIcon from '@mui/icons-material/Settings';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Badge,
  Chip,
  Switch,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
//
import Scrollbar from '../../Scrollbar';
import ColorManyPicker from '../../ColorManyPicker';
import CollapsableTaskListing from 'src/pages/Tasks/CollapsableTaskListing';
import Tooltip from 'src/theme/overrides/Tooltip';

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' },
];


export const STATUS_OPTIONS = [
  { label: 'Pending', value: 'not_started', color: 'warning', filterValue: '0' },
  { label: 'In Progress', value: 'in_progress', color: 'secondary', filterValue: '1' },
  // { label: 'In Review', value: 'in_review', color: 'warning', filterValue: '2' },
  { label: 'Completed', value: 'completed', color: 'primary', filterValue: '3' },
  { label: 'Cancelled', value: 'cancelled', color: 'error', filterValue: '4' },
];
export const SETTING_OPTIONS = [
  { label: 'ONLY MY TO DO', value: 'toDo', filterValue: '0' },
  { label: 'Expand', value: 'expand', filterValue: '1' },
];
export const FILTER_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];
export const FILTER_PRICE_OPTIONS = [
  { value: 'below', label: 'Below $25' },
  { value: 'between', label: 'Between $25 - $75' },
  { value: 'above', label: 'Above $75' },
];
export const FILTER_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

// ----------------------------------------------------------------------

ProjectSetting.propTypes = {
  isOpenFilterSetting: PropTypes.bool,
  onResetFilterSetting: PropTypes.func,
  onOpenFilterSetting: PropTypes.func,
  onCloseFilterSetting: PropTypes.func,
  formik: PropTypes.object,
};

export default function ProjectSetting({
  Expand,
  ProjectStats,
  MyTodo,
  isOpenFilterSetting,
  isFilterSettingData,
  onOpenFilterSetting,
  onCloseFilterSetting,
  onFilterSettingData,
  onChangeFilterSettingData,
  // formik
}) {
  const {id} = useParams();
  const [isMyTodoOn, setIsMyTodoOn] = useState(false);
  
  return (
    <>
      <div style={{ display: 'flex' }}>
        <Button
          disableRipple
          color='inherit'
          endIcon={<SettingsIcon/>}
          onClick={onOpenFilterSetting}
          sx={{ backgroundColor: '#f6f7f8', fontWeight: '600' }}
        >
          Setting & Stats&nbsp;
        </Button>
      </div>

      <Drawer
        anchor='right'
        open={isOpenFilterSetting}
        onClose={onCloseFilterSetting}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant='subtitle1' sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilterSetting}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant='subtitle1' gutterBottom>
                Setting
              </Typography>
                <FormGroup>
                    {MyTodo}
                    {Expand}
                </FormGroup>
            </div>

            <div>
              <Typography variant='subtitle1' gutterBottom>
                Stats
              </Typography>
              <FormGroup>
                {ProjectStats}  
              </FormGroup>
              </div>
          </Stack>
        </Scrollbar>

      </Drawer>
      {/* </Form> */}
      {/* </FormikProvider> */}
    </>
  );
}
