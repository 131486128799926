import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import SideMenuPopover from './SideMenuPopover';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useSocket } from 'src/Hooks/socketContext';
import { logmodeis } from "../../../config/config";

export default function PopoverSectionMenu({
  isOpen,
  onOpen,
  onClose,
  anchorEl,
  handleOpenDialog,
  sections,
  handleMoveSection,
  selectedRow,
  isPersonal,
  isDragDisabled,
  showMoveTo,
  data,
}) {
  const moveToRef = React.useRef();
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
  const { getUserProfileData } = useSocket();
  const user = getUserProfileData();
  const handleClose = () => {
    onClose();
  };
  const handleOpenSubMenu = (e, task, index) => {
    let shouldOpen = isAllowed();
    if (!shouldOpen) return;

    if (process.env.NODE_ENV == logmodeis){console.log(task, 'target-task');
    console.log(index, 'target-index');}
    setSubMenuAnchorEl(e.currentTarget);
    setIsSubMenuOpen(true);
    // setRowData(task);
  };
  const isAllowed = () => {
    if (isPersonal) {
      return true;
      // let result = isDragButtonDisabled();
      // if (result === true) {
      //   return !result;
      // }
      // return true;
    }
    let allowed = false;
    let result = isDragButtonDisabled();
    if (result === true) {
      return !result;
    }
    let status = user?.allow_reorder_task;
    if (String(status) === '1') {
      allowed = true;
    }
    return allowed;
  };
  const canDelete = () => {
    if (isPersonal) return true;
    let allowed = false;
    if (selectedRow.isAssigned || selectedRow.isCreator) {
      allowed = true;
    }
    return allowed;
  };
  const isDragButtonDisabled = () => {
    let result = isDragDisabled();
    return result;
  };
  const handleCloseSubMenu = () => {
    setIsSubMenuOpen(false);
    setSubMenuAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItem sx={{ padding: '.2rem' }} disabled={!canDelete()}>
            <ListItemButton
              onClick={() => {
                handleOpenDialog();
              }}
              disabled={!canDelete()}
            >
              <ListItemText>
                <Typography sx={{ fontSize: '0.8rem' }}>Delete</Typography>
              </ListItemText>
              <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                <DeleteForeverOutlinedIcon />
                {/* <DeleteIcon /> */}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          {showMoveTo && (
            <>
              {String(selectedRow.taskType) !== '2' && (
                <ListItem disabled={!isAllowed()} ref={moveToRef} sx={{ padding: '.2rem' }}>
                  <ListItemButton disabled={!isAllowed()} onClick={handleOpenSubMenu}>
                    <ListItemText>
                      <Typography sx={{ fontSize: '0.8rem' }}>Move to</Typography>
                    </ListItemText>
                    <ListItemIcon sx={{ margin: 0, marginLeft: 1 }}>
                      <ChangeCircleIcon />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              )}
            </>
          )}
        </List>
      </Popover>
      <SideMenuPopover
        isOpen={isSubMenuOpen}
        onOpen={handleOpenSubMenu}
        onClose={handleCloseSubMenu}
        anchorEl={subMenuAnchorEl}
        sections={sections}
        handleMoveSection={handleMoveSection}
        closeParentPopover={handleClose}
        selectedRow={selectedRow}
        data={data}
        // handleOpenDialog={}
      />
    </div>
  );
}
