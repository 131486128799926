import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import editFill from "@iconify/icons-eva/edit-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import { logmodeis } from "../../../config/config";
// material
import {
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
//
import Scrollbar from "../../Scrollbar";
// import Messages from './Messages/Messages';
import {
  uploadTaskFiles,
  removeTaskFiles,
  detailTaskFiles,
} from "../../../DAL/Project/Project";
import { baseUri } from "../../../config/config";
import { LoadingButton } from "@mui/lab";
import CkEditor from "src/components/editor/CKEditor";
import { set_blank_target } from "src/utils/DomUtils";
import {
  FILE_ACCEPT_TYPE_STRING,
} from "src/constants";

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: "featured", label: "Featured" },
  { value: "newest", label: "Newest" },
  { value: "priceDesc", label: "Price: High-Low" },
  { value: "priceAsc", label: "Price: Low-High" },
];
export const PRIORITY_OPTIONS = [
  { label: "Critical", value: "critical", color: "error", filterValue: "0" },
  { label: "High", value: "high", color: "primary", filterValue: "1" },
  { label: "Medium", value: "medium", color: "success", filterValue: "2" },
  { label: "Low", value: "low", color: "info", filterValue: "3" },
];

const getChipColor = (value) => {
  const chip = PRIORITY_OPTIONS.find((chip) => chip.value === value);
  if (!chip) {
    const chip2 = STATUS_OPTIONS.find((chip) => chip.value === value);
    return chip2.color;
  }
  return chip.color;
};

const getLabel = (value) => {
  const chip = PRIORITY_OPTIONS.find((chip) => chip.value === value);
  if (!chip) {
    const chip2 = STATUS_OPTIONS.find((chip) => chip.value === value);
    return chip2.label;
  }
  return chip.label;
};

export const STATUS_OPTIONS = [
  {
    label: "Not Started",
    value: "not_started",
    color: "success",
    filterValue: "0",
  },
  {
    label: "In Progress",
    value: "in_progress",
    color: "secondary",
    filterValue: "1",
  },
  {
    label: "In Review",
    value: "in_review",
    color: "warning",
    filterValue: "2",
  },
  {
    label: "Completed",
    value: "completed",
    color: "primary",
    filterValue: "3",
  },
  { label: "Cancelled", value: "cancelled", color: "error", filterValue: "4" },
];
export const FILTER_RATING_OPTIONS = [
  "up4Star",
  "up3Star",
  "up2Star",
  "up1Star",
];
export const FILTER_PRICE_OPTIONS = [
  { value: "below", label: "Below $25" },
  { value: "between", label: "Between $25 - $75" },
  { value: "above", label: "Above $75" },
];
export const FILTER_COLOR_OPTIONS = [
  "#00AB55",
  "#000000",
  "#FFFFFF",
  "#FFC0CB",
  "#FF4842",
  "#1890FF",
  "#94D82D",
  "#FFC107",
];

// ----------------------------------------------------------------------

EditTaskSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

const useStyles = makeStyles(({ theme }) => ({
  textArea: {
    "&.Mui-focused": { outline: "green" },
  },
  edit: {
    marginLeft: ".2rem",
    "& :hover": {
      cursor: "pointer",
    },
  },
  editCommentIcon: {
    fontSize: "1rem",
  },
  formMargin: {
    marginTop: 10,
  },
  cancelBtn: {
    width: "100%",
    marginBlock: ".15rem",
    alignSelf: "center",
    marginRight: ".1rem",
    marginLeft: ".5rem",
    "&:hover": {
      cursor: "pointer",
      color: "green",
    },
  },
  noShadow: {
    boxShadow: "none",
  },
}));

export default function EditTaskSidebar({
  isOpenEditTask,
  onResetEditTask,
  onOpenEditTask,
  onCloseEditTask,
  isTaskData,
  openUpdate,
  openEdit,
  SubmitTask,
  targetTask,
  setTargetTask,
  isUpdatingTask,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setProfileImage] = React.useState("");
  const [taskImages, setTaskImages] = React.useState();
  const [imagesDetail, setImagesDetail] = React.useState([]);
  const [updateName, setUpdateName] = React.useState("");
  const [status, setStatus] = React.useState("1");
  const [uploadingFile, setUploadingFile] = useState(false);

  const [updateDescription, setUpdateDescription] = React.useState("");
  const addCommentRef = React.useRef();

  const [editComment, setEditComment] = useState();

  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [deleteImg, setDeleteImg] = useState();
  const [showEditor, setShowEditor] = useState(false);
  const [taskUploadFile, setTaskUploadFile] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (process.env.NODE_ENV == logmodeis) {
      console.log(isTaskData, "taskData");
    }
    SubmitTask(
      updateName,
      updateDescription,
      isTaskData.task_id,
      onCloseEditTask
    );
  };

  const fileChangedHandler = async (e) => {
    // setiswaiting(true);
    // setiswaiting(true);
    if (!e.target) {
      return;
    }
    if (!e.target.files) {
      return;
    }
    if (!e.target.files[0]) {
      return;
    }
    // FILE_ACCEPT_TYPE_STRING
    // ALLOWED_TASK_FILES_WITH_ICONS
    /* image/png,image/jpeg,image/jpg */
    if (process.env.NODE_ENV == logmodeis) {
      console.log(e.target.files[0], "files-");
    }
    let can_upload = false;
    let file_type = "0";
    const { type } = e.target.files[0];
    const is_video_type = type.startsWith("video/");
    const is_audio_type = type.startsWith("audio/");
    let allowed_types = FILE_ACCEPT_TYPE_STRING.split(",");
    // [
    //   "image/png",
    //   "image/jpeg",
    //   "image/jpg",
    //   "application/pdf",
    //   "text/pdf",
    // ];
    allowed_types.map((item) => {
      if (item === String(type)) {
        can_upload = true;
      }
    });
    if (is_video_type === true || is_audio_type === true) {
      can_upload = true;
    }
    if (String(type) === "text/pdf" || String(type) === "application/pdf") {
      file_type = "1";
    }
    if (
      String(type).startsWith("application/") ||
      is_video_type === true ||
      is_audio_type === true
    ) {
      file_type = "1";
    }
    if (!can_upload) {
      enqueueSnackbar("Please chose an image or pdf file", {
        variant: "error",
      });
      return;
    }

    setUploadingFile(true);

    const formData = new FormData(); // formdata object

    formData.append("file_type", file_type);
    formData.append("task_id", isTaskData.task_id);
    formData.append("task_images", e.target.files[0]);
    const resp = await uploadTaskFiles(formData);
    setUploadingFile(false);

    if (resp.code === 200) {
      getImagesDetail();
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const removeImage = async (data, index) => {
    setIsImageDialogOpen(true);
    setDeleteImg({ data: data, index: index });
  };
  const handleRemoveImage = async () => {
    const formData = new FormData(); // formdata object

    // formData.append('file_type', '0');
    formData.append("task_id", deleteImg.data.task_id);
    formData.append("image_id", deleteImg.data.id);
    const resp = await removeTaskFiles(formData);
    // setimage(upload_image_resp.path);
    if (resp.code === 200) {
      setIsImageDialogOpen(false);
      getImagesDetail();
      enqueueSnackbar(resp.message, { variant: "success" });
    } else {
      setIsImageDialogOpen(false);
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const getImagesDetail = async () => {
    const formData = new FormData(); // formdata object

    formData.append("task_id", isTaskData.task_id);
    const result = await detailTaskFiles(isTaskData.task_id);
    if (result.code === 200) {
      setImagesDetail(result.task_files);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const applyBlankTargetOnAnchorTag = (id) => {
    set_blank_target(id);
  };
  const getTaskDetail = async () => {
    const formData = new FormData(); // formdata object

    formData.append("task_id", isTaskData.task_id);
    const result = await detailTaskFiles(isTaskData.task_id);
    if (result.code === 200) {
      setUpdateName(result.task_text_and_detail.task_name);
      setUpdateDescription(result.task_text_and_detail.task_distribution);
      setTimeout(() => {
        //to open link in new tab
        applyBlankTargetOnAnchorTag("task-description-team-portal");
      }, 200);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getHtml = (html) => {
    if (!html) return;
    return { __html: html };
  };
  const handleShowEditor = (value) => {
    setShowEditor(value);
  };
  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === "Apple Computer, Inc.") {
      is_safari = true;
    }
    return is_safari;
  };
  const getActualTime = (date) => {
    if (!date) {
      return '';
    }
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };
  useEffect(() => {
    setTaskImages(isTaskData);
  }, [isOpenEditTask]);

  useEffect(async () => {
    if (isOpenEditTask) {
      getImagesDetail();
      getTaskDetail();

      handleShowEditor(false);
    }
  }, [isOpenEditTask]);

  return (
    <>
      <Drawer
        disableEnforceFocus={true}
        anchor="right"
        open={isOpenEditTask}
        onClose={onCloseEditTask}
        PaperProps={{
          sx: {
            width: { xs: 340, md: 380 },
            border: "none",
            overflow: "hidden",
          },
          // sx: { width: 380, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1 }}
        >
          {/* <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: 'bold' }}>
            Task Detail
          </Typography> */}
          <IconButton onClick={onCloseEditTask}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
          {uploadingFile && (
            <div className="uploading-file">
              <Typography sx={{ m: 0 }} variant="body1">
                Uploading file...
              </Typography>

              <CircularProgress size="1.3rem" />
            </div>
          )}
        </Stack>
        <Divider />
        <Scrollbar>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2} sx={{ p: 2 }}>
              <div>
                <div>
                  <Typography style={{ fontsize: 16, fontWeight: "bold" }}>
                    Task created on :
                  </Typography>
                  <Typography className="mb-2">
                    {getActualTime(isTaskData?.createdDate)}
                  </Typography>
                </div>
                <textarea
                  style={{
                    border: "hidden",
                    backgroundColor: "rgb(0 42 21 / 8%) ",
                    fontSize: 12,
                    minHeight: "15vh",
                  }}
                  disabled={
                    localStorage.getItem("user_id") ==
                      isTaskData.task_creator_id ||
                    isTaskData.isAssigned === true
                      ? false
                      : true
                  }
                  required
                  // rows="3"

                  className="form-control text-area-task"
                  value={updateName}
                  onChange={(e) => setUpdateName(e.target.value)}
                  id="exampleFormControlInput1"
                />
              </div>
              <div className="task-description-editor-parent">
                {/* <div className='row justify-content-end align-items-center'>
                {isTaskData.isAssigned === true && (
                  <div className='col d-flex flex-col justify-content-end my-1'>
                    {updateDescription === '' && (
                      <Typography className='mr-auto'>
                        {!showEditor && 'Click the Edit icon to add description'}
                      </Typography>
                    )}
                    {showEditor === false ? (
                      <Tooltip title='show editor' placement='top'>
                        <span role='button'>
                          <Icon onClick={() => handleShowEditor(true)} icon={editFill} />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title='close editor' placement='top'>
                        <span role='button'>
                          <CloseIcon onClick={() => handleShowEditor(false)} />
                        </span>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div> */}
                {isTaskData.isAssigned === false ? (
                  <div
                    id="task-description-team-portal"
                    dangerouslySetInnerHTML={getHtml(updateDescription)}
                  ></div>
                ) : (
                  <CkEditor
                    content={updateDescription}
                    setContent={(value) => setUpdateDescription(value)}
                  />
                )}
              </div>

              <div>
                <div className="row">
                  {imagesDetail &&
                    imagesDetail.map((x, index) => (
                      <div key={index} className="col-3 mt-2">
                        {isTaskData.isCreator === true ||
                        isTaskData.isAssigned === true ? (
                          <CancelIcon
                            style={{
                              color: "red",
                              position: "absolute",
                              marginLeft: 40,
                              bottom: 45,
                            }}
                            tabIndex="0"
                            role="button"
                            onClick={() => removeImage(x, index)}
                            onKeyDown={() => removeImage(x, index)}
                          />
                        ) : null}
                        {/* <div
                        style={{
                          height: 15,
                          width: 15,
                          borderRadius: 50,
                          backgroundColor: 'red',
                          color: '#fff',
                          position: 'absolute',
                          fontSize: 8,
                          textAlign: 'center',
                          marginBottom: 15,
                          marginLeft: 25,
                          bottom: 25,
                          cursor: 'pointer'
                        }}
                        tabIndex="0"
                        role="button"
                        onClick={() => removeImage(x, i)}
                        onKeyDown={() => removeImage(x, i)}
                      >
                        X
                      </div> */}
                        <a
                          href={baseUri + x.task_image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {String(x.file_type) === "0" ? (
                            <img
                              src={baseUri + x.task_image}
                              alt="task images"
                              style={{
                                width: 50,
                                height: 50,
                                borderRadius: 5,
                                cursor: "pointer",
                                backgroundSize: "cover",
                              }}
                            />
                          ) : (
                            <div>
                              <Tooltip
                                arrow
                                title={x.file_name ? x.file_name : ""}
                              >
                                <Icon
                                  width="50px"
                                  height="50px"
                                  icon="akar-icons:file"
                                  // icon="bxs:file-pdf"
                                />

                                {/* <Typography>{truncateText(x.task_image, 15)}</Typography> */}
                              </Tooltip>
                            </div>
                          )}
                        </a>
                      </div>
                    ))}
                  <div className="col-3 mt-2">
                    <input
                      disabled={
                        localStorage.getItem("user_id") ==
                          isTaskData.task_creator_id ||
                        isTaskData.isAssigned === true
                          ? false
                          : true
                      }
                      accept={FILE_ACCEPT_TYPE_STRING}
                      // accept="image/png,image/jpeg,image/jpg,application/pdf,text/pdf"
                      hidden
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={fileChangedHandler}
                    />
                    <label
                      htmlFor="contained-button-file"
                      style={{ display: "flex" }}
                    >
                      <Tooltip title="Attach Image">
                        <IconButton
                          disabled={
                            localStorage.getItem("user_id") ==
                              isTaskData.task_creator_id ||
                            isTaskData.isAssigned === true
                              ? false
                              : true
                          }
                          color="inherit"
                          aria-label="upload picture"
                          component="span"
                          fullWidth
                          variant="outlined"
                          style={{
                            border: "1px solid #c5c5c5",
                            borderRadius: 5,
                            width: 45,
                            height: 50,
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </label>
                  </div>
                </div>
                <LoadingButton
                  fullWidth
                  size="medium"
                  type="submit"
                  color="primary"
                  variant="outlined"
                  startIcon={<Icon icon={editFill} />}
                  className="mt-2"
                  loading={isUpdatingTask}
                  disabled={
                    localStorage.getItem("user_id") ==
                      isTaskData.task_creator_id ||
                    isTaskData.isAssigned === true
                      ? false
                      : true
                  }
                >
                  Update
                </LoadingButton>
              </div>
            </Stack>
          </form>
        </Scrollbar>
        {/* removed comments section from this line */}
        {/* confirmation dialog */}
        <Dialog
          open={isImageDialogOpen}
          onClose={() => setIsImageDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete file</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this file?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleRemoveImage()}>Yes</Button>
            <Button
              onClick={() => setIsImageDialogOpen(false)}
              //    autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Drawer>
    </>
  );
}
