import React, { useState, useEffect, useRef, useMemo } from "react";
import { noTask } from "src/assets";
import {
  Tooltip,
  Typography,
  IconButton,
  Box,
  Avatar,
  Checkbox,
  Skeleton,
  TablePagination,
} from "@mui/material";
import { baseUri } from "src/config/config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { set_blank_target } from "src/utils/DomUtils";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import DatePicker from "react-datepicker";
import moment from "moment";
import OtherAssociatesPicker from "src/pages/Sales/components/OtherAssociatesPicker";

const SaleCardUnOptimized = ({
  sale,
  onSaleAction = () => null,
  associates = [],
  saleSectionDetail,
  fetchSalesBySection,
  reRenderApi,
  setReRenderApi,
}) => {
  const itemRef = useRef();
  const [show, setShow] = useState(false);

  const handleOpenMenu = async (e, sale) => {
    const action = {
      action_title: "open_sale_card_menu",
      payload: {
        event: e,
        sale_item: sale,
      },
    };
    await onSaleAction(action);
  };
  const onStatusChange = async (e, sale) => {
    const action = {
      action_title: "sale_status_change",
      payload: {
        event: e,
        sale_item: sale,
        saleSectionDetail:saleSectionDetail,
        fetchSalesBySection: fetchSalesBySection,

      },
    };
    onSaleAction(action);
  };
  const showAvatar = (data) => {
    if (data.associate_id === null) {
      return false;
    }
    if (String(data.associate_id) === "0") {
      return false;
    }
    if (data.associate_id !== null && data.team_first_name === null) {
      return false;
    }
    if (String(data.associate_id) !== "0" && data.team_first_name === null) {
      return false;
    }
    return true;
  };
  const showAmount = (amount) => {
    if (!amount) {
      return false;
    }
    for (let i = 0; i < amount.length; i++) {
      amount = amount.replace(",", "");
    }
    let show = false;
    if (Number(amount) > 0) {
      show = true;
    }
    return show;
  };
  const getAnsList = (list) => {
    let answers = [];
    let fb_id = list.facebook_id;
    let fb_link = `<a target='_blank' href='https://www.facebook.com/${fb_id}'> https://www.facebook.com/${fb_id} </a>`;
    const getDayOneHtml = (html) => {
      if (!fb_id) {
        return `${"<h2>Facebook Profile</h2>" + "<br/>" + html}`;
      }
      if (fb_id === null) {
        return `${"<h2>Facebook Profile</h2>" + "<br/>" + html}`;
      }
      if (fb_id === "") {
        return `${"<h2>Facebook Profile</h2>" + "<br/>" + html}`;
      }

      return `${"<h2>Facebook Profile</h2>" + fb_link + "<br/>" + html}`;
    };
    if (String(list.day1) !== "") {
      answers.push({
        day: 1,
        data: getDayOneHtml(list.day1),
        createdAt: list.created_at,
      });
    }
    if (String(list.day2) !== "") {
      answers.push({ day: 2, data: list.day2, createdAt: list.created_at });
    }
    if (String(list.day3) !== "") {
      answers.push({ day: 3, data: list.day3, createdAt: list.created_at });
    }
    if (String(list.day4) !== "") {
      answers.push({ day: 4, data: list.day4, createdAt: list.created_at });
    }
    if (String(list.day5) !== "") {
      answers.push({ day: 5, data: list.day5, createdAt: list.created_at });
    }
    if (String(list.day6) !== "") {
      answers.push({ day: 6, data: list.day6, createdAt: list.created_at });
    }
    return answers;
  };
  const handleOpenTooltip = (e, sale) => {
    setTimeout(() => {
      set_blank_target("sale-html-description");
    }, 200);
  };
  const getTooltipHtml = (sale) => {
    if (sale.answers_detail !== "") {
      return { __html: sale.answers_detail };
    }
    if (sale.sale_description) {
      return { __html: sale.sale_description };
    }
    if (!sale.sale_description) {
      return { __html: "<p>No Description added!</p>" };
    }
    if (sale.sale_description === "") {
      return { __html: "<p>No Description added!</p>" };
    }
    return "";
  };
  const openSidebar = (sale,reRenderApi,setReRenderApi) => {
    console.log("siderbar open")
    const action = {
      action_title: "open_sale_card_sidebar",
      payload: {
        sale_item: sale,
      },
    };
    onSaleAction(action);
  };
  const handleTargetDate = (date) => {
    const action = {
      action_title: "sale_target_date_change",
      payload: {
        sale_item: sale,
        date: date,
      },
    };
    onSaleAction(action);
  };

  const handleSetAssociates = () => {
    const action = {
      action_title: "set_associates",
      payload: {
        sale_item: sale,
      },
    };
    onSaleAction(action)
  };
  const handleChangeOptionalAssociates = (member, action) => {
    const _action = {
      action_title: "change_optional_associates",
      payload: {
        sale_item: sale,
        member: member,
        member_action: action,
      },
    };
    onSaleAction(_action);
  };

  const handleOpenAnswer = (data) => {
    const action = {
      action_title: "open_answer_detail",
      payload: {
        sale_item: sale,
        answer_detail: data,
      },
    };
    onSaleAction(action);
  };
  const observerHandle = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  };
  const interSectionHandler = () => {
    const observer = new IntersectionObserver(observerHandle, { root: null });
    observer.observe(itemRef.current);
  };
  useEffect(() => {
    interSectionHandler();
  }, []);
  return (
    <div ref={itemRef} style={{ minHeight: "8.5rem" }}>
      {show && (
        <>
          <Box
            key={sale.id}
            borderRadius="4px"
            boxShadow={3}
            flexDirection="row"
            alignItems="center"
          >
            <div className="row mt-3 py-3">
              <div className="col-12">
                <div className="row align-items-center relative">
                  <span
                    style={{
                      position: "absolute",
                      right: 20,
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 2,
                    }}
                  >
                    <IconButton onClick={(e) => handleOpenMenu(e, sale)}>
                      <MoreVertIcon />
                    </IconButton>
                  </span>
                  <div className="product-name col-12 mb-1">
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex align-items-center pl-2">
                          <div>
                            <Checkbox
                              // disabled={!sale.is_sale_assigned}
                              value={sale.status}
                              onChange={(e) => onStatusChange(e, sale)}
                              checked={sale.completed_status === true}
                            />
                          </div>
                          <div>
                            {" "}
                            <Tooltip
                              onOpen={(e) => handleOpenTooltip(e)}
                              title={
                                <div id="task-description-tooltip">
                                  <div
                                    id="sale-html-description"
                                    dangerouslySetInnerHTML={getTooltipHtml(
                                      sale
                                    )}
                                  ></div>
                                </div>
                              }
                              arrow
                            >
                              <Typography
                                style={{
                                  display: "inline-block",
                                  whiteSpace: "pre-wrap",
                                }}
                                role="button"
                                onClick={() => openSidebar(sale, reRenderApi, setReRenderApi)}
                                className="pl-2"
                              >
                                {sale.sale_title ? sale.sale_title : "no title"}
                                {/* {`${sale.first_name} ${sale.last_name} - ${
                                    sale.client_email
                                  }${getPhone(sale.phone)}`} */}
                              </Typography>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-info col-12">
                    <div className="row align-items-center">
                      <div className="col-md-3 mb-2 mb-md-0">
                        <div className="pl-2 d-flex align-items-center">
                          <div role="button">
                            <Tooltip arrow title="created at">
                              <a
                                style={{
                                  textDecorationLine: "underline",
                                  fontSize: 12,
                                  padding: 0,
                                  color: "#0056b3",
                                  marginLeft: "11px",
                                }}
                              >
                                {getActualDate(sale.created_at)}
                              </a>
                            </Tooltip>
                          </div>
                          <div
                            style={{
                              marginLeft: "0.5rem",
                              paddingBottom: "2px",
                            }}
                          >
                            {sale.sale_target_date === "" ? (
                              <DatePicker
                                value={sale.sale_target_date}
                                selected={sale.sale_target_date}
                                onSelect={(date) => handleTargetDate(date)}
                                minDate={new Date()}
                                targetTask={sale}
                                customInput={
                                  <ExampleCustomInput targetTask={sale} />
                                }
                              />
                            ) : (
                              <DatePicker
                                value={sale.sale_target_date}
                                selected={new Date(sale.sale_target_date)}
                                onSelect={(date) => handleTargetDate(date)}
                                minDate={new Date()}
                                targetTask={sale}
                                customInput={
                                  <ExampleCustomInput targetTask={sale} />
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1 mb-2 mb-md-0">
                        <div className="d-flex justify-content-center justify-content-md-center align-items-center pl-2 pl-md-0">
                          <div className="mr-2">
                            {showAvatar(sale) && (
                              <Tooltip
                                arrow
                                title={`Main Associate ${
                                  sale.team_first_name +
                                  " " +
                                  sale.team_last_name
                                }`}
                                placement="bottom"
                              >
                                {sale.image !== "" ? (
                                  <Avatar
                                    role="button"
                                    alt="Remy Sharp"
                                    src={baseUri + sale.image}
                                    style={{ height: 30, width: 30 }}
                                    // className='ml-2'
                                  />
                                ) : (
                                  <Avatar
                                    role="button"
                                    alt="Remy Sharp"
                                    style={{ height: 30, width: 30 }}
                                  >
                                    {sale.team_first_name.substring(0, 1)}
                                  </Avatar>
                                )}
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mb-2 mb-md-0">
                        <OtherAssociatesPicker
                          optionalAssociates={sale.optional_associate}
                          row={sale}
                          membersData={associates}
                          setMembersData={handleSetAssociates}
                          onChangeAssociates={(member, action) =>
                            handleChangeOptionalAssociates(member, action)
                          }
                        />
                      </div>
                      <div className="col-md-5 mb-1 mb-md-0">
                        <div className="d-flex justify-content-center align-items-center">
                          <div>
                            <Tooltip arrow title="Product name">
                              <Typography
                                role="button"
                                className="project-name-div mb-1 mb-md-0"
                                component="div"
                                style={{ marginLeft: "0.7rem" }}
                              >
                                {sale.product_name}
                              </Typography>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div className="sale-answers col-12">
                        <div
                          style={{ paddingLeft: "10px" }}
                          className="d-flex justify-content-start justify-content-md-start  align-items-center mt-2"
                        >
                          {getAnsList(sale).map((item, i) => (
                            <span
                              key={i}
                              onClick={() => handleOpenAnswer(item)}
                              role="button"
                              className={`ans-day ${i === 0 && "pl-2"}`}
                            >
                              <Tooltip title="view" arrow placement="top">
                                <Typography>{`#Day ${item.day}`}</Typography>
                              </Tooltip>
                            </span>
                          ))}
                          {String(sale.agreement_pdf) !== "" && (
                            <Typography>
                              <a
                                style={{
                                  listStyle: "none",
                                  textDecoration: "none",
                                }}
                                className="sale-link ml-3"
                                href={sale.agreement_pdf}
                                target="_blank"
                              >
                                #agreement pdf
                              </a>
                            </Typography>
                          )}
                          {String(sale.payment_invoice) !== "" && (
                            <Typography>
                              <a
                                style={{
                                  listStyle: "none",
                                  textDecoration: "none",
                                }}
                                className="sale-link"
                                href={sale.payment_invoice}
                                target="_blank"
                              >
                                #payment invoice
                              </a>
                            </Typography>
                          )}
                          {showAmount(sale.amount) && (
                            <span className="ml-2">
                              <Tooltip title="Amount" arrow placement="top">
                                <Typography className="theme-text-color">{`Amount: ${sale.currency}${sale.amount} `}</Typography>
                              </Tooltip>
                            </span>
                          )}
                        </div>
                        <div className="d-flex justify-content-start justify-content-md-start  align-items-center mt-1">
                          {sale.package && (
                            <span className="ml-2">
                              <Tooltip title="package" arrow placement="top">
                                <Typography className="theme-text-color">{` ${sale.package} `}</Typography>
                              </Tooltip>
                            </span>
                          )}
                          {sale.payment_plan && (
                            <span className="ml-2">
                              <Tooltip
                                title="payment plan"
                                arrow
                                placement="top"
                              >
                                <Typography className="theme-text-color">{` ${sale.payment_plan} `}</Typography>
                              </Tooltip>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
          {/* <TablePagination
          rowsPerPageOptions={[15, 30, 50]}
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </>
      )}
    </div>
  );
};
const SaleCard = ({ sale, onSaleAction = () => null, associates = [],saleSectionDetail,fetchSalesBySection, reRenderApi, setReRenderApi }) => {
  const optimized = useMemo(
    () => (
      <SaleCardUnOptimized
        sale={sale}
        onSaleAction={onSaleAction}
        associates={associates}
        saleSectionDetail={saleSectionDetail}
        fetchSalesBySection={fetchSalesBySection}
        reRenderApi={reRenderApi}
        setReRenderApi={setReRenderApi}
      />
    ),
    [associates, { ...sale }]
  );
  return optimized;
};

export default SaleCard;

const getDateColor = (targetDate) => {
  //2022-01-24 example date
  let actual_target_date = getActual(targetDate);
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(actual_target_date).isBefore(today_moment_date)) {
    return "red";
  }
  return "rgb(0, 171, 85)";
};
const showDangerIcon = (targetDate, status) => {
  //2022-01-24 example date
  let actual_target_date = getActual(targetDate);
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(actual_target_date).isBefore(today_moment_date)) {
    return true;
  }
  return false;
};
const detectSafariBrowser = () => {
  let is_safari = false;
  if (navigator.vendor === "Apple Computer, Inc.") {
    is_safari = true;
  }
  return is_safari;
};
const getActualDate = (date) => {
  if (!date) return "-";
  let date_safari = date;
  // date_safari = date_safari.replaceAll('-', '/');
  for (let i = 0; i < date_safari.length; i++) {
    if (date_safari.charAt(i) === "-") {
      date_safari = date_safari.replace("-", "/");
    }
  }
  const serverDate = new Date(date);
  const difference = serverDate.getTimezoneOffset();
  const serverDate_safari = new Date(date_safari);
  const difference_safari = serverDate_safari.getTimezoneOffset();
  if (detectSafariBrowser()) {
    const setTime = moment(
      moment(date_safari).subtract(difference_safari, "m")
    ).format("DD, MM YYYY");
    return setTime;
  }
  if (!detectSafariBrowser()) {
    const setTime = moment(moment(serverDate).subtract(difference, "m")).format(
      "DD, MM YYYY"
    );

    return setTime;
  }
};
const getActual = (date) => {
  if (!date) return "-";
  let date_safari = date;
  // date_safari = date_safari.replaceAll('-', '/');
  for (let i = 0; i < date_safari.length; i++) {
    if (date_safari.charAt(i) === "-") {
      date_safari = date_safari.replace("-", "/");
    }
  }
  const serverDate = new Date(date);
  const difference = serverDate.getTimezoneOffset();
  const serverDate_safari = new Date(date_safari);
  const difference_safari = serverDate_safari.getTimezoneOffset();
  if (detectSafariBrowser()) {
    const setTime = moment(
      moment(date_safari).subtract(difference_safari, "m")
    ).format("YYYY MM DD");
    return setTime;
  }
  if (!detectSafariBrowser()) {
    const setTime = moment(moment(serverDate).subtract(difference, "m")).format(
      "YYYY MM DD"
    );

    return setTime;
  }
};
const ExampleCustomInput = React.forwardRef(
  ({ value, onClick, targetTask }, ref) => (
    <Tooltip arrow title="Due Date">
      {value !== "" ? (
        <>
          <Tooltip arrow title="due date">
            <a
              role="button"
              tabIndex="0"
              className="btn"
              onClick={onClick}
              onKeyDown={onClick}
              ref={ref}
              style={{
                textDecorationLine: "underline",
                fontSize: 12,
                //  color: 'green',
                color: getDateColor(value),
                padding: 0,
              }}
            >
              {getActualDate(value)}
              {/* {moment(value).format('DD MMM, YYYY')} */}
            </a>
          </Tooltip>
          {showDangerIcon(value) && (
            <>
              <PriorityHighIcon htmlColor="red" style={{ fontSize: "1rem" }} />
            </>
          )}
        </>
      ) : (
        <a
          role="button"
          tabIndex="0"
          className="btn"
          onClick={onClick}
          onKeyDown={onClick}
          ref={ref}
          style={{
            textDecorationLine: "underline",
            fontSize: 12,
            padding: 0,
            //  color: 'green'
            color: getDateColor(value),
          }}
        >
          Due date
        </a>
      )}
    </Tooltip>
  )
);
