import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { logmodeis } from "src/config/config";

function TaskLinkController(props) {
  const params = useParams();
  const navigate = useNavigate();
  if (process.env.NODE_ENV == logmodeis) {
    console.log(params, "params");
    console.log(navigate, "navigate");
  }

  const navigateToProject = () => {
    navigate(`/projects/project/${params.project_id}`, {
      state: { task_id: params.task_id, is_copied_link: true },
    });
  };
  useEffect(() => {
    navigateToProject();
  }, []);
  return (
    <div>
      <Loader />
    </div>
  );
}

export default TaskLinkController;
