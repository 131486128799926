import React, { useState, useRef } from "react";
import { logmodeis } from "../../../config/config";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import { Typography, Tooltip, AvatarGroup } from "@mui/material";
import { baseUri } from "../../../config/config";
// components
import MenuPopover from "../../../components/MenuPopover";

function OtherAssociatesPicker(props) {
  const anchorRef = useRef(null);
  const [openMemberSelect, setOpenMemberSelect] = useState(false);
  // const [taskstatus, setTaskStatus] = useState(LANGS[0]);
  if(process.env.NODE_ENV == logmodeis){console.log(props,"props-----")}
  const handleClose = () => {
    setOpenMemberSelect(false);
  };

  const handleOpenMemberSelect = () => {
    setOpenMemberSelect(true);
  };

  const handleChangeAssociate = async (value) => {
    // setOpenMemberSelect(false);
    const exist = isMemberSelected(value);
    if(process.env.NODE_ENV == logmodeis){console.log(exist, "exist--");}
    if (exist) {
      props.onChangeAssociates(value, "pop");
    } else {
      props.onChangeAssociates(value, "push");
    }
  };
  const isMemberSelected = (member) => {
    if(process.env.NODE_ENV == logmodeis){console.log(props.optionalAssociates,"1st console");}
    let optional_arr = [];
    optional_arr = [...props.optionalAssociates];
    const found =
    optional_arr.find(
        (u_id) => String(u_id) === String(member.user_id)
      ) !== undefined;
    return found;
  };

  const getOptionalAssociates = () => {
    let populated = [];
    let optional_arr = [];
    optional_arr = [...props.optionalAssociates];
      props.membersData.forEach((item) => {
        let found = optional_arr.find(
          (id) => String(id) === String(item.user_id)
        );
        if (!!found) {
          populated.push(item);
        }
      });
      return populated;
  };
  const optional_associates = getOptionalAssociates();
  if(process.env.NODE_ENV == logmodeis){console.log(optional_associates,'optional_associates')}
  return (
    <>
      <Tooltip title="Update Other Associates">
        <div ref={anchorRef} className="d-flex align-items-center">
          {
          props.optionalAssociates.length > 0 ? (
            <AvatarGroup max={3} sx={{ mx: "auto" }}>
              {optional_associates.map((user) => (
                <span key={`${user.user_id}`} className="mx-auto">
                  {user.image ? (
                    <Avatar
                      onClick={() => handleOpenMemberSelect()}
                      style={{
                        width: 30,
                        height: 30,
                        cursor: "pointer",
                        marginInline: "auto",
                      }}
                      src={baseUri + user.image}
                    />
                  ) : (
                    <Avatar
                      onClick={() => handleOpenMemberSelect()}
                      style={{
                        width: 30,
                        height: 30,
                        cursor: "pointer",
                        marginInline: "auto",
                      }}
                    >
                      {user.first_name.substring(0, 1) +
                        user.last_name.substring(0, 1)}
                    </Avatar>
                  )}
                </span>
              ))}
            </AvatarGroup>
          ) : (
            <Avatar
              // ref={anchorRef}
              onClick={() => handleOpenMemberSelect()}
              style={{
                width: 30,
                height: 30,
                cursor: "pointer",
                marginInline: "auto",
              }}
            >
              <AddIcon style={{ color: "green" }} />
            </Avatar>
          )}
        </div>
      </Tooltip>
      <MenuPopover
        open={openMemberSelect}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <List
          sx={{
            maxHeight: "22rem",
            maxWidth: "16rem",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {props.membersData.length > 0 ? (
            props.membersData.map((member, index) => (
              <React.Fragment key={member.user_id}>
                {isMemberSelected(member) === false ? (
                  <ListItem
                    onClick={() => handleChangeAssociate(member)}
                    style={{ cursor: "pointer" }}
                    className="list-hov"
                  >
                    <ListItemIcon>
                      {member.image !== "" ? (
                        <Avatar
                          alt="Remy Sharp"
                          src={baseUri + member.image}
                          style={{ height: 30, width: 30 }}
                        />
                      ) : (
                        <Avatar
                          alt="Remy Sharp"
                          style={{ height: 30, width: 30 }}
                        >
                          {member.first_name.substring(0, 1)}
                        </Avatar>
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      {member.first_name} {member.last_name}
                    </ListItemText>
                  </ListItem>
                ) : (
                  <ListItem
                    onClick={() => handleChangeAssociate(member)}
                    style={{
                      borderLeft: "2px solid #00AB55",
                      borderRadius: 3,
                      backgroundColor: "rgba(0, 171, 85, 0.13)",
                      margin: 2,
                      cursor: "pointer",
                    }}
                  >
                    <ListItemIcon>
                      {member.image !== "" ? (
                        <Avatar
                          alt="Remy Sharp"
                          src={baseUri + member.image}
                          style={{ height: 30, width: 30 }}
                        />
                      ) : (
                        <Avatar
                          alt="Remy Sharp"
                          style={{ height: 30, width: 30 }}
                        >
                          {member.first_name.substring(0, 1)}
                        </Avatar>
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      {member.first_name} {member.last_name}
                    </ListItemText>
                  </ListItem>
                )}
              </React.Fragment>
            ))
          ) : (
            <ListItem disabled>
              <ListItemText>
                <Typography style={{ fontSize: "0.7rem" }}>
                  No Other Associates
                </Typography>
              </ListItemText>
            </ListItem>
          )}
        </List>
      </MenuPopover>
    </>
  );
}

export default OtherAssociatesPicker;
