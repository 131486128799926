import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack';
import { logmodeis } from "../../../config/config";

// material
import {
  Radio,
  Avatar,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  FormControl,
  ListItemText,
  ListItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ScrollToBottom from 'react-scroll-to-bottom';
import moment from 'moment';
//
import Scrollbar from 'src/components/Scrollbar';
// import ColorManyPicker from '../../ColorManyPicker';
// import Messages from './Messages/Messages';
import {
  addTaskComments,
  taskComments,
  EditComment,
  deleteComment,
  markAllCommentsAsRead,
} from '../../../DAL/Project/Project';
import CkEditor from 'src/components/editor/CKEditor';
import { baseUri } from '../../../config/config';
import ConfirmDeleteComment from 'src/components/Confirmation/ConfirmDeleteComment';
import CommentPopover from 'src/components/Popovers/CommentPopover';
import { set_blank_target } from 'src/utils/DomUtils';
import { useParams } from 'react-router-dom';
import { useSocket } from 'src/Hooks/socketContext';
// import { timelineDotClasses } from '@mui/lab';

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' },
];
export const PRIORITY_OPTIONS = [
  { label: 'Critical', value: 'critical', color: 'error', filterValue: '0' },
  { label: 'High', value: 'high', color: 'primary', filterValue: '1' },
  { label: 'Medium', value: 'medium', color: 'success', filterValue: '2' },
  { label: 'Low', value: 'low', color: 'info', filterValue: '3' },
];

export const STATUS_OPTIONS = [
  {
    label: 'Not Started',
    value: 'not_started',
    color: 'success',
    filterValue: '0',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
    color: 'secondary',
    filterValue: '1',
  },
  {
    label: 'In Review',
    value: 'in_review',
    color: 'warning',
    filterValue: '2',
  },
  {
    label: 'Completed',
    value: 'completed',
    color: 'primary',
    filterValue: '3',
  },
  { label: 'Cancelled', value: 'cancelled', color: 'error', filterValue: '4' },
];
export const FILTER_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];
export const FILTER_PRICE_OPTIONS = [
  { value: 'below', label: 'Below $25' },
  { value: 'between', label: 'Between $25 - $75' },
  { value: 'above', label: 'Above $75' },
];
export const FILTER_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

// ----------------------------------------------------------------------

CommentsDrawer.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

const useStyles = makeStyles(({ theme }) => ({
  textArea: {
    '&.Mui-focused': { outline: 'green' },
  },
  edit: {
    marginLeft: '.2rem',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  editCommentIcon: {
    fontSize: '1rem',
  },
  formMargin: {
    marginTop: 10,
  },
  cancelBtn: {
    width: '100%',
    marginBlock: '.15rem',
    alignSelf: 'center',
    marginRight: '.1rem',
    marginLeft: '.5rem',
    '&:hover': {
      cursor: 'pointer',
      color: 'green',
    },
  },
  noShadow: {
    boxShadow: 'none',
  },
}));

function RenderContent(data) {
  /* shift menu button here */
  const getCommentStatus = (input) => {
    let label = 'Public';
    if (String(input) === String(0)) {
      label = 'Private';
    }
    return label;
  };
  const getHtml = (html) => {
    if (!html) return;
    return { __html: html };
  };
  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === 'Apple Computer, Inc.') {
      is_safari = true;
    }
    return is_safari;
  };
  const getActualTime = (date) => {
    if (!date) {
      return '';
    }
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const title = (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='subtitle2'>{data.user_name}</Typography>

        <div>
          <div className='d-flex'>
            <span
              className='mr-2'
              style={{
                background: 'rgb(246, 247, 248)',
                borderRadius: 3,
                padding: '1px 3px',
                color: `${String(data.status) === String(1) ? '#00aa55' : 'rgb(212, 69, 69)'}`,
                fontWeight: 'bold',
                fontSize: '10px',
              }}
            >
              {getCommentStatus(data.status)}
            </span>
            <Typography
              alignSelf='center'
              component='span'
              variant='body2'
              sx={{ color: 'text.secondary', fontSize: 10 }}
            >
              {/* <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 14, height: 14 }} /> */}
              {data && moment(getActualTime(data.createdAt)).fromNow()}

              {/* {data && moment(data.created_date).format('DD, MMM YYYY hh:mm:sa')} */}
            </Typography>
          </div>
        </div>
      </div>

      {data && <div dangerouslySetInnerHTML={getHtml(data.comment)}></div>}
      {/* <Typography component='span' variant='body2' sx={{ color: 'text.secondary' }}>
        {data && data.comment}
  
      </Typography> */}
    </>
  );

  return {
    avatar: <Avatar alt='title' src={baseUri + data.user_image} />,
    title,
    // detail: notification
  };
}
function NotificationItem({
  commentObj,
  setEditComment,
  commentObjIndex,
  setComment,
  commentsData,
  setCommentData,
  setCommentUpdate,
  getCommentsList,
  setSelectedCommentStatus,
  getTaskList,
}) {
  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === 'Apple Computer, Inc.') {
      is_safari = true;
    }
    return is_safari;
  };
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { title } = RenderContent(commentObj);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDeletingComment, setIsDeletingComment] = React.useState(false);

  // console.log(commentObj, 'comment')
  const handleEditComment = (commentObj) => {
    setSelectedCommentStatus(commentObj.status);
    setComment(commentObj.comment);
    setEditComment(commentObj);
    setCommentUpdate(true);
    setAnchorEl(null);
  };
  const handleDeleteDialogOpen = (state) => {
    setIsDeleteDialogOpen(state);
  };
  const handleDeleteComment = async () => {
    setIsDeleteDialogOpen(true);
    setIsDeletingComment(true);
    const formData = new FormData();
      
    formData.append('comment_id', commentObj.id);
    
    /* delete locally */
    setCommentUpdate(false);
    setComment('');
    let data = [...commentsData];
    commentsData.map((comment, index) => {
      if (comment.id === commentObj.id) {
        if (process.env.NODE_ENV == logmodeis){console.log(comment, 'to be deleted');}
        data.splice(index, 1);
      }
    });
    setCommentData(data);
    if (process.env.NODE_ENV == logmodeis){console.log(data, 'after-del');}
    /* delete through API */

    let response = await deleteComment(commentObj.id);
    if (process.env.NODE_ENV == logmodeis){console.log(response, 'response-delete');}
    if (response.code === 200) {
      setIsDeleteDialogOpen(false);
      setIsDeletingComment(false);
      enqueueSnackbar('Comment deleted', { variant: 'success' });
      getTaskList();
      getCommentsList();
    } else {
      setIsDeleteDialogOpen(false);
      setIsDeletingComment(false);
      getTaskList();
      getCommentsList();
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };
  const isCreator = (commentObj) => {
    let userId = localStorage.getItem('user_id');
    const { action_perform_by } = commentObj;
    let creator = false;
    if (action_perform_by === userId) {
      creator = true;
    }
    return creator;
  };

  return (
    <Stack direction='row' alignItems='flex-start' className='comment-card my-2'>
      <ListItem
        disableGutters
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          width: '100%',
          pr: 0,
          // bgcolor: '#eaeaea'
        }}
      >
        <ListItemText primary={title} />
      </ListItem>

      <div className='pt-1'>
        <CommentPopover
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          isCreator={isCreator}
          handleEditComment={handleEditComment}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          commentObj={commentObj}
        />
      </div>
      <ConfirmDeleteComment
        handleDelete={handleDeleteComment}
        commentObj={commentObj}
        commentsData={commentsData}
        setCommentData={setCommentData}
        isDeleteDialogOpen={isDeleteDialogOpen}
        handleDeleteDialogOpen={handleDeleteDialogOpen}
        setAnchorEl={setAnchorEl}
        isDeletingComment={isDeletingComment}
      />
    </Stack>
  );
}

export default function CommentsDrawer({
  onCloseComment,
  isTaskData,
  getTaskList,
  isCommentOpen,
  markCommentAsRead,
  verifiedListing,
  projectDetail,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { sendSocketNotification } = useSocket();
  const { id } = useParams();
  const [comment, setComment] = React.useState('');
  const [status, setStatus] = React.useState(localStorage.getItem('allow_check_comments') === "1" ? '0':'1');
  const [selectedCommentStatus, setSelectedCommentStatus] = React.useState('0');
  const [commentData, setCommentData] = React.useState([]);

  const addCommentRef = React.useRef();
  const [isCommentUpdating, setIsCommentUpdating] = useState(false);
  const [editComment, setEditComment] = useState();
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const [sendingComment, setSendingComment] = useState(false);
  const [updatingComment, setUpdatingComment] = useState(false);

  const handleCancelUpdate = () => {
    setIsCommentUpdating(false);
    setComment('');
    setStatus('1');
  };
  const applyBlankTargetOnAnchorTag = (id) => {
    set_blank_target(id);
  };
  const getCommentsList = async () => {
    const formData = new FormData(); // formdata object
      
    formData.append('task_id', isTaskData.task_id);
    const result = await taskComments(isTaskData.task_id);
    if (process.env.NODE_ENV == logmodeis){console.log(result, 'task-comments');}
    if (result.code === 200) {
      if (process.env.NODE_ENV == logmodeis){console.log(result, 'comments-result');}
      let reversed = result.comment_list.reverse();
      setCommentData(reversed);
      setTimeout(() => {
        //to open link in new tab
        applyBlankTargetOnAnchorTag('team-portal-comments-listing');
      }, 200);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const handleAddComments = async (e) => {
    e.preventDefault();

    if (isCommentUpdating) {
      setUpdatingComment(true);
      if (process.env.NODE_ENV == logmodeis){console.log(editComment, 'edit-comment-object');
      console.log('status---', selectedCommentStatus)}
      const formData = new FormData();
        
      // formData.append('comment_id', editComment.id);
      formData.append('comment', comment);
      formData.append('status', selectedCommentStatus);
      
      /* editing locally */
      let data = [];
      
      
      commentData.map((userComment, index) => {
        if (userComment.id === editComment.id) {
          userComment['comment'] = comment;
          userComment['status'] = selectedCommentStatus;
        }
        data.push(userComment);
      });
      if (process.env.NODE_ENV == logmodeis){console.log(data, 'edited comment data');}
      setCommentData(data);
      setComment('');
      setIsCommentUpdating(false);

      /* editing through api */
      let response = await EditComment(formData,editComment._id);
      if (process.env.NODE_ENV == logmodeis){console.log(response, 'response');}
      if (response.code === 200) {
        setUpdatingComment(false);
        if (process.env.NODE_ENV == logmodeis){console.log('comment added');}
        getCommentsList();
        getTaskList();
        enqueueSnackbar('Comment edited', { variant: 'success' })
      } else {
        setUpdatingComment(false);
        getTaskList();
        getCommentsList();
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    }
    if (!isCommentUpdating) {
      if (comment.trim() === ' ') {
        enqueueSnackbar('Comment cannot be empty', { variant: 'error' });
        return;
      }
      if (comment.trim() === '') {
        enqueueSnackbar('Comment cannot be empty', { variant: 'error' });
        return;
      }
      setSendingComment(true);
      if (process.env.NODE_ENV == logmodeis){console.log(isCommentUpdating, 'add comment');
      console.log("Status report",status);}
      const formData = new FormData();
        
      formData.append('task_id', isTaskData.task_id);
      formData.append('comment', comment);
      formData.append('status', status);

      const result = await addTaskComments(formData);
      if (result.code === 200) {
        setSendingComment(false);
        if (process.env.NODE_ENV == logmodeis){console.log(result, 'result');}
        getCommentsList();
        getTaskList();
        setComment('');
        enqueueSnackbar(result.message, { variant: 'success' });

        if (String(isTaskData.visibilityStatus) !== false) {
          // for admin
          const payload_admin = {
            receiver_id: projectDetail.project_manager_id,
            message: 'A new comment has been added',
            project_id: id,
            task_id: isTaskData.task_id,
            project_name: projectDetail.project_name,
           notification_type: 'task',
          };
          if (process.env.NODE_ENV == logmodeis){console.log(payload_admin, 'admin-notification-payload');}
          sendSocketNotification(payload_admin);
          if (String(localStorage.getItem('user_id')) !== String(isTaskData.task_creator_id)) {
            //for task creator
            const payload_creator = {
              receiver_id: isTaskData.task_creator_id,
              message: 'A new comment has been added (admin)',
              project_id: id,
              task_id: isTaskData.task_id,
              project_name: projectDetail.project_name,
              notification_type: 'task',
            };
            if (process.env.NODE_ENV == logmodeis){console.log(payload_creator, 'creator-notification-payload');}
            sendSocketNotification(payload_creator);
          }
          //for team
          isTaskData.members.map((member) => {
            if (String(localStorage.getItem('user_id')) !== String(member.user_id)) {
              const payload_team = {
                receiver_id: member.user_id,
                message: 'A new comment has been added (team)',
                project_id: id,
                task_id: isTaskData.task_id,
                project_name: projectDetail.project_name,
                notification_type: 'task',
              };
              if (process.env.NODE_ENV == logmodeis){console.log(payload_team, 'members-notification-payload');}
              sendSocketNotification(payload_team);
            }
          });
        }
      } else {
        setSendingComment(false);
        getTaskList();
        enqueueSnackbar(result.message, { variant: 'error' });
      }
    }
  };
  const isSendButtonDisabled = () => {
    let disabled = true;

    if (isTaskData.isAssigned === false) {
      return true;
    }
    if (localStorage.getItem('user_id') == isTaskData.task_creator_id) {
      disabled = false;
    }
    if (isTaskData.isAssigned === true) {
      disabled = false;
    }
    if (sendingComment) {
      disabled = true;
    }
    if (!sendingComment) {
      disabled = false;
    }

    return disabled;
  };
  const markCommentsAsRead = async (taskId) => {
    if (process.env.NODE_ENV == logmodeis){console.log(taskId, 'mark as read');}
    const formData = new FormData();
      
    formData.append('task_id', taskId);
    if (process.env.NODE_ENV == logmodeis){console.log(...formData, 'mark-read-req');}
    const response = await markAllCommentsAsRead(taskId);
    if (response.code === 200) {
      if (process.env.NODE_ENV == logmodeis){console.log(response, 'read-resp');}

      markCommentAsRead(taskId);
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };
  useEffect(async () => {
    if (isCommentOpen) {
      getCommentsList();
      if (verifiedListing === false) {
        if (process.env.NODE_ENV == logmodeis){console.log('marking-read');}
        markCommentsAsRead(isTaskData.task_id);
      }
      if (process.env.NODE_ENV == logmodeis){console.log(isTaskData, 'task-at-comment-drawer');}
    }
    setCommentData([]);
  }, [isCommentOpen]);
  useEffect(() => {
    if (isCommentOpen) {
      setIsCommentUpdating(false);
      setComment('');
      setIsTextFieldFocused(false);
    }
    // console.log(isTaskData, 'task-at-comment-drawer');
  }, [isCommentOpen]);
  return (
    <>
      <Drawer
        disableEnforceFocus={true}
        anchor='right'
        open={isCommentOpen}
        onClose={onCloseComment}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '450px', md: '450px' },
            border: 'none',
            overflow: 'hidden',
          },
        }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ px: 1 }}>
          <IconButton onClick={onCloseComment}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <div className='comments-outerContainer' style={{ marginTop: 10 }}>
          <div className='comments-container' id='team-portal-comments-listing'>
            <div role='button' className='py-1' style={{ paddingInline: '5px' }}>
              {/* <Tooltip title='Task' placement='top'> */}
              <Typography style={{ fontsize: 16, fontWeight: 'bold' }}>
                {isTaskData.task}
              </Typography>
              {/* </Tooltip> */}
            </div>
            {/* <InfoBar /> */}
            <ScrollToBottom className='messages'>
              <Scrollbar>
                {commentData.length > 0 ? (
                  commentData.map((commentObj, index) => (
                    <NotificationItem
                      key={index}
                      getCommentsList={getCommentsList}
                      commentsData={commentData}
                      setCommentData={setCommentData}
                      targetRef={addCommentRef}
                      setEditComment={setEditComment}
                      commentStatus={status}
                      commentObj={commentObj}
                      setComment={setComment}
                      commentObjIndex={index}
                      setCommentUpdate={setIsCommentUpdating}
                      isTextFieldFocused={isTextFieldFocused}
                      setIsTextFieldFocused={setIsTextFieldFocused}
                      setSelectedCommentStatus={setSelectedCommentStatus}
                      selectedCommentStatus={selectedCommentStatus}
                      getTaskList={getTaskList}
                    />
                  ))
                ) : (
                  <Typography>No comments yet</Typography>
                )}
              </Scrollbar>
            </ScrollToBottom>
            {/* <Input /> */}
            <form className={`form ${classes.formMargin}`} onSubmit={handleAddComments}>
              <div className='comments-input'></div>
              <div className='commentItemParent'>
                <CkEditor
                  mode={isTaskData.isAssigned ? false : true}
                  content={comment}
                  setContent={(value) => setComment(value)}
                />
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='row' style={{ paddingInline: '15px' }}>
                    <div className='pr-2 pl-md-0'>
                  
                   
                      <FormControl component='fieldset'>
                        {!isCommentUpdating ? (
                          <RadioGroup
                            row
                            aria-label='gender'
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            name='row-radio-buttons-group'
                          >
                            <FormControlLabel
                              value={'1'}
                              control={
                                <Radio
                                  disabled={
                                    localStorage.getItem('user_id') == isTaskData.task_creator_id ||
                                    isTaskData.isAssigned === true
                                      ? false
                                      : true
                                  }
                                />
                              }
                              label='Public'
                              style={{ marginBottom: 0, fontSize: 10 }}
                            />
                            <FormControlLabel
                              value={'0'}
                              control={
                                <Radio
                                  disabled={
                                    localStorage.getItem('user_id') == isTaskData.task_creator_id ||
                                    isTaskData.isAssigned === true
                                      ? false
                                      : true
                                  }
                                />
                              }
                              label='Private'
                              style={{ marginBottom: 0, fontSize: 10 }}
                            />
                          </RadioGroup>
                        ) 
                       : (
                         <RadioGroup
                            row
                            aria-label='gender'
                            value={selectedCommentStatus}
                            onChange={(e) => setSelectedCommentStatus(e.target.value)}
                            name='row-radio-buttons-group'
                          >
                            <FormControlLabel
                              value='1'
                              control={<Radio />}
                              label='Public'
                              style={{ marginBottom: 0, fontSize: 10 }}
                            />
                            <FormControlLabel
                              value='0'
                              control={<Radio />}
                              label='Private'
                              style={{ marginBottom: 0, fontSize: 10 }}
                            />
                          </RadioGroup>
                        )}
                      </FormControl>
                    </div>
                    <div className='mt-1 pr-2 pl-md-0' style={{ marginLeft: 'auto' }}>
                      {!isCommentUpdating ? (
                        <Button
                          className={classes.noShadow}
                          variant='contained'
                          type='submit'
                          style={{ width: '5rem' }}
                          disabled={isSendButtonDisabled()}
                        >
                          {sendingComment ? 'Sending...' : 'Send'}
                        </Button>
                      ) : (
                        <Button
                          className={classes.noShadow}
                          variant='contained'
                          type='submit'
                          style={{ width: '5rem' }}
                          disabled={
                            localStorage.getItem('user_id') == isTaskData.task_creator_id ||
                            isTaskData.isAssigned === true
                              ? false
                              : true
                          }
                        >
                          Update
                        </Button>
                      )}
                      {isCommentUpdating && (
                        <span onClick={handleCancelUpdate} className={classes.cancelBtn}>
                          cancel
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* confirmation dialog */}
      </Drawer>
    </>
  );
}
