import  dc_token  from "../../utils/tokenDecryption";
import { invokeApi } from '../../bl_libs/invokeApi';

export const addSaleNote = async (data) => {
  const requestObj = {
    path: `api/sale_note/add_sale_note`,
    method: 'POST',
    headers: {
      'x-sh-auth': dc_token()
    },
    postData: data
  };
  return invokeApi(requestObj);
};

export const editSaleNote = async (data,saleNoteId) => {
  const requestObj = {
    path: `api/sale_note/edit_sale_note/${saleNoteId}`,
    method: 'PUT',
    headers: {
      'x-sh-auth': dc_token()
    },
    postData: data
  };
  return invokeApi(requestObj);
};

export const saleNoteDetail = async (saleId) => {
  const requestObj = {
    path: `api/sale_note/sale_note_detail/${saleId}`,
    method: 'GET',
    headers: {
      'x-sh-auth': dc_token()
    },
    // postData: data
  };
  return invokeApi(requestObj);
};

export const deleteSaleNote = async (saleNoteId) => {
  const requestObj = {
    path: `api/sale_note/delete_sale_note/${saleNoteId}`,
    method: 'DELETE',
    headers: {
      'x-sh-auth': dc_token()
    },
    // postData: data
  };
  return invokeApi(requestObj);
};

export const listingSaleNote = async (saleId) => {
  const requestObj = {
    path: `api/sale_note/sale_note_list/${saleId}`,
    method: 'GET',
    headers: {
      'x-sh-auth': dc_token()
    },
  };
  return invokeApi(requestObj);
};
