import React, { useState, useEffect } from "react";
import { logmodeis } from "src/config/config";
import {
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import NoteLayout from "./components/NoteLayout";
import { useSnackbar } from "notistack";
import { css } from "@emotion/css";

import {
  format,
} from "date-fns";
import { allNotesList } from "../../DAL/Project/Project";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: '50%',
    marginTop: '20%',
  },
  img: {
    margin: 'auto',
  },
}));

const dataofnotes = [
  {
    id: 1,
    targetdate: "23 jan 2022",
    time: "23 jan 2019",
    title: "this is demo title this is demo title this is demo title",
    disc: " this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text",
  },
  {
    id: 2,
    targetdate: "13 jan 2022",
    time: "23 jan 2019",
    title: "this is demo title",
    disc: " this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text",
  },
  {
    id: 3,
    targetdate: "23 feb 2022",
    time: "23 jan 2019",
    title: "this is demo title",
    disc: " this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text",
  },
  {
    id: 4,
    targetdate: "23 dec 2022",
    time: "23 jan 2019",
    title: "this is demo title",
    disc: " this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text",
  },
  {
    id: 5,
    targetdate: "23 jan 2022",
    time: "23 jan 2019",
    title: "this is demo title",
    disc: " this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text",
  },
  {
    id: 6,
    targetdate: "23 jan 2022",
    time: "23 jan 2019",
    title: "this is demo title",
    disc: " this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text",
  },
  {
    id: 7,
    targetdate: "23 jan 2022",
    time: "23 jan 2019",
    title: "this is demo title",
    disc: " this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text",
  },
  {
    id: 8,
    targetdate: "23 jan 2022",
    time: "23 jan 2019",
    title: "this is demo title",
    disc: " this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text",
  },
  {
    id: 9,
    targetdate: "23 jan 2022",
    time: "23 jan 2019",
    title: "this is demo title",
    disc: " this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text",
  },
  {
    id: 10,
    targetdate: "23 jan 2022",
    time: "23 jan 2019",
    title: "this is demo title",
    disc: " this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text",
  },
  {
    id: 11,
    targetdate: "23 jan 2022",
    time: "23 jan 2019",
    title: "this is demo title",
    disc: " this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text",
  },
  {
    id: 12,
    targetdate: "23 jan 2022",
    time: "23 jan 2019",
    title: "this is demo title",
    disc: " this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text this is demo text",
  },
];

function AllNotes() {
  const [dataNotes, setdataNotes] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [todate, settodate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [fromdate, setfromdate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [targettime, settargettime] = useState(format(new Date(), "HH:mm:ss"));


  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handeltodate = (e) => {
    if(process.env.NODE_ENV == logmodeis){console.log("todate value is ", e);}
    settodate(e);
  };

  const handelfromdate = (e) => {
    if(process.env.NODE_ENV == logmodeis){console.log("Fromdate value is ", e);}
    setfromdate(e);
  };

  useEffect(() => {
    Listofallnotes();
    return () => {};
  }, []);

  const deletenote = (id) => {
    Listofallnotes();
    // console.log(id,'delete note')
    // let newarray = []
    // newarray= dataNotes.filter(x => x._id !== id)

    // // newarray = dataNotes.splice(i,1)
    // setdataNotes(newarray);
  };

  const Listofallnotes = async () => {
    setIsWaiting(true);
    const formData = new FormData();
     
    if(process.env.NODE_ENV == logmodeis){console.log(...formData, "Listofallnotes-req");}
    const result = await allNotesList(formData);
    if(process.env.NODE_ENV == logmodeis){console.log(result, "Listofallnotes-res");}
    if (result.code === 200) {
      setdataNotes(result.note_list);
      setIsWaiting(false);
      //   enqueueSnackbar("Project order updated", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const FilteredSearch = async () => {
    setIsWaiting(true);
    // console.log("to", todate);
    // console.log("from", fromdate);
    const formData = new FormData();
    formData.append("to", todate);
    formData.append("from", fromdate);
     
    if(process.env.NODE_ENV == logmodeis){console.log(...formData, "FilteredSearch-req");}
    const result = await allNotesList(formData);
    if(process.env.NODE_ENV == logmodeis){console.log(result, "FilteredSearch-res");}
    if (result.code === 200) {
      setdataNotes(result.note_list);
      setIsWaiting(false);
      //   enqueueSnackbar("Project order updated", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  if (isWaiting) {
    return <CircularProgress className={classes.loading} color='primary' />;
  }

  return (
    <div style={{}}>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 40 }}
          >
            <div style={{ marginRight: 20 }}>
              <Button
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                component="span"
                variant="contained"
                color="primary"
                onClick={() => Listofallnotes()}
              >
                All
              </Button>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginRight: 10 }}>
                <TextField
                  size="small"
                  id="datefrom"
                  label="From Date"
                  type="date"
                  defaultValue={fromdate}
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => handelfromdate(e.target.value)}
                />
              </div>
              <div style={{ marginRight: 10 }}>
                <TextField
                  size="small"
                  id="dateto"
                  label="To Date"
                  type="date"
                  defaultValue={todate}
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => handeltodate(e.target.value)}
                />
              </div>
              <div style={{}}>
                <Button
                  style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                  component="span"
                  // onClick={() => navigate("/Notes/AddNotes")}
                  variant="contained"
                  color="primary"
                  onClick={() => FilteredSearch()}
                >
                  Find
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button
            style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
            component="span"
            onClick={() =>
              navigate("/Notes/AddNotes", {
                state: {
                  data: [],
                  comefrom: "add",
                },
              })
            }
            variant="contained"
            color="primary"
            startIcon={<Icon icon="material-symbols:add-circle-outline" />}
          >
            Add New Notes
          </Button>
        </div>
      </div>

      <div
        className={css`
          display: grid;
          grid-gap: 2rem;
          grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
        `}
      >
        {dataNotes && dataNotes.length !== 0 ? (
          dataNotes.map((x, i) => {
            return (
              <NoteLayout
                key={x._id}
                data={x}
                index={i}
                deletenote={deletenote}
              />
            );
          })
        ) : (
          <div
            className={css`
              // background-color:red;
              // width: auto;
              // height: 20rem;
              // display: flex;
              // justify-content:center;
              // align-items: center;
              //
            `}
          >
            <p>No notes found</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default AllNotes;
