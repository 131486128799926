import * as React from 'react'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, List, ListItem, ListItemButton } from '@mui/material'

export default function CommentPopover({
  isCreator,
  handleEditComment,
  setIsDeleteDialogOpen,
  commentObj,
  anchorEl,
  setAnchorEl,
}) {
  //   const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <IconButton
        disabled={isCreator(commentObj) ? false : true}
        aria-describedby={id}
        variant='contained'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List sx={{ fontSize: '12px' }}>
          <ListItem disabled={isCreator(commentObj) ? false : true} sx={{ padding: 0 }}>
            <ListItemButton
              onClick={() => handleEditComment(commentObj)}
              // disabled={isCreator(commentObj) ? false : true}
            >
              Edit
            </ListItemButton>
          </ListItem>
          <ListItem disabled={isCreator(commentObj) ? false : true} sx={{ padding: 0 }}>
            <ListItemButton
              onClick={() => setIsDeleteDialogOpen(true)}
              // disabled={isCreator(commentObj) ? false : true}
            >
              Delete
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  )
}
