import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import {
  Container,
  TextField,
  Grid,
  IconButton,
  CircularProgress,
  Button,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  departmentDetail,
  editDepartment,
} from "../../DAL/Department/Department";
import { baseUri, logmodeis } from "../../config/config";

const useStyles = makeStyles(() => ({
  img: {
    width: "100%",
    height: 300,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function EditDepartment(props) {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setIswaiting] = useState(true);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [status, setStatus] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (process.env.NODE_ENV == logmodeis) {
      console.log(name, "department name");
    }
    let _status;
    if (status === true) {
      _status = 1;
    } else {
      _status = 0;
    }
    if (process.env.NODE_ENV == logmodeis) {
      console.log(_status, "_status");
    }
    setIswaiting(true);
    const formData = new FormData();
    formData.append("department_name", name);
    formData.append("description", description);
    formData.append("status", _status);
    if (Image) {
      formData.append("department_image", Image);
    }

    formData.append("department_id", id);
    const result = await editDepartment(formData);
    if (result.code === 200) {
      setIswaiting(false);
      navigate(-1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIswaiting(false);
    }
  };

  const fileChangedHandler = async (e) => {
    // setiswaiting(true);
    if (process.env.NODE_ENV == logmodeis) {
      console.log(e.target.files[0]);
    }
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setimage(e.target.files[0]);
    // let formData = new FormData(); //formdata object
    // formData.append("directory", "images/");
    // formData.append("image", e.target.files[0]);
    // const upload_image_resp = await upload_image(formData);
    // console.log(upload_image_resp);
    // setimage(upload_image_resp.path);
    // if(upload_image_resp.code == 200){
    //   setiswaiting(false);
    // }
  };

  const fetchTeamDetail = async () => {
    const formData = new FormData();

    formData.append("department_id", id);
    const result = await departmentDetail(formData);
    if (result.code === 200) {
      setIswaiting(false);
      setName(result.department_data.department_name);
      setDescription(result.department_data.description);
      // setEmail(result.Team_Data.email);
      if (result.department_data.status) {
        setStatus(true);
      } else {
        setStatus(false);
      }
      setimage(result.department_data.image);
      if (result.department_data.image) {
        setProfileImage(baseUri + result.department_data.image);
      } else {
        setProfileImage(
          "https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg"
        );
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIswaiting(false);
    }
  };

  useEffect(() => {
    fetchTeamDetail();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <Container maxWidth="sm">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ mb: 5 }} style={{ textAlign: "center" }}>
          Edit Department
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="name"
                label="Name"
                type="name"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="name"
                label="Description"
                type="description"
                fullWidth
                variant="outlined"
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              {file ? (
                <>
                  {" "}
                  <img
                    className={classes.img}
                    src={file}
                    alt="team member"
                  />{" "}
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/png,image/jpeg,image/jpg"
                hidden
                id="contained-button-file"
                multiple
                type="file"
                onChange={fileChangedHandler}
              />
              <label
                htmlFor="contained-button-file"
                style={{ display: "flex" }}
              >
                <Button
                  component="span"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  startIcon={<DriveFolderUploadIcon />}
                >
                  Upload Image
                </Button>
              </label>
            </Grid>

            <Grid item xs={6}>
              Status :
              <Switch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button variant="contained" type="submit">
                Edit Department
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default EditDepartment;
