import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Tooltip from "@mui/material/Tooltip";
import "@fullcalendar/daygrid/main.css";
import { Box } from "@mui/material";
import Loader from "src/components/Loader";
const status_color_and_value = [
  {
    value: 0,
    label: "Pending",
    color: "#eedc82",
  },
  {
    value: 1,
    label: "In Progress",
    color: "#9bddff",
  },
  //value:2 for in-review, currently not being used.
  {
    value: 3,
    label: "Completed",
    color: "#b2ec5d",
  },
  {
    value: 4,
    label: "Cancelled",
    color: "#ff6961",
  },
  {
    value: 5,
    label: "Verified",
    color: "#969292",
  },
];
const FullCalender = ({ loading, events, onEventClick }) => {
  const getStatusProps = (value) => {
    if (!value) {
      return { color: "" };
    }

    let found = status_color_and_value.find(
      (item) => String(item.value) === String(value)
    );
    if (found) {
      return found;
    }
  };
  const renderEventContent = (info) => {
    const { more } = info.event._def.extendedProps;
    if (!more) {
      return <></>;
    }
    return (
      <Box>
        <Tooltip title={more.task_name}>
          <div className="calender-events-box">
            <b
              className="calender-events-text"
              style={{
                backgroundColor: getStatusProps(more.task_status).color,
              }}
            >
              {more?.task_name.length > 18
                ? `${more.task_name.substring(0, 18)}..`
                : more.task_name}
            </b>
          </div>
        </Tooltip>
      </Box>
    );
  };

  /* <<--------------------------------------------------------->> */
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <div className="full-calendar">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "today prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        className="fc-day-sun fc-toolbar-chunk fc eventimage fc-col-header-cell-cushion fc-today-button fc-right fc-prev-button fc-right fc-next-button fc-dayGridMonth-button fc-timeGridWeek-button fc-timeGridDay-button fc-daygrid-day-number fc-daygrid-day-top"
        eventContent={renderEventContent}
        eventClick={onEventClick}
        events={events}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={2}
        weekends={true}
      />
    </div>
  );
};

export default FullCalender;
// const custom_events = [
//   {
//     start: "2022-08-06",
//     title: "this is title",
//     allDay: true,
//     task: [
//       {
//         section_id: "62e366bc360ed3ca43ce0668",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 3,
//           task_status: 0,
//           status: true,
//           order: 1,
//           team: [
//             {
//               _id: "62a86cea1ec16b4c24f1e4af",
//               user: "629dd28ffe5c3403d730d6ec",
//               user_profile: {
//                 first_name: "done",
//                 last_name: "dev 2",
//                 email: "dev2@gmail.com",
//                 image: "image/553bbcc0-e8ac-11ec-b080-a9560f063d68.jpeg",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "manager",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:38.291Z",
//               updatedAt: "2022-06-14T11:11:38.291Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: [
//             "629dd224fe5c3403d730d6bc",
//             "629dd28ffe5c3403d730d6ec",
//           ],
//           _id: "62e3670c360ed3ca43ce06d8",
//           task_title: "NEXT TASK ONE",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86c911ec16b4c24f1e31c",
//             user: "629dd224fe5c3403d730d6bc",
//             user_profile: {
//               _id: "629dd224fe5c3403d730d6bc",
//               first_name: "Done",
//               last_name: "Dev 1",
//               email: "dev1@gmail.com",
//               image: "image/4e816ed0-e581-11ec-b080-a9560f063d68.jpeg",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "admin",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:10:09.952Z",
//             updatedAt: "2022-06-14T11:10:09.952Z",
//             user_status: 1,
//             leave_reason: "",
//           },
//           task_action_by: "admin",
//           created_at: "2022-07-29T04:50:20.276Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//       {
//         section_id: "62e366b0360ed3ca43ce0600",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [
//             "image/2dfcce70-1236-11ed-ae9b-c398362370c1.png",
//             "excel/2e4ff780-1236-11ed-ae9b-c398362370c1.xlsx",
//             "ppt/2ec8d100-1236-11ed-ae9b-c398362370c1.pptx",
//             "docx/2f36fc20-1236-11ed-ae9b-c398362370c1.docx",
//             "pdf/2f94d390-1236-11ed-ae9b-c398362370c1.pdf",
//           ],
//           priority: 0,
//           task_status: 2,
//           status: true,
//           order: 1,
//           team: [
//             {
//               _id: "62a86cea1ec16b4c24f1e4af",
//               user: "629dd28ffe5c3403d730d6ec",
//               user_profile: {
//                 first_name: "done",
//                 last_name: "dev 2",
//                 email: "dev2@gmail.com",
//                 image: "image/553bbcc0-e8ac-11ec-b080-a9560f063d68.jpeg",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "manager",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:38.291Z",
//               updatedAt: "2022-06-14T11:11:38.291Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86d001ec16b4c24f1e535",
//               user: "62a71652fe5c3403d73200df",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "four",
//                 email: "dev4@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:12:00.848Z",
//               updatedAt: "2022-06-14T11:12:00.848Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: [
//             "629dd224fe5c3403d730d6bc",
//             "629dd224fe5c3403d730d6bc",
//             "629dd224fe5c3403d730d6bc",
//           ],
//           _id: "62e366fc360ed3ca43ce068b",
//           task_title: "DEFAULT TASK ONE",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86c911ec16b4c24f1e31c",
//             user: "629dd224fe5c3403d730d6bc",
//             user_profile: {
//               _id: "629dd224fe5c3403d730d6bc",
//               first_name: "Done",
//               last_name: "Dev 1",
//               email: "dev1@gmail.com",
//               image: "image/4e816ed0-e581-11ec-b080-a9560f063d68.jpeg",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "admin",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:10:09.952Z",
//             updatedAt: "2022-06-14T11:10:09.952Z",
//             user_status: 1,
//             leave_reason: "",
//           },
//           task_action_by: "admin",
//           created_at: "2022-07-29T04:50:04.581Z",
//           updated_at: "2022-08-01T06:48:41.258Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//       {
//         section_id: "62e366b0360ed3ca43ce0600",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 2,
//           task_status: 2,
//           status: true,
//           order: 5,
//           team: [
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: ["62a7162afe5c3403d73200c9"],
//           _id: "62ee0ce9cdd00704deb56f7c",
//           task_title: "another task for myself",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86cf71ec16b4c24f1e4f0",
//             user: "62a7162afe5c3403d73200c9",
//             user_profile: {
//               first_name: "dev",
//               last_name: "three",
//               email: "dev3@gmail.com",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "team",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:11:51.739Z",
//             updatedAt: "2022-07-29T07:53:38.940Z",
//             user_status: 1,
//             leave_reason: "alksdjfdssdsd",
//           },
//           task_action_by: "team",
//           created_at: "2022-08-06T06:40:41.227Z",
//           updated_at: "2022-08-06T07:14:33.138Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//       {
//         section_id: "62e366b0360ed3ca43ce0600",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 3,
//           task_status: 0,
//           status: true,
//           order: 6,
//           team: [
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: ["62a7162afe5c3403d73200c9"],
//           _id: "62ee0d14cdd00704deb56ff8",
//           task_title: "another for testing",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86cf71ec16b4c24f1e4f0",
//             user: "62a7162afe5c3403d73200c9",
//             user_profile: {
//               first_name: "dev",
//               last_name: "three",
//               email: "dev3@gmail.com",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "team",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:11:51.739Z",
//             updatedAt: "2022-07-29T07:53:38.940Z",
//             user_status: 1,
//             leave_reason: "alksdjfdssdsd",
//           },
//           task_action_by: "team",
//           created_at: "2022-08-06T06:41:24.681Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//     ],
//   },
//   {
//     start: "2022-08-06",
//     title: "this is title",
//     allDay: true,
//     task: [
//       {
//         section_id: "62e366bc360ed3ca43ce0668",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 3,
//           task_status: 0,
//           status: true,
//           order: 1,
//           team: [
//             {
//               _id: "62a86cea1ec16b4c24f1e4af",
//               user: "629dd28ffe5c3403d730d6ec",
//               user_profile: {
//                 first_name: "done",
//                 last_name: "dev 2",
//                 email: "dev2@gmail.com",
//                 image: "image/553bbcc0-e8ac-11ec-b080-a9560f063d68.jpeg",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "manager",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:38.291Z",
//               updatedAt: "2022-06-14T11:11:38.291Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: [
//             "629dd224fe5c3403d730d6bc",
//             "629dd28ffe5c3403d730d6ec",
//           ],
//           _id: "62e3670c360ed3ca43ce06d8",
//           task_title: "NEXT TASK ONE",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86c911ec16b4c24f1e31c",
//             user: "629dd224fe5c3403d730d6bc",
//             user_profile: {
//               _id: "629dd224fe5c3403d730d6bc",
//               first_name: "Done",
//               last_name: "Dev 1",
//               email: "dev1@gmail.com",
//               image: "image/4e816ed0-e581-11ec-b080-a9560f063d68.jpeg",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "admin",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:10:09.952Z",
//             updatedAt: "2022-06-14T11:10:09.952Z",
//             user_status: 1,
//             leave_reason: "",
//           },
//           task_action_by: "admin",
//           created_at: "2022-07-29T04:50:20.276Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//       {
//         section_id: "62e366b0360ed3ca43ce0600",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [
//             "image/2dfcce70-1236-11ed-ae9b-c398362370c1.png",
//             "excel/2e4ff780-1236-11ed-ae9b-c398362370c1.xlsx",
//             "ppt/2ec8d100-1236-11ed-ae9b-c398362370c1.pptx",
//             "docx/2f36fc20-1236-11ed-ae9b-c398362370c1.docx",
//             "pdf/2f94d390-1236-11ed-ae9b-c398362370c1.pdf",
//           ],
//           priority: 0,
//           task_status: 2,
//           status: true,
//           order: 1,
//           team: [
//             {
//               _id: "62a86cea1ec16b4c24f1e4af",
//               user: "629dd28ffe5c3403d730d6ec",
//               user_profile: {
//                 first_name: "done",
//                 last_name: "dev 2",
//                 email: "dev2@gmail.com",
//                 image: "image/553bbcc0-e8ac-11ec-b080-a9560f063d68.jpeg",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "manager",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:38.291Z",
//               updatedAt: "2022-06-14T11:11:38.291Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86d001ec16b4c24f1e535",
//               user: "62a71652fe5c3403d73200df",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "four",
//                 email: "dev4@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:12:00.848Z",
//               updatedAt: "2022-06-14T11:12:00.848Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: [
//             "629dd224fe5c3403d730d6bc",
//             "629dd224fe5c3403d730d6bc",
//             "629dd224fe5c3403d730d6bc",
//           ],
//           _id: "62e366fc360ed3ca43ce068b",
//           task_title: "DEFAULT TASK ONE",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86c911ec16b4c24f1e31c",
//             user: "629dd224fe5c3403d730d6bc",
//             user_profile: {
//               _id: "629dd224fe5c3403d730d6bc",
//               first_name: "Done",
//               last_name: "Dev 1",
//               email: "dev1@gmail.com",
//               image: "image/4e816ed0-e581-11ec-b080-a9560f063d68.jpeg",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "admin",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:10:09.952Z",
//             updatedAt: "2022-06-14T11:10:09.952Z",
//             user_status: 1,
//             leave_reason: "",
//           },
//           task_action_by: "admin",
//           created_at: "2022-07-29T04:50:04.581Z",
//           updated_at: "2022-08-01T06:48:41.258Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//       {
//         section_id: "62e366b0360ed3ca43ce0600",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 2,
//           task_status: 2,
//           status: true,
//           order: 5,
//           team: [
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: ["62a7162afe5c3403d73200c9"],
//           _id: "62ee0ce9cdd00704deb56f7c",
//           task_title: "another task for myself",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86cf71ec16b4c24f1e4f0",
//             user: "62a7162afe5c3403d73200c9",
//             user_profile: {
//               first_name: "dev",
//               last_name: "three",
//               email: "dev3@gmail.com",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "team",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:11:51.739Z",
//             updatedAt: "2022-07-29T07:53:38.940Z",
//             user_status: 1,
//             leave_reason: "alksdjfdssdsd",
//           },
//           task_action_by: "team",
//           created_at: "2022-08-06T06:40:41.227Z",
//           updated_at: "2022-08-06T07:14:33.138Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//       {
//         section_id: "62e366b0360ed3ca43ce0600",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 3,
//           task_status: 0,
//           status: true,
//           order: 6,
//           team: [
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: ["62a7162afe5c3403d73200c9"],
//           _id: "62ee0d14cdd00704deb56ff8",
//           task_title: "another for testing",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86cf71ec16b4c24f1e4f0",
//             user: "62a7162afe5c3403d73200c9",
//             user_profile: {
//               first_name: "dev",
//               last_name: "three",
//               email: "dev3@gmail.com",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "team",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:11:51.739Z",
//             updatedAt: "2022-07-29T07:53:38.940Z",
//             user_status: 1,
//             leave_reason: "alksdjfdssdsd",
//           },
//           task_action_by: "team",
//           created_at: "2022-08-06T06:41:24.681Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//     ],
//   },
//   {
//     start: "2022-08-06",
//     title: "this is title",
//     allDay: true,
//     task: [
//       {
//         section_id: "62e366bc360ed3ca43ce0668",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 3,
//           task_status: 0,
//           status: true,
//           order: 1,
//           team: [
//             {
//               _id: "62a86cea1ec16b4c24f1e4af",
//               user: "629dd28ffe5c3403d730d6ec",
//               user_profile: {
//                 first_name: "done",
//                 last_name: "dev 2",
//                 email: "dev2@gmail.com",
//                 image: "image/553bbcc0-e8ac-11ec-b080-a9560f063d68.jpeg",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "manager",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:38.291Z",
//               updatedAt: "2022-06-14T11:11:38.291Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: [
//             "629dd224fe5c3403d730d6bc",
//             "629dd28ffe5c3403d730d6ec",
//           ],
//           _id: "62e3670c360ed3ca43ce06d8",
//           task_title: "NEXT TASK ONE",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86c911ec16b4c24f1e31c",
//             user: "629dd224fe5c3403d730d6bc",
//             user_profile: {
//               _id: "629dd224fe5c3403d730d6bc",
//               first_name: "Done",
//               last_name: "Dev 1",
//               email: "dev1@gmail.com",
//               image: "image/4e816ed0-e581-11ec-b080-a9560f063d68.jpeg",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "admin",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:10:09.952Z",
//             updatedAt: "2022-06-14T11:10:09.952Z",
//             user_status: 1,
//             leave_reason: "",
//           },
//           task_action_by: "admin",
//           created_at: "2022-07-29T04:50:20.276Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//       {
//         section_id: "62e366b0360ed3ca43ce0600",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [
//             "image/2dfcce70-1236-11ed-ae9b-c398362370c1.png",
//             "excel/2e4ff780-1236-11ed-ae9b-c398362370c1.xlsx",
//             "ppt/2ec8d100-1236-11ed-ae9b-c398362370c1.pptx",
//             "docx/2f36fc20-1236-11ed-ae9b-c398362370c1.docx",
//             "pdf/2f94d390-1236-11ed-ae9b-c398362370c1.pdf",
//           ],
//           priority: 0,
//           task_status: 2,
//           status: true,
//           order: 1,
//           team: [
//             {
//               _id: "62a86cea1ec16b4c24f1e4af",
//               user: "629dd28ffe5c3403d730d6ec",
//               user_profile: {
//                 first_name: "done",
//                 last_name: "dev 2",
//                 email: "dev2@gmail.com",
//                 image: "image/553bbcc0-e8ac-11ec-b080-a9560f063d68.jpeg",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "manager",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:38.291Z",
//               updatedAt: "2022-06-14T11:11:38.291Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86d001ec16b4c24f1e535",
//               user: "62a71652fe5c3403d73200df",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "four",
//                 email: "dev4@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:12:00.848Z",
//               updatedAt: "2022-06-14T11:12:00.848Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: [
//             "629dd224fe5c3403d730d6bc",
//             "629dd224fe5c3403d730d6bc",
//             "629dd224fe5c3403d730d6bc",
//           ],
//           _id: "62e366fc360ed3ca43ce068b",
//           task_title: "DEFAULT TASK ONE",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86c911ec16b4c24f1e31c",
//             user: "629dd224fe5c3403d730d6bc",
//             user_profile: {
//               _id: "629dd224fe5c3403d730d6bc",
//               first_name: "Done",
//               last_name: "Dev 1",
//               email: "dev1@gmail.com",
//               image: "image/4e816ed0-e581-11ec-b080-a9560f063d68.jpeg",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "admin",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:10:09.952Z",
//             updatedAt: "2022-06-14T11:10:09.952Z",
//             user_status: 1,
//             leave_reason: "",
//           },
//           task_action_by: "admin",
//           created_at: "2022-07-29T04:50:04.581Z",
//           updated_at: "2022-08-01T06:48:41.258Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//       {
//         section_id: "62e366b0360ed3ca43ce0600",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 2,
//           task_status: 2,
//           status: true,
//           order: 5,
//           team: [
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: ["62a7162afe5c3403d73200c9"],
//           _id: "62ee0ce9cdd00704deb56f7c",
//           task_title: "another task for myself",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86cf71ec16b4c24f1e4f0",
//             user: "62a7162afe5c3403d73200c9",
//             user_profile: {
//               first_name: "dev",
//               last_name: "three",
//               email: "dev3@gmail.com",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "team",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:11:51.739Z",
//             updatedAt: "2022-07-29T07:53:38.940Z",
//             user_status: 1,
//             leave_reason: "alksdjfdssdsd",
//           },
//           task_action_by: "team",
//           created_at: "2022-08-06T06:40:41.227Z",
//           updated_at: "2022-08-06T07:14:33.138Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//       {
//         section_id: "62e366b0360ed3ca43ce0600",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 3,
//           task_status: 0,
//           status: true,
//           order: 6,
//           team: [
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: ["62a7162afe5c3403d73200c9"],
//           _id: "62ee0d14cdd00704deb56ff8",
//           task_title: "another for testing",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86cf71ec16b4c24f1e4f0",
//             user: "62a7162afe5c3403d73200c9",
//             user_profile: {
//               first_name: "dev",
//               last_name: "three",
//               email: "dev3@gmail.com",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "team",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:11:51.739Z",
//             updatedAt: "2022-07-29T07:53:38.940Z",
//             user_status: 1,
//             leave_reason: "alksdjfdssdsd",
//           },
//           task_action_by: "team",
//           created_at: "2022-08-06T06:41:24.681Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//     ],
//   },
// ];
// const events = [
//   {
//     date: "2022-08-01",
//     task: [],
//   },
//   {
//     date: "2022-08-02",
//     task: [],
//   },
//   {
//     date: "2022-08-03",
//     task: [],
//   },
//   {
//     date: "2022-08-04",
//     task: [],
//   },
//   {
//     date: "2022-08-05",
//     task: [],
//   },
//   {
//     date: "2022-08-06",
//     task: [
//       {
//         section_id: "62e366bc360ed3ca43ce0668",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 3,
//           task_status: 0,
//           status: true,
//           order: 1,
//           team: [
//             {
//               _id: "62a86cea1ec16b4c24f1e4af",
//               user: "629dd28ffe5c3403d730d6ec",
//               user_profile: {
//                 first_name: "done",
//                 last_name: "dev 2",
//                 email: "dev2@gmail.com",
//                 image: "image/553bbcc0-e8ac-11ec-b080-a9560f063d68.jpeg",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "manager",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:38.291Z",
//               updatedAt: "2022-06-14T11:11:38.291Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: [
//             "629dd224fe5c3403d730d6bc",
//             "629dd28ffe5c3403d730d6ec",
//           ],
//           _id: "62e3670c360ed3ca43ce06d8",
//           task_title: "NEXT TASK ONE",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86c911ec16b4c24f1e31c",
//             user: "629dd224fe5c3403d730d6bc",
//             user_profile: {
//               _id: "629dd224fe5c3403d730d6bc",
//               first_name: "Done",
//               last_name: "Dev 1",
//               email: "dev1@gmail.com",
//               image: "image/4e816ed0-e581-11ec-b080-a9560f063d68.jpeg",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "admin",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:10:09.952Z",
//             updatedAt: "2022-06-14T11:10:09.952Z",
//             user_status: 1,
//             leave_reason: "",
//           },
//           task_action_by: "admin",
//           created_at: "2022-07-29T04:50:20.276Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//       {
//         section_id: "62e366b0360ed3ca43ce0600",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [
//             "image/2dfcce70-1236-11ed-ae9b-c398362370c1.png",
//             "excel/2e4ff780-1236-11ed-ae9b-c398362370c1.xlsx",
//             "ppt/2ec8d100-1236-11ed-ae9b-c398362370c1.pptx",
//             "docx/2f36fc20-1236-11ed-ae9b-c398362370c1.docx",
//             "pdf/2f94d390-1236-11ed-ae9b-c398362370c1.pdf",
//           ],
//           priority: 0,
//           task_status: 2,
//           status: true,
//           order: 1,
//           team: [
//             {
//               _id: "62a86cea1ec16b4c24f1e4af",
//               user: "629dd28ffe5c3403d730d6ec",
//               user_profile: {
//                 first_name: "done",
//                 last_name: "dev 2",
//                 email: "dev2@gmail.com",
//                 image: "image/553bbcc0-e8ac-11ec-b080-a9560f063d68.jpeg",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "manager",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:38.291Z",
//               updatedAt: "2022-06-14T11:11:38.291Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86d001ec16b4c24f1e535",
//               user: "62a71652fe5c3403d73200df",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "four",
//                 email: "dev4@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:12:00.848Z",
//               updatedAt: "2022-06-14T11:12:00.848Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: [
//             "629dd224fe5c3403d730d6bc",
//             "629dd224fe5c3403d730d6bc",
//             "629dd224fe5c3403d730d6bc",
//           ],
//           _id: "62e366fc360ed3ca43ce068b",
//           task_title: "DEFAULT TASK ONE",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86c911ec16b4c24f1e31c",
//             user: "629dd224fe5c3403d730d6bc",
//             user_profile: {
//               _id: "629dd224fe5c3403d730d6bc",
//               first_name: "Done",
//               last_name: "Dev 1",
//               email: "dev1@gmail.com",
//               image: "image/4e816ed0-e581-11ec-b080-a9560f063d68.jpeg",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "admin",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:10:09.952Z",
//             updatedAt: "2022-06-14T11:10:09.952Z",
//             user_status: 1,
//             leave_reason: "",
//           },
//           task_action_by: "admin",
//           created_at: "2022-07-29T04:50:04.581Z",
//           updated_at: "2022-08-01T06:48:41.258Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//       {
//         section_id: "62e366b0360ed3ca43ce0600",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 2,
//           task_status: 2,
//           status: true,
//           order: 5,
//           team: [
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: ["62a7162afe5c3403d73200c9"],
//           _id: "62ee0ce9cdd00704deb56f7c",
//           task_title: "another task for myself",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86cf71ec16b4c24f1e4f0",
//             user: "62a7162afe5c3403d73200c9",
//             user_profile: {
//               first_name: "dev",
//               last_name: "three",
//               email: "dev3@gmail.com",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "team",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:11:51.739Z",
//             updatedAt: "2022-07-29T07:53:38.940Z",
//             user_status: 1,
//             leave_reason: "alksdjfdssdsd",
//           },
//           task_action_by: "team",
//           created_at: "2022-08-06T06:40:41.227Z",
//           updated_at: "2022-08-06T07:14:33.138Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//       {
//         section_id: "62e366b0360ed3ca43ce0600",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 3,
//           task_status: 0,
//           status: true,
//           order: 6,
//           team: [
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: ["62a7162afe5c3403d73200c9"],
//           _id: "62ee0d14cdd00704deb56ff8",
//           task_title: "another for testing",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-06T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86cf71ec16b4c24f1e4f0",
//             user: "62a7162afe5c3403d73200c9",
//             user_profile: {
//               first_name: "dev",
//               last_name: "three",
//               email: "dev3@gmail.com",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "team",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:11:51.739Z",
//             updatedAt: "2022-07-29T07:53:38.940Z",
//             user_status: 1,
//             leave_reason: "alksdjfdssdsd",
//           },
//           task_action_by: "team",
//           created_at: "2022-08-06T06:41:24.681Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//     ],
//   },
//   {
//     date: "2022-08-07",
//     task: [],
//   },
//   {
//     date: "2022-08-08",
//     task: [],
//   },
//   {
//     date: "2022-08-09",
//     task: [],
//   },
//   {
//     date: "2022-08-10",
//     task: [],
//   },
//   {
//     date: "2022-08-11",
//     task: [
//       {
//         section_id: "62e366bc360ed3ca43ce0668",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 3,
//           task_status: 0,
//           status: true,
//           order: 2,
//           team: [
//             {
//               _id: "62a86cea1ec16b4c24f1e4af",
//               user: "629dd28ffe5c3403d730d6ec",
//               user_profile: {
//                 first_name: "done",
//                 last_name: "dev 2",
//                 email: "dev2@gmail.com",
//                 image: "image/553bbcc0-e8ac-11ec-b080-a9560f063d68.jpeg",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "manager",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:38.291Z",
//               updatedAt: "2022-06-14T11:11:38.291Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: [
//             "629dd224fe5c3403d730d6bc",
//             "629dd28ffe5c3403d730d6ec",
//           ],
//           _id: "62e3670f360ed3ca43ce06f6",
//           task_title: "NEXT TASK TWO",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-11T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86c911ec16b4c24f1e31c",
//             user: "629dd224fe5c3403d730d6bc",
//             user_profile: {
//               _id: "629dd224fe5c3403d730d6bc",
//               first_name: "Done",
//               last_name: "Dev 1",
//               email: "dev1@gmail.com",
//               image: "image/4e816ed0-e581-11ec-b080-a9560f063d68.jpeg",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "admin",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:10:09.952Z",
//             updatedAt: "2022-06-14T11:10:09.952Z",
//             user_status: 1,
//             leave_reason: "",
//           },
//           task_action_by: "admin",
//           created_at: "2022-07-29T04:50:23.579Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//     ],
//   },
//   {
//     date: "2022-08-12",
//     task: [],
//   },
//   {
//     date: "2022-08-13",
//     task: [],
//   },
//   {
//     date: "2022-08-14",
//     task: [],
//   },
//   {
//     date: "2022-08-15",
//     task: [],
//   },
//   {
//     date: "2022-08-16",
//     task: [],
//   },
//   {
//     date: "2022-08-17",
//     task: [],
//   },
//   {
//     date: "2022-08-18",
//     task: [],
//   },
//   {
//     date: "2022-08-19",
//     task: [
//       {
//         section_id: "62e366bc360ed3ca43ce0668",
//         project_id: "62e366b0360ed3ca43ce05fe",
//         project_title: "Project ONE",
//         workspace_id: "62a86c911ec16b4c24f1e319",
//         task: {
//           image: [],
//           priority: 3,
//           task_status: 0,
//           status: true,
//           order: 3,
//           team: [
//             {
//               _id: "62a86cea1ec16b4c24f1e4af",
//               user: "629dd28ffe5c3403d730d6ec",
//               user_profile: {
//                 first_name: "done",
//                 last_name: "dev 2",
//                 email: "dev2@gmail.com",
//                 image: "image/553bbcc0-e8ac-11ec-b080-a9560f063d68.jpeg",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "manager",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:38.291Z",
//               updatedAt: "2022-06-14T11:11:38.291Z",
//               user_status: 1,
//               leave_reason: "",
//             },
//             {
//               _id: "62a86cf71ec16b4c24f1e4f0",
//               user: "62a7162afe5c3403d73200c9",
//               user_profile: {
//                 first_name: "dev",
//                 last_name: "three",
//                 email: "dev3@gmail.com",
//                 status: true,
//               },
//               workspace: "62a86c911ec16b4c24f1e319",
//               role: "team",
//               allow_drag_drop: false,
//               allow_add_project: false,
//               createdAt: "2022-06-14T11:11:51.739Z",
//               updatedAt: "2022-07-29T07:53:38.940Z",
//               user_status: 1,
//               leave_reason: "alksdjfdssdsd",
//             },
//           ],
//           team_assign_by: [
//             "629dd224fe5c3403d730d6bc",
//             "629dd28ffe5c3403d730d6ec",
//           ],
//           _id: "62e36714360ed3ca43ce0716",
//           task_title: "NEXT TASK THREE",
//           task_description: "",
//           task_type: "0",
//           end_date: "2022-08-19T00:00:00.000Z",
//           task_action_id: {
//             _id: "62a86c911ec16b4c24f1e31c",
//             user: "629dd224fe5c3403d730d6bc",
//             user_profile: {
//               _id: "629dd224fe5c3403d730d6bc",
//               first_name: "Done",
//               last_name: "Dev 1",
//               email: "dev1@gmail.com",
//               image: "image/4e816ed0-e581-11ec-b080-a9560f063d68.jpeg",
//               status: true,
//             },
//             workspace: "62a86c911ec16b4c24f1e319",
//             role: "admin",
//             allow_drag_drop: false,
//             allow_add_project: false,
//             createdAt: "2022-06-14T11:10:09.952Z",
//             updatedAt: "2022-06-14T11:10:09.952Z",
//             user_status: 1,
//             leave_reason: "",
//           },
//           task_action_by: "admin",
//           created_at: "2022-07-29T04:50:28.126Z",
//           role: "team",
//         },
//         task_comment_count: 0,
//       },
//     ],
//   },
//   {
//     date: "2022-08-20",
//     task: [],
//   },
//   {
//     date: "2022-08-21",
//     task: [],
//   },
//   {
//     date: "2022-08-22",
//     task: [],
//   },
//   {
//     date: "2022-08-23",
//     task: [],
//   },
//   {
//     date: "2022-08-24",
//     task: [],
//   },
//   {
//     date: "2022-08-25",
//     task: [],
//   },
//   {
//     date: "2022-08-26",
//     task: [],
//   },
//   {
//     date: "2022-08-27",
//     task: [],
//   },
//   {
//     date: "2022-08-28",
//     task: [],
//   },
//   {
//     date: "2022-08-29",
//     task: [],
//   },
//   {
//     date: "2022-08-30",
//     task: [],
//   },
//   {
//     date: "2022-08-31",
//     task: [],
//   },
//   ,
// ];
