import React, { useState } from "react";
import { logmodeis } from "src/config/config";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Container, Typography } from "@mui/material";
//

import CryptoJS from "crypto-js";
import { login } from "../DAL/Login/Login";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
// ----------------------------------------------------------------------
const SECRET_KEY = "cristianoRonaldoIsTheGreatestPlayerOfAllTime";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const handleSubmitLogin = async (data) => {
    setLoading(true);
    if(process.env.NODE_ENV == logmodeis){console.log(data, "get login data");}
    const result = await login(data);
    if(process.env.NODE_ENV == logmodeis){console.log(result, "login result");}
    if (result.code === 200) {
      const data = result.token;
      const dataString = JSON.stringify(data);
      const encryptedData = CryptoJS.AES.encrypt(
        dataString,
        SECRET_KEY
      ).toString();
      if(process.env.NODE_ENV == logmodeis){console.log(encryptedData, "encryptedData--");}

      if (process.env.NODE_ENV !== "production") {
        localStorage.setItem("plainToken", result.token);
      }
      localStorage.setItem("token", encryptedData);
      localStorage.setItem("user-profile", JSON.stringify(result.user_profile));
      localStorage.setItem("first_name", result.user_profile.first_name);
      localStorage.setItem("last_name", result.user_profile.last_name);
      localStorage.setItem("user_id", result.user_profile.user_id);
      localStorage.setItem("image", result.user_profile.image);
      localStorage.setItem("user-status", result.user_profile.status);
      localStorage.setItem(
        "allow_check_comments",
        result.user_profile.allow_check_comments
      );
      navigate("/dashboard", { replace: true });
      setLoading(false);
    } else {
      if (result.message === "User Is Inactive") {
        enqueueSnackbar(
          "Your account has been disabled please contact support",
          { variant: "error" }
        );
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        if(process.env.NODE_ENV == logmodeis){console.log("else---");}
      }
      setLoading(false);
    }
  };
  return (
    <RootStyle title="Login | Support Task Portal">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Dynamite Digital Task Portal
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Enter your details below.
            </Typography>
          </Stack>

          <LoginForm isLoading={loading} onhandleLogin={handleSubmitLogin} />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
