import PropTypes from "prop-types";
import { logmodeis } from "src/config/config";
// material
import { Grid, TablePagination } from "@mui/material";
import ProjectCard from "./ProjectCard";
import SearchNotFound from "../../SearchNotFound";
import { useState } from "react";

// ----------------------------------------------------------------------

ProjectList.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default function ProjectList({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalPagesOfProjects,
  projects,
  buttonOption,
  onDeleteProject,
  dragDisabled,
  isDeletingProject,
  afterSort,
  ...other
}) {
  const [dragStart, setDragStart] = useState(false);
console.log(projects,"projects---")
console.log(buttonOption,"buttonOption---")
{console.log(totalPagesOfProjects,"count----")}
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDragEnd = (result) => {
    if (process.env.NODE_ENV == logmodeis) {
      console.log(result, "drag-end");
    }
    const active = result.active;
    const over = result.over;
    if (!active || !over) {
      return;
    }
    const active_index = active.data.current.sortable.index;
    const over_index = over.data.current.sortable.index;
    if (active_index === over_index) {
      return;
    }
    // else sort the array
    if (process.env.NODE_ENV == logmodeis) {
      console.log(active_index, "active_index");
      console.log(over_index, "over_index");
    }
    let sorted = sortArrayItems({
      destination_index: over_index,
      source_index: active_index,
      data: projects,
    });
    // console.log(sorted, "sorted");
    afterSort(sorted);
  };
  const sortArrayItems = ({
    destination_index = 0,
    source_index = 0,
    data = [],
  }) => {
    let spliced_items = [...data];

    const [removed_item] = spliced_items.splice(source_index, 1);
    spliced_items.splice(destination_index, 0, removed_item);

    return spliced_items;
  };
  
  return (
    <div {...other}>
      <Grid container spacing={3} style={{ marginBottom: "10px" }}>
        {projects.length > 0 ? (
          projects.map((project) => {
            return (
              <>
              {console.log(project,"project---")}
              <Grid key={project.id} item xs={12} sm={6} md={3}>
                <ProjectCard
                  isDeletingProject={isDeletingProject}
                  project={project}
                  onDeleteProject={onDeleteProject}
                />
              </Grid></>
            );
          })
        ) : (
          <Grid item xs={12}>
            <SearchNotFound />
          </Grid>
        )}
      </Grid>
      {buttonOption.all == true ? 
      <TablePagination className="pagination-wrapper"
        rowsPerPageOptions={[8, 25, 50]}
        component="div"
        count={totalPagesOfProjects.all_projects_count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> : ""
    }
    {buttonOption.team == true ? 
      <TablePagination className="pagination-wrapper"
        rowsPerPageOptions={[8, 25, 50]}
        component="div"
        count={totalPagesOfProjects.team_projects_count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> : ""
    }
    {buttonOption.personal == true ? 
      <TablePagination className="pagination-wrapper"
        rowsPerPageOptions={[8, 25, 50]}
        component="div"
        count={totalPagesOfProjects.personal_projects_count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> : ""
    }
      
    </div>
  );
}
// function SortableItem(props) {
//   const item = props.item;
//   const {
//     transform,
//     transition,
//     setDraggableNodeRef,
//     setDroppableNodeRef,
//     setNodeRef,
//     listeners,
//     attributes,
//     data,
//     isSorting,
//     isOver,
//     over,
//     newIndex,
//     overIndex,
//     isDragging,
//   } = useSortable({ id: props.id });

//   const style = {
//     transform: CSS.Transform.toString(transform),
//     transition,
//   };
//   return (
//     <Grid
//       item
//       xs={12}
//       sm={6}
//       md={3}
//       sx={{ zIndex: isDragging ? "2" : "1" }}
//       // style={{ padding: "0px" }}
//     >
//       <div ref={setNodeRef}>
//         <div
//           {...listeners}
//           {...attributes}
//           style={style}
//           ref={setDraggableNodeRef}
//         >
//           <ProjectCard project={item} />
//         </div>
//       </div>
//     </Grid>
//   );
// }
