import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import PaidIcon from "@mui/icons-material/Paid";
import { logmodeis } from "src/config/config";
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "calendar",
    path: "/calender",
    icon: getIcon("simple-line-icons:calender"),
  },
  {
    title: "Projects",
    path: "/projects",
    icon: getIcon(barchartfill),
  },
  {
    title: "All tasks",
    path: "/all-tasks",
    icon: getIcon("fluent:clipboard-more-20-regular"),
  },
  {
    title: "All completed tasks",
    path: "/all-completed-tasks",
    icon: getIcon("akar-icons:circle-check"),
  },
  {
    title: "Team Chat",
    path: "/chat/chat-messages",
    icon: getIcon("bi:chat-left-dots"),
  },
  // {
  //   title: 'team',
  //   path: '/team',
  //   icon: getIcon(peopleFill)
  // },
  // {
  //   title: 'department',
  //   path: '/department',
  //   icon: getIcon(peopleFill)
  // }
  // {
  //   title: 'product',
  //   path: '/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'blog',
  //   path: '/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // }
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];
if(process.env.NODE_ENV == logmodeis){console.log(localStorage.getItem("allowViewSales"), "first-value-at-sidebar");}
const pushRoute = () => {
  let exist = false;
  sidebarConfig.map((route) => {
    if(process.env.NODE_ENV == logmodeis){console.log(route.path,"route.path")}
    if (String(route.path) === "/all-sales-listing") {
      exist = true;
    }
  });
  if (!exist) {
    if (String(localStorage.getItem("allowViewSales")) === true) {
      sidebarConfig.push({
        title: "All Sales",
        path: "/all-sales-listing",
        icon: <PaidIcon width={22} height={22} />,
      });
      if(process.env.NODE_ENV == logmodeis){console.log(sidebarConfig, "pushed-routes");}
    }
  }
};
// pushRoute();
export default sidebarConfig;
