import dc_token from "../../utils/tokenDecryption"
import { invokeApi } from "../../bl_libs/invokeApi";

export const login = async (data) => {
  const requestObj = {
    path: `api/login`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const logout = async () => {
  const requestObj = {
    path: `api/logout`,
    method: "GET",
    headers: {
      'x-sh-auth': dc_token()
    },
  };
  return invokeApi(requestObj);
};

export const register = async (data) => {
  const requestObj = {
    path: `api/user_registor`,
    method: "POST",
    headers: {
      'x-sh-auth': dc_token()
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const confirmEmail = async (data) => {
  const requestObj = {
    path: `api/email_verification`,
    method: "POST",
    headers: {
      'x-sh-auth': dc_token()
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const confirmPinCode = async (data) => {
  const requestObj = {
    path: `api/code_verification`,
    method: "POST",
    headers: {
      'x-sh-auth': dc_token()
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updatePassword = async (data) => {
  const requestObj = {
    path: `api/reset_password`,
    method: "POST",
    headers: {
      'x-sh-auth': dc_token()
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const profileDetail = async (data) => {
  const requestObj = {
    path: `api/user_profile/detail_profile`,
    method: "GET",
    headers: {
      'x-sh-auth': dc_token()
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const updateUserPassword = async (data) => {
  const requestObj = {
    path: `api/change_password`,
    method: "PUT",
    headers: {
      'x-sh-auth': dc_token()
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const updateProfile = async (data) => {
  const requestObj = {
    path: `api/user_profile/edit_profile`,
    method: "PUT",
    headers: {
      'x-sh-auth': dc_token()
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
