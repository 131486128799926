import PropTypes from "prop-types";
import { logmodeis } from "src/config/config";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import editFill from "@iconify/icons-eva/edit-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import { useSnackbar } from "notistack";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { format } from "date-fns";

// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@mui/material";
import moment from "moment";
//
import { styled } from "@mui/material/styles";

import CkEditor from "src/components/editor/CKEditor";
import Scrollbar from "src/components/Scrollbar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  addSaleNote,
  deleteSaleNote,
  editSaleNote,
  listingSaleNote,
} from "src/DAL/Notes/notes";
import { updateSaleDescription } from "src/DAL/Project/Project";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

EditSaleSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

export default function EditSaleSidebar({
  isOpenEditSaleSidebar,
  onCloseSaleSidebar,
  expanded,
  setExpanded,
  selectedItem,
  getAllSalesListing,
  prevSections,
  sections,
  fetchSalesBySection,
  reRender,
  setReRender,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState(false);
  const [saleDescription, setSaleDescription] = useState("");
  const [questionAns, setQuestionAns] = useState("");
  const [saleTitle, setSaleTitle] = useState("");

  const [notesList, setNotesList] = useState([]);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDescription, setNoteDescription] = useState("");
  const [noteDate, setNoteDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [openNewNote, setOpenNewNote] = useState(false);

  // Grid style
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  // Grid style

  const handleSubmit = async (e) => {
    e.preventDefault();
    prevSections.current = sections;
    setIsUpdating(true);
    if (!selectedItem) return;
    const formData = new FormData();

    formData.append("sale_description", saleDescription);

    formData.append("sale_title", saleTitle);
    if (process.env.NODE_ENV == logmodeis) {
      console.log(...formData, "update-sale-desc-req");
      console.log(selectedItem.id, "saleid--");
    }
    const result = await updateSaleDescription(formData, selectedItem.id);
    if (result.code === 200) {
      setIsUpdating(false);
      enqueueSnackbar("Sale description updated", { variant: "success" });
      onCloseSaleSidebar(true)
      getAllSalesListing();
      setReRender(!reRender)


    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsUpdating(false);
      if (process.env.NODE_ENV == logmodeis) {
        console.log(result, "error");
      }
    }
  };
  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === "Apple Computer, Inc.") {
      is_safari = true;
    }
    return is_safari;
  };
  const getActualTime = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };
  const getPhone = (phone) => {
    if (phone === "") {
      return " - ";
    }
    return phone;
  };

  // add new note
  const handleAddNote = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("sale_id", selectedItem.id);
    formData.append("title", noteTitle);
    formData.append("description", noteDescription);
    formData.append("target_date", noteDate);

    const result = await addSaleNote(formData);

    if (result.code === 200) {
      console.log(result, "result note");
      fetchNoteListing();
      setNoteTitle("");
      setNoteDescription("");
      enqueueSnackbar("Sale note updated", { variant: "success" });
      setIsUpdating(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsUpdating(false);
    }
  };

  // fetch note listing
  const fetchNoteListing = async () => {
    console.log(selectedItem.id, selectedItem, "selectedItem---");
    const result = await listingSaleNote(selectedItem.id);

    if (result.code === 200) {
      console.log(result, "result note-listing");
      setNotesList(result.sale_note_list);
      setIsUpdating(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsUpdating(false);
    }
  };

  const handleDelete = async (id) => {
    const result = await deleteSaleNote(id);

    if (result.code === 200) {
      fetchNoteListing();
      setIsUpdating(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsUpdating(false);
    }
  };
  const handleEdit = async (val, index) => {
    const formData = new FormData();
    formData.append("title", notesList[index].title);
    formData.append("description", notesList[index].description);
    formData.append("target_date", notesList[index].target_date);
    const result = await editSaleNote(formData, notesList[index]._id);

    if (result.code == 200) {
      fetchNoteListing();
      setIsUpdating(false);
      enqueueSnackbar(result.message, { variant: "success" });
      console.log("success----");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsUpdating(false);
      console.log("error----");
    }
  };
  const handleTitle = (e) => {
    setNoteTitle(e.target.value);
  };
  const setTime = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };
  const handleNotesTitleInArray = (e, index) => {
    notesList[index].title = e.target.value;
    setNotesList([...notesList]);
  };
  const handleNotesDateInArray = (e, index) => {
    notesList[index].targetDate = e.target.value;
    setNotesList([...notesList]);
  };
  const handleNotesDescriptionInArray = (e, index) => {
    notesList[index].description = e.target.value;
    setNotesList([...notesList]);
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(async () => {
    if (isOpenEditSaleSidebar) {
      if (selectedItem) {
        setSaleDescription(selectedItem.sale_description);
        setQuestionAns(selectedItem.answers_detail);
        setSaleTitle(selectedItem.sale_title ? selectedItem.sale_title : "");
      }
      fetchNoteListing();
    }
  }, [isOpenEditSaleSidebar]);
  return (
    <>
      <Drawer
        disableEnforceFocus={true}
        anchor="right"
        open={isOpenEditSaleSidebar}
        onClose={onCloseSaleSidebar}
        PaperProps={{
          sx: {
            width: { xs: "100%", sm: 500 },
            border: "none",
            overflow: "hidden",
          },
          // sx: { width: { xs: 340, md: 380 }, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1 }}
        >
          <IconButton onClick={onCloseSaleSidebar}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 2 }}>
            <div>
              {/* update description */}
              <Accordion
                expanded={expanded === "description"}
                onChange={handleChange("description")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Sale Description</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex flex-wrap w-100 align-items-center align-content-center">
                          <div className="mr-3">
                            <Typography
                              component="span"
                              style={{ fontsize: 16, fontWeight: "bold" }}
                            >
                              Name :
                            </Typography>
                            <Typography component="span">
                              {selectedItem &&
                                ` ${selectedItem.first_name} ${selectedItem.last_name}`}
                            </Typography>
                          </div>
                          <div className="mr-3">
                            <Typography
                              component="span"
                              style={{
                                fontsize: 16,
                                fontWeight: "bold",
                                marginTop: 0,
                              }}
                            >
                              Email :
                            </Typography>
                            <Typography component="span">
                              {selectedItem && " " + selectedItem.client_email}
                            </Typography>
                          </div>
                          <div className="mr-3">
                            <Typography
                              component="span"
                              style={{
                                fontsize: 16,
                                fontWeight: "bold",
                                marginTop: 0,
                              }}
                            >
                              Phone :
                            </Typography>
                            <Typography component="span">
                              {selectedItem && getPhone(selectedItem.phone)}
                            </Typography>
                          </div>
                          <div className="mr-3">
                            <Typography
                              component="span"
                              style={{
                                fontsize: 16,
                                fontWeight: "bold",
                                marginTop: 0,
                              }}
                            >
                              Created At :
                            </Typography>
                            <Typography component="span">
                              {selectedItem &&
                                " " + getActualTime(selectedItem.created_at)}
                            </Typography>
                          </div>
                          <div className="mr-3">
                            <Typography
                              component="span"
                              style={{
                                fontsize: 16,
                                fontWeight: "bold",
                                marginTop: 0,
                              }}
                            >
                              Due Date :
                            </Typography>
                            <Typography component="span">
                              {selectedItem && selectedItem.sale_target_date
                                ? " " +
                                  getActualTime(selectedItem.sale_target_date)
                                : " "}
                            </Typography>
                          </div>
                          <Typography
                            style={{ fontsize: 16, fontWeight: "bold" }}
                          >
                            Description :
                          </Typography>
                          <div className="task-description-editor-parent w-100">
                            <CkEditor
                              content={saleDescription}
                              setContent={(value) => setSaleDescription(value)}
                            />
                          </div>
                          <TextField
                            className="mt-3"
                            // multiline
                            // maxRows={4}
                            fullWidth
                            variant="outlined"
                            value={saleTitle}
                            label="Sale Title"
                            onChange={(e) => setSaleTitle(e.target.value)}
                          />
                          <LoadingButton
                            fullWidth
                            size="medium"
                            type="submit"
                            color="primary"
                            variant="outlined"
                            startIcon={<Icon icon={editFill} />}
                            className="mt-2"
                            loading={isUpdating}
                          >
                            Update
                          </LoadingButton>
                        </div>
                      </div>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>

              {/* add notes */}

              <Accordion
                expanded={expanded === "notes"}
                onChange={handleChange("notes")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Notes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {expanded == "notes" && (
                    <>
                      {openNewNote && (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                className="mb-2"
                                fullWidth
                                id="outlined-size-small"
                                size="small"
                                label="Title"
                                value={noteTitle}
                                onChange={(e) => handleTitle(e)}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Item className="p-0">
                                <TextField
                                  fullWidth
                                  size="small"
                                  id="datefrom"
                                  label="Target Date"
                                  type="date"
                                  defaultValue={noteDate}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(e) => setNoteDate(e.target.value)}
                                />
                              </Item>
                            </Grid>
                          </Grid>

                          <TextField
                            fullWidth
                            id="outlined-size-small"
                            label="Description"
                            multiline
                            maxRows={4}
                            value={noteDescription}
                            onChange={(e) => setNoteDescription(e.target.value)}
                          />

                          <div className="d-flex justify-content-center my-3">
                            <LoadingButton
                              size="medium"
                              type="submit"
                              color="primary"
                              variant="outlined"
                              startIcon={<Icon icon={editFill} />}
                              className="mt-2"
                              onClick={(e) => handleAddNote(e)}
                              loading={isUpdating}
                            >
                              Add Note
                            </LoadingButton>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {/* update notes */}
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginBottom: "10px", textAlign: "center" }}
                  >
                    Notes Detail
                  </Typography>
                  {notesList.length > 0 &&
                    notesList.map((val, index) => {
                      return (
                        <>
                          <Card sx={{ minWidth: 275, mb: 3 }}>
                            <CardContent style={{ position: "relative" }}>
                              <CardActions className="d-flex justify-content-between align-items-center p-0">
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  style={{ marginBottom: 0 }}
                                >
                                  Created Date: {setTime(val.createdAt)}
                                </Typography>
                                <Button
                                  size="small"
                                  style={{ color: "red" }}
                                  onClick={(index) => handleDelete(val._id)}
                                >
                                  Delete Note
                                </Button>
                              </CardActions>
                              <Divider
                                className="mt-1 mb-3"
                                style={{
                                  position: "absolute",
                                  left: 0,
                                  right: 0,
                                }}
                              />
                              <Grid container spacing={2}>
                                <Grid item xs={7}>
                                  <TextField
                                    style={{ marginTop: "30px" }}
                                    className="mb-3"
                                    fullWidth
                                    id="outlined-size-small"
                                    size="small"
                                    label="Title"
                                    value={val.title}
                                    onChange={(e) =>
                                      handleNotesTitleInArray(e, index)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <Item
                                    className="d-flexalign-items-center"
                                    style={{ paddingTop: "30px" }}
                                  >
                                    <TextField
                                      fullWidth
                                      size="small"
                                      id="datefrom"
                                      label="Target Date"
                                      type="date"
                                      defaultValue={setTime(val.targetDate)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      onChange={(e) =>
                                        handleNotesDateInArray(e, index)
                                      }
                                    />
                                  </Item>
                                </Grid>
                              </Grid>

                              <TextField
                                fullWidth
                                id="outlined-multiline-flexible"
                                label="Description"
                                multiline
                                maxRows={4}
                                value={val.description}
                                onChange={(e) =>
                                  handleNotesDescriptionInArray(e, index)
                                }
                              />
                              <Button
                                size="small"
                                className="mt-2"
                                onClick={() => handleEdit(val, index)}
                              >
                                Edit Note
                              </Button>
                            </CardContent>
                          </Card>
                        </>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            </div>
          </Stack>
        </Scrollbar>
        {expanded == "notes" && (
          <div className="d-flex justify-content-center">
            <Typography
              variant="h6"
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenNewNote(!openNewNote)}
            >
              Note
              <LibraryAddIcon sx={{ marginLeft: "5px" }} />
            </Typography>
          </div>
        )}
      </Drawer>
    </>
  );
}
