import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DateAdapter from '@mui/lab/AdapterMoment';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Chip,
  CircularProgress,
  DialogContentText,
  Grid,
} from '@mui/material';
import { useSnackbar } from 'notistack';
// import { activeTeamList } from 'src/DAL/Project/Project';
import { chatGroupDetail } from 'src/DAL/chat/chat';
import { baseUri,logmodeis } from 'src/config/config';
import { getManagerTeam } from 'src/DAL/Team/Team';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EditGroupDialog({
  onEditGroup,
  isOpen,
  toggle,
  currentGroup,
  isEditing,
  isDeleting,
}) {

  const [members, setMembers] = React.useState([]);
  const [startTime, setStartTime] = React.useState('');
  const [endTime, setEndTime] = React.useState('');
  const [groupName, setGroupName] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [Image, setImage] = React.useState('');
  const [file, setProfileImage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentGroupDetail, setCurrentGroupDetail] = React.useState({});
  const [membersData, setMembersData] = useState([]);
  const fileChangedHandler = async (e) => {
    if (!e.target) {
      // setProfileImage('');
      return;
    }
    if (!e.target.files) {
      // setProfileImage('');
      return;
    }
    if (!e.target.files[0]) {
      // setProfileImage('');
      return;
    }
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };
  const handleClose = () => {
    setStartTime('');
    setEndTime('');
    setMembers([]);
    setGroupName('');
    setProfileImage('');
    setImage('');
    toggle();
    // setOpen(false);
  };
  const handleChange = (data) => {
    if (process.env.NODE_ENV == logmodeis){console.log(data);}
    setMembers(data);
    // const {
    //   target: { value }
    // } = event;
    // setMembers(
    //   // On autofill we get a the stringified value.
    //   typeof value === 'string' ? value.split(',') : value
    // );
  };
  const handleChangeStartTime = (e) => {
    if (process.env.NODE_ENV == logmodeis){console.log(e.target.value);}
    setStartTime(e.target.value);
  };
  const handleChangeEndTime = (e) => {
    if (process.env.NODE_ENV == logmodeis){console.log(e.target.value);}
    setEndTime(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!currentGroup) {
      return;
    }
    if (members.length < 1) {
      enqueueSnackbar('Please chose at least one member! ', { variant: 'error' });
      return;
    }
    const id = currentGroup.id;
    if (process.env.NODE_ENV == logmodeis){console.log('submit');}
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('group_name', groupName);
    data.append('group_id', id);
    members.map((member, index) => {
      data.append(`team[${index}]`, member.id);
    });
    data.append('chat_start_time', startTime);
    data.append('chat_end_time', endTime);
    if (Image) {
      data.append('group_image', Image);
    }
    // console.log(...data, 'add-group-req');
    onEditGroup(data);
  };
  const getGroupDetail = async () => {
    if (process.env.NODE_ENV == logmodeis){console.log(currentGroup, 'cgrp');}
    if (!currentGroup) {
      return;
    }
    const id = currentGroup.id;
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('group_id', id);
    setIsLoading(true);
    const result = await chatGroupDetail(data);

    if (process.env.NODE_ENV == logmodeis){console.log(result, 'group-detail');}
    if (result.code === 200) {
      const { group_data } = result;
      setIsLoading(false);
      setCurrentGroupDetail({ detail: result.group_data, messages: result.group_message_list });

      setStartTime(group_data.chat_start_time);
      setEndTime(group_data.chat_end_time);
      setMembers(group_data.team);
      setGroupName(group_data.group_name);
      setProfileImage(baseUri + group_data.group_image);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const fetchTeamListing = async () => {
    const formData = new FormData();
      
    const result = await getManagerTeam(formData);
    if (process.env.NODE_ENV == logmodeis){console.log(result, 'active-team');}
    if (result.code === 200) {
      setMembersData(result.Team_member_list);
      if (process.env.NODE_ENV == logmodeis){console.log(result.Team_member_list, 'overall listing');}
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  React.useEffect(() => {
    if (isOpen) {
      getGroupDetail();
      fetchTeamListing();
    }
  }, [isOpen]);
  return (
    <Dialog
      open={isOpen}
      //   onClose={handleClose}
      aria-labelledby='file-dialog-title'
      aria-describedby='file-dialog-description'
      maxWidth='md'
      fullWidth
      // PaperProps={{ sx: { width: { md: '40rem', sm: '30rem', xs: '25rem' } } }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id='file-dialog-title'>Edit Group</DialogTitle>
        <DialogContent>
          <DialogContentText id='file-dialog-description'></DialogContentText>
          <DialogContent>
            {!isLoading ? (
              <Grid gap={3} container maxWidth='sm' mx='auto' justifyContent='center'>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    type='text'
                    value={groupName}
                    variant='outlined'
                    label='Group name'
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id='tags-filled'
                    options={membersData}
                    value={members}
                    // defaultValue={members}
                    getOptionLabel={(option) => `${option.first_name}  ${' '}  ${option.last_name}`}
                    filterSelectedOptions
                    // getOptionSelected={(option, value) => option.id === value.id}
                    // filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, value) => handleChange(value)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant='outlined'
                          label={`${option.first_name}${' '}${option.last_name}`}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant='outlined' label='Select Team Members' />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <TextField
                      required
                      onChange={handleChangeStartTime}
                      id='start-time'
                      label='Chat Start Time'
                      type='time'
                      value={startTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      fullWidth
                      //   sx={{ width: 150 }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <TextField
                      required
                      onChange={handleChangeEndTime}
                      id='end-time'
                      label='Chat End Time'
                      type='time'
                      value={endTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      fullWidth
                      //   sx={{ width: 150 }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  {file ? (
                    <>
                      <img
                        className='m-auto'
                        width='140px'
                        height='140px'
                        src={file}
                        alt='group image'
                      />
                    </>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <input
                    accept='image/png,image/jpeg,image/jpg'
                    hidden
                    id='contained-button-file'
                    multiple
                    type='file'
                    onChange={fileChangedHandler}
                  />
                  <label htmlFor='contained-button-file' style={{ display: 'flex' }}>
                    <Button
                      component='span'
                      fullWidth
                      variant='outlined'
                      color='primary'
                      startIcon={<DriveFolderUploadIcon />}
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>
              </Grid>
            ) : (
              <div className='center-progress'>
                <CircularProgress />
              </div>
            )}
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type='submit'
            loading={isEditing}
            // onClick={() => handleUploadFile()}
          >
            Edit
          </LoadingButton>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
