// material
import { Box, Grid, Container, Typography } from '@mui/material';
import { logmodeis } from 'src/config/config';
import { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import { getUserDashboardTasks, userProfile } from 'src/DAL/Project/Project';
import { TaskListCard } from './components';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useSocket } from 'src/Hooks/socketContext';
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getUserProfileData } = useSocket();

  const [taskData, setTaskData] = useState({
    allTasks: [],
    newTasks: [],
    topPriorityTasks: [],
    targetTodayTasks: [],
    recentlyCompleted: [],
    datePassed: [],
  });
  const getTasks = async () => {
    const formData = new FormData();
      
    const result = await getUserDashboardTasks(formData);
    if(process.env.NODE_ENV == logmodeis){console.log(result, 'result');}
    if (result.code === 200) {
      const {
        task_those_target_date_is_today,
        user_all_task,
        user_new_task,
        user_top_priority_task,
        recent_completed_task,
        pass_target_date_task,
      } = result;
      setTaskData({
        ...taskData,
        allTasks: user_all_task,
        newTasks: user_new_task,
        topPriorityTasks: user_top_priority_task,
        targetTodayTasks: task_those_target_date_is_today,
        recentlyCompleted: recent_completed_task,
        datePassed: pass_target_date_task,
      });
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const getUserProfile = async () => {
    const formData = new FormData();
      
    const result = await userProfile(formData);
    if(process.env.NODE_ENV == logmodeis){console.log(result, 'user-profile');}
    const { user_detail } = result;
    localStorage.setItem('allowDrag', String(user_detail.allow_reorder_task));
    localStorage.setItem('allowViewSales', String(user_detail.sales_option));
    if(process.env.NODE_ENV == logmodeis){console.log(result.user_detail, 'user-profile-dashboard');}
  };

  const handleViewAll = () => {
    //
    if(process.env.NODE_ENV == logmodeis){console.log('view all clicked');}

    navigate('/all-target-date-passed-tasks');
  };
  useEffect(() => {
    if(process.env.NODE_ENV == logmodeis){console.log(getUserProfileData(), 'u-prof');}
    getTasks();
    // getUserProfile();
  }, []);

  return (
    <Page title='Dashboard | Support Task Portal'>
      <Container maxWidth='xl'>
        <Box sx={{ pb: 5 }}>
          <Typography variant='h4'>Hi, Welcome back</Typography>
        </Box>
        <div className='row'>
          <div className='col-12'>{/* <Calender /> */}</div>
          <div className='col-12'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TaskListCard
                  getTasks={getTasks}
                  title={"Today's target date tasks"}
                  data={taskData.targetTodayTasks}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ minHeight: '100%' }}>
                  <TaskListCard
                    getTasks={getTasks}
                    title={'Top priority tasks'}
                    data={taskData.topPriorityTasks}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ minHeight: '100%' }}>
                  <TaskListCard
                    getTasks={getTasks}
                    title={'Target date passed tasks'}
                    data={taskData.datePassed}
                    //
                    customButton={true}
                    customButtonTitle='View All'
                    handleCustomButtonClick={handleViewAll}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ minHeight: '100%' }}>
                  <TaskListCard
                    getTasks={getTasks}
                    title={'Recent tasks'}
                    data={taskData.newTasks}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <TaskListCard
                  getTasks={getTasks}
                  title={'Recently completed tasks'}
                  data={taskData.recentlyCompleted}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid container spacing={3}></Grid>
      </Container>
    </Page>
  );
}
