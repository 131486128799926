import * as React from "react";
import { logmodeis } from "src/config/config";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";

export default function ConfirmDeleteComment({
  handleDelete,
  commentObj,
  commentsData,
  setCommentData,
  isDeleteDialogOpen,
  handleDeleteDialogOpen,
  setAnchorEl,
  isDeletingComment,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    handleDeleteDialogOpen(true);
  };
  const handleDeleteComment = () => {
    setAnchorEl(null);
    handleDelete();
  };
  const handleClose = () => {
    if (process.env.NODE_ENV == logmodeis) {
      console.log(commentObj);
      console.log(commentsData);
    }
    setAnchorEl(null);
    handleDeleteDialogOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete comment"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete your comment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isDeletingComment}
            onClick={handleDeleteComment}
          >
            Yes
          </LoadingButton>
          <Button
            onClick={handleClose}
            //    autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
