import React, { useState } from "react";
import {
  Container,
  // Tooltip,
  Typography,
  IconButton,
  Box,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Menu,
  Input,
  TextField,
  Select,
  InputLabel,
  FormControl,
  FormLabel,
  Switch,
  Stack,
  Link,
  Fade,
} from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import moment from 'moment';
import {
  // formatISO,
  format,
  parseISO,
  // isToday,
  // setHours,
  // setMinutes,
} from "date-fns";
import {
  formatInTimeZone,
} from "date-fns-tz";
import { useSnackbar } from "notistack";
import { deleteNotes } from "src/DAL/Project/Project";
import { css } from "@emotion/css";
import { set_blank_target } from "src/utils/DomUtils";
import { styled } from '@mui/material/styles';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const longText = `
Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.
Praesent non nunc mollis, fermentum neque at, semper arcu.
Nullam eget est sed sem iaculis gravida eget vitae justo.
`;

export default function NoteLayout({ data, index, deletenote }) {
  const [mydata, setmydata] = useState(data);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Deletenotes = async (id, index) => {
    const formData = new FormData();
    formData.append("_id", id);
    const result = await deleteNotes(formData);
    if (result.code === 200) {
      // setdataNotes(result.note_list)
      enqueueSnackbar("Note Deleted Successfully", { variant: "success" });
      deletenote(id);
      // let newarray=[mydata]
      // newarray.splice(index,1)
      // setmydata(...newarray)
      // navigate(-1)
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleOpenTooltip = (e, sale) => {
    setTimeout(() => {
      set_blank_target("notes-html-description");
    }, 200);
  };
  const getTooltipHtml = (list) => {
    if (list.description !== "") {
      return { __html: list.description };
    }
  };

  return (
    <Tooltip
    onOpen={(e) => handleOpenTooltip(e)}
    title={
      <div id="task-description-tooltip">
        <div
          id="notes-description-html"
          dangerouslySetInnerHTML={getTooltipHtml(mydata)}
        ></div>
      </div>
    }
    arrow
  >
    <div
      key={mydata._id}
      // onClick={() => navigate("/Notes/AddNotes")}
      style={{
        backgroundColor: "#FFFFF4",
        height: 200,
        width: 240,
        overflow: "hidden",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: 10,
        padding: 10,
        position: "relative",
      }}
    >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#0000",
            // justifyContent:'flex-end' ,
            // alignItems:'flex-end',
            position: "absolute",
            top: 2,
            right: 6,
          }}
        >
          <div style={{}}>
            <Icon onClick={handleClick} icon="ph:dots-three-vertical-bold" />
          </div>
        </div>
<div
      onClick={() =>
        navigate("/Notes/AddNotes", {
          state: {
            data: mydata,
            comefrom: "Nview",
          },
        })
      }
>

        <div
          onClick={() =>
            navigate("/Notes/AddNotes", {
              state: {
                data: mydata,
                comefrom: "Nview",
              },
            })
          }
          style={{
            fontFamily: "Montserrat,sans-serif",
            fontWeight: "bold",
            fontSize: 13,
            cursor: "pointer",
            width: "90%",
            // backgroundColor: "red",
          }}
        >
          <span
            className={css`
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            `}
          >
            {mydata.title}
          </span>
        </div>

        <div
          style={{
            fontFamily: "Montserrat,sans-serif",
            flexGrow: 1,
            color: "gray",
            fontSize: 11,
          }}
        >
          {formatInTimeZone(new Date(mydata.updatedAt),timeZone,"dd-MMM-yyyy")}
        </div>

        <div
          onClick={() =>
            navigate("/Notes/AddNotes", {
              state: {
                data: mydata,
                comefrom: "Nview",
              },
            })
          }
          style={{
            fontFamily: "Montserrat,sans-serif",
            color: "gray",
            fontSize: 12,
            cursor: "pointer",
            display: "flex",
            marginTop: 5,
            // height: "50%",
          }}
        >
          <span
            className={css`
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5;
              overflow: hidden;
            `}
          >
            {mydata.description.replace(/(&nbsp;|<([^>]+)>)/gi, "").trim()}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#0000",
            justifyContent: "flex-end",
            position: "absolute",
            bottom: 0,
            right: 10,
            cursor: "pointer",
          }}
          onClick={() =>
            navigate("/Notes/AddNotes", {
              state: {
                data: mydata,
                comefrom: "Nview",
              },
            })
          }
        >
          <p
            style={{
              fontFamily: "Montserrat,sans-serif",
              color: "gray",
              fontSize: 11,
            }}
          >
            Target Date is :&nbsp;
          </p>
          <div
            style={{
              fontFamily: "Montserrat,sans-serif",
              color: "gray",
              fontSize: 11,
            }}
          >
          {moment(mydata.target_date, "YYYY-MM-DD").format('DD, MMM YYYY')}
          {' - '}
          {moment(mydata.target_time, 'h:mm a').format('h:mm a')}
          </div>
        </div>
        </div>

      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          style={{ fontSize: 10 }}
          onClick={() =>
            navigate("/Notes/AddNotes", {
              state: {
                data: mydata,
                comefrom: "edit",
              },
            })
          }
        >
          Edit
        </MenuItem>
        <MenuItem
          style={{ color: "red", fontSize: 10 }}
          onClick={() => Deletenotes(mydata._id, index)}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
    </Tooltip>

  );
}
