import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import chevronUpFill from '@iconify/icons-eva/chevron-up-fill';
import chevronDownFill from '@iconify/icons-eva/chevron-down-fill';
// material
import { Menu, Button, MenuItem, Typography } from '@mui/material';
import { logmodeis } from "src/config/config";


// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: 'none', label: 'none', type: 'default' },
  { value: 'oldest', label: 'Oldest First', type: 'date' },
  { value: 'newest', label: 'Newest First', type: 'date' },
  { value: 'priorityDesc', label: 'Priority: High-Low', type: 'priority' },
  { value: 'priorityAsc', label: 'Priority: Low-High', type: 'priority' },
  {
    value: 'priorityAsc',
    label: 'Pick last project from array instead of first.',
    type: 'priority',
  },
];
export default function SectionSelect({
  data,
  onChange,
  selectedIndexPublic,
  setSelectedIndexPublic,
  selectedIndexPrivate,
  setSelectedIndexPrivate,
  isTaskPersonal,
  setSelectedSection,
}) {
  const [open, setOpen] = useState(null);
  const [title, setTitle] = useState('');
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const handleMenuItemClick = async (event, index) => {
    if (process.env.NODE_ENV == logmodeis){console.log('isindex', index);}
    if (isTaskPersonal) {
      setSelectedIndexPrivate(index);
      let text = getText(index);
      setTitle(text);
      if (process.env.NODE_ENV == logmodeis){console.log(text, 'at-item  ');}
    } else if (!isTaskPersonal) {
      setSelectedIndexPublic(index);
      let text = getText(index);
      setTitle(text);
      if (process.env.NODE_ENV == logmodeis){console.log(text, 'at-item  ');}
    }
    setOpen(null);
    onChange(data[index]);
  };
  const isSelected = (index) => {
    let selected = false;
    if (isTaskPersonal) {
      if (index === selectedIndexPrivate) {
        selected = true;
        return selected;
      }
    }
    if (!isTaskPersonal) {
      if (index === selectedIndexPublic) {
        selected = true;
        return selected;
      }
    }
  };
  const truncateText = (string, maxLength) => {
    if (maxLength == string.length) {
      return string;
    }
    if (string.length > maxLength) {
      let result = string.slice(0, maxLength).concat(' ' + '....');
      if (process.env.NODE_ENV == logmodeis){console.log(result, 'conc');}
      return result;
    }
    return string;
  };
  const getText = (index) => {
    if (process.env.NODE_ENV == logmodeis){console.log(index, 'at-get');}
    if (index === -1) {
      return '';
    }
    const text = data[index].task;
    return truncateText(text, 20);
  };
  useEffect(() => {
    if (process.env.NODE_ENV == logmodeis){console.log(data, 'data--');}
  }, [data]);
  useEffect(() => {
    setTitle('');
    setSelectedIndexPrivate(0);
    setSelectedIndexPublic(0);
  }, [isTaskPersonal]);
  React.useEffect(() => {
    if (isTaskPersonal) {
      setSelectedSection(data[selectedIndexPrivate]);
    }
    if (!isTaskPersonal) {
      setSelectedSection(data[selectedIndexPublic]);
    }
  }, [selectedIndexPublic, selectedIndexPrivate]);
  return (
    <>
      <Button
        color='inherit'
        disableRipple
        onClick={handleOpen}
        sx={{
          backgroundColor: '#f6f7f8',
          width: '100%',
          overflow: 'hidden',
          height: '52px',
          padding: '2px',
          overflow: 'hidden',
        }}
        endIcon={<Icon color='#00ab55' icon={open ? chevronUpFill : chevronDownFill} />}
      >
        <Typography
          component='span'
          sx={{ color: '#00ab55', fontWeight: '600', textTransform: 'none' }}
        >{data.length <= 0 && 'Choose Section'}
        </Typography>
        <Typography
          component='span'
          overflow='hidden'
          variant='subtitle2'
          sx={{ color: 'text.secondary', textTransform: 'none' }}
        >
          {isTaskPersonal && <> {data.length > 0 && data[selectedIndexPrivate].task} </>}
          {!isTaskPersonal && <> {data.length > 0 && data[selectedIndexPublic].task} </>}
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {data.length > 0 ? (
          <span>
            {data.map((option, index) => (
              <MenuItem
                key={option.task_id}
                sx={{ typography: 'body2' }}
                selected={isSelected(index)}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {truncateText(option.task, 30)}
              </MenuItem>
            ))}
          </span>
        ) : (
          <MenuItem
            disabled
            sx={{ typography: 'body2' }}
          >
            No sections yet
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
