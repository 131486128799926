import dc_token from "../../utils/tokenDecryption"
import { invokeApi } from 'src/bl_libs/invokeApi';

export const createGroup = async (data) => {
  const requestObj = {
    path: `api/chat_group/add_chat_group`,
    method: 'POST',
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const chatGroupList = async (data) => {
  const requestObj = {
    path: `api/chat_group/chat_group_list_for_team`,
    // path: `api/chat_group/chat_group_list`,
    method: 'GET',
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const readGroupMessages = async (chat_id) => {
  const requestObj = {
    path: `api/chat_group/user_group_messages_mark_as_read/${chat_id}`,
    // path: `api/chat_group/chat_group_list`,
    method: 'GET',
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteMessage = async (data,chat_id) => {
  const requestObj = {
    path: `api/chat/delete_message/${chat_id}`,
    method: 'DELETE',
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getChatTeam = async (data) => {
  const requestObj = {
    path: `api/manager_team/get_manager_team_for_chat`,
    method: 'GET',
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const setMessageReaction = async (data) => {
  const requestObj = {
    path: `api/chat/add_message_reaction`,
    method: 'POST',
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const unreadMessagesCount = async (data) => {
  const requestObj = {
    path: `api/chat/user_unread_messages`,
    method: 'GET',
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const readDirectMessages = async (chat_id) => {
  const requestObj = {
    path: `api/chat/user_messages_mark_as_read/${chat_id}`,
    // path: `api/chat_group/chat_group_list`,
    method: 'GET',
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const chatGroupsList = async (data) => {
  const requestObj = {
    path: `api/chat_group/group_list`,
    method: 'POST',
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const chatGroupDetail = async (data) => {
  const requestObj = {
    path: `api/chat_group/chat_group_detail`,
    method: 'POST',
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailWithMessages = async (group_id) => {
  const requestObj = {
    path: `api/chat_group/chat_group_detail_with_messages/${group_id}`,
    method: 'GET',
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const detailDirectMessages = async (chat_id) => {
  const requestObj = {
    path: `api/chat/chat_details/${chat_id}`,
    method: 'GET',
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const sendDirectMessage = async (data) => {
  const requestObj = {
    path: `api/chat/add_message`,
    method: 'POST',
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteDirectMessageChat = async (data) => {
  const requestObj = {
    path: `api/chat/delete_chat`,
    method: 'POST',
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteChatGroup = async (data) => {
  const requestObj = {
    path: `api/chat_group/delete_chat_group`,
    method: 'POST',
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editChatGroup = async (data) => {
  const requestObj = {
    path: `api/chat_group/edit_chat_group`,
    method: 'POST',
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const sendGroupChatMessage = async (data) => {
  const requestObj = {
    path: `api/chat_group/add_message`,
    method: 'POST',
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const initialChatMessage = async (data) => {
  const requestObj = {
    path: `api/chat/initiallize_chat`,
    method: 'POST',
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
