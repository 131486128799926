import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Button,
  IconButton,
  Avatar,
  AvatarGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { baseUri, s3baseUrl } from "../../../config/config";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

const ProjectImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

// ----------------------------------------------------------------------

ProjectCard.propTypes = {
  product: PropTypes.object,
};

export default function ProjectCard({
  project,
  onDeleteProject,
  isDeletingProject,
}) {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [projectData, setProjectData] = React.useState({});
  // const { id, name, cover } = project;

  const handleOpenDialog = (data) => {
    setOpenDialog(true);
    setProjectData(data);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <Card>
        <Box sx={{ pt: "100%", position: "relative" }}>
          {localStorage.getItem("user_id") == project.team_id && (
            <Menu
              key="left"
              direction="right"
              align="center"
              position="anchor"
              viewScroll="auto"
              menuButton={
                <IconButton
                  style={{
                    zIndex: 9,
                    top: 10,
                    right: 9,
                    position: "absolute",
                  }}
                >
                  <MoreVertIcon
                    style={{
                      border: "1px solid black",
                      borderRadius: "50%",
                      color: "black",
                    }}
                  />
                </IconButton>
              }
            >
              <MenuItem
                onClick={() => navigate(`/projects/edit-project/${project.id}`)}
                //   props.history.push({
                //     pathname: `/projects/edit_project/${x._id}`,
                //   })
                // }
              >
                <span style={{ fontSize: "12px" }}>Edit</span>
              </MenuItem>
              <MenuItem onClick={() => handleOpenDialog(project)}>
                {" "}
                <span style={{ fontSize: "12px" }}>Delete</span>
              </MenuItem>
            </Menu>
          )}
          {/* <Link color="inherit" underline="hover" component={RouterLink}> */}
          {project.project_image ? (
            <ProjectImgStyle
              // alt={name}
              style={{ cursor: "pointer" }}
              src={s3baseUrl + project.project_image}
              className="image-hov"
              onClick={() =>
                navigate(`/projects/project/${project.id}`, { state: project })
              }
            />
          ) : (
            <ProjectImgStyle
              // alt={name}
              style={{ cursor: "pointer" }}
              src="https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg"
              className="image-hov"
              onClick={() =>
                navigate(`/projects/project/${project.id}`, { state: project })
              }
            />
          )}
          {/* <Avatar className="image-hov">N</Avatar> */}
          {/* </Link> */}
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          {/* <Link color="inherit" underline="hover" component={RouterLink}> */}
          <Typography
            variant="subtitle2"
            noWrap
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/projects/project/${project.id}`, { state: project })
            }
          >
            {project.project_name}
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* <ColorPreview colors={colors} /> */}
            <AvatarGroup max={3}>
              {project.member_info.map((x, i) => (
                <span key={i}>
                  {x.image ? (
                    <Avatar
                      src={s3baseUrl + x.image}
                      style={{ width: 30, height: 30 }}
                    />
                  ) : (
                    <Avatar style={{ width: 30, height: 30 }}>
                      {x.first_name ? x.first_name.substring(0, 1) : ""}
                    </Avatar>
                    // <Avatar src={baseUri + x.image} style={{ width: 30, height: 30 }} />
                  )}
                </span>
              ))}
            </AvatarGroup>
            <Typography variant="subtitle1">
              {project.completed_task}/{project.all_task}
            </Typography>
          </Stack>
        </Stack>
      </Card>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          Are you sure you want to delete this project ?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <LoadingButton
            loading={isDeletingProject}
            onClick={() => onDeleteProject(projectData.id)}
          >
            Yes, Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
