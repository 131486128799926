export const status_color_and_value_old = [
  {
    value: 0,
    label: "Pending",
    color: "#f5c900",
  },
  {
    value: 1,
    label: "In Progress",
    color: "#68aefc",
  },
  //value:2 for in-review, currently not being used.
  {
    value: 3,
    label: "Live Completed",
    color: "#aabb5d",
  },
  {
    value: 6,
    label: "Dev Completed",
    color: "#522e92",
    // color: '#1976d2',
  },
  {
    value: 4,
    label: "Cancelled",
    color: "#960031",
  },
  {
    value: 5,
    label: "Verified",
    color: "#51d821",
    // color: '#1976d2',
  },
];

export const status_color_and_value = [
  {
    value: 0,
    label: "Pending",
    color: "#f5c900",
  },
  {
    value: 1,
    label: "In Progress",
    color: "#000",
  },
  // {
  //   value: 2,
  //   label: "In Reviewe",
  //   color: "#a52cdddc",
  // },
  //value:2 for in-review, currently not being used.
  {
    value: 4,
    label: "Cancelled",
    color: "#960031",
  },
  {
    value: 6,
    label: "Dev Completed",
    color: "#3792cb",
    // color: '#1976d2',
  },
  {
    value: 8,
    label: "QA Verified(dev)",
    color: "#8C8B10",
  },
  {
    value: 7,
    label: "Dev Verified",
    color: "#f96300",
    // color: '#1976d2',
  },
  {
    value: 3,
    label: "Live Completed",
    color: "#51d821",
  },
  {
    value: 5,
    label: "Live Verified",
    color: '#EC00FF',
  },
  
];
export const priority_color_and_value = [
  {
    value: 0,
    label: "Critical",
    color: "#FF4842",
    // color: 'error',
  },
  {
    value: 1,
    label: "High",
    color: "#ffd500",
    // color: '#fdd10b',
  },
  {
    value: 2,
    label: "Medium",
    color: "#54D62C",
    // color: 'success',
  },
  {
    value: 3,
    label: "Low",
    color: "#969292",
  },
];
export const paid_status_color_and_value = [
  {
    value: 0,
    label: "Unpaid",
    color: "#969292",
    // color: 'error',
  },
  {
    value: 1,
    label: "Paid",
    color: "#54D62C",
    // color: 'error',
  },
];
export const SORT_BY_OPTIONS = [
  { value: "0", label: "Default", type: "default" },
  { value: "1", label: "Oldest First", type: "date" },
  { value: "2", label: "Newest First", type: "date" },
  { value: "3", label: "Priority: High-Low", type: "priority" },
  { value: "4", label: "Priority: Low-High", type: "priority" },
  { value: "5", label: "Due Date", type: "date" },
];

export const REACTION_ICONS = [
  { icon: "emojione:smiling-face-with-heart-eyes", key: "1", label: "lovely" },
  { icon: "emojione:face-with-tears-of-joy", key: "2", label: "laugh" },
  { icon: "emojione:slightly-smiling-face", key: "3", label: "smile" },
  { icon: "noto-v1:thumbs-up", key: "4", label: "thumbs up" },
  { icon: "noto-v1:thumbs-down", key: "5", label: "thumbs down" },
  { icon: "emojione:face-with-steam-from-nose", key: "6", label: "angry" },
  { icon: "emojione:stopwatch", key: "7", label: "waiting" },
  { icon: "emojione-v1:left-check-mark", key: "7", label: "verified" },
  { icon: "emojione:frowning-face", key: "7", label: "sad" },
  { icon: "emojione:red-heart", key: "7", label: "heart" },
];
export const FILE_ACCEPT_TYPE_STRING =
  "video/*,audio/*,video/webm,video/x-matroska,image/png,image/jpeg,image/jpg,text/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
/*  */
export const ALLOWED_TASK_FILES_WITH_ICONS = [
  {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml",
    icon: "ant-design:file-excel-outlined",
    color: "#1c6c40",
    baseTypes: ["excel"],
  },
  {
    type: "application/msword",
    icon: "ant-design:file-word-outlined",
    color: "#2b5592",
    baseTypes: ["word", "docx"],
  },
  {
    type: "application/pdf",
    icon: "ant-design:file-pdf-outlined",
    color: "#bb0806",
    baseTypes: ["pdf"],
  },
  {
    type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    icon: "ant-design:file-ppt-outlined",
    color: "#c05535",
    baseTypes: ["ppt"],
  },
  {
    type: "text/csv",
    icon: "iwwa:file-csv",
    color: "#58936e",
    baseTypes: ["csv"],
  },
  {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    icon: "ant-design:file-excel-outlined",
    color: "#1c6c40",
    baseTypes: ["excel"],
  },
  {
    type: "application/vnd.ms-excel",
    icon: "ant-design:file-excel-outlined",
    color: "#1c6c40",
    baseTypes: ["excel"],
  },
  {
    type: "application/vnd.ms-powerpoint",
    icon: "ant-design:file-ppt-outlined",
    color: "#c05535",
    baseTypes: ["ppt"],
  },
  {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    icon: "ant-design:file-word-outlined",
    color: "#2b5592",
    baseTypes: ["word"],
  },
  {
    type: "text/pdf",
    icon: "ant-design:file-pdf-outlined",
    color: "#bb0806",
    baseTypes: ["pdf"],
  },
];
