import React from 'react';
import { Icon } from '@iconify/react';
import Emojis from './Emojis';
import HtmlTooltip from './HtmlTooltip';
import { ClickAwayListener } from '@mui/material';
const Reaction = ({ onEmojiClick, toggleTooltip, isTooltipOpen }) => {
  return (
    <HtmlTooltip
      open={isTooltipOpen}
      title={
        <React.Fragment>
          <Emojis onEmojiClick={onEmojiClick} />
        </React.Fragment>
      }
    >
      <span onClick={() => toggleTooltip(true)} className='reaction-parent'>
        <ClickAwayListener onClickAway={() => toggleTooltip(false)}>
          <div className='reaction-inner'>
            <Icon icon='fluent:emoji-add-24-regular' />
          </div>
        </ClickAwayListener>
      </span>
    </HtmlTooltip>
  );
};

export default Reaction;
