import { invokeApi } from "../../bl_libs/invokeApi";
import dc_token from "../../utils/tokenDecryption"
// done
export const projectListing = async (data,page,limit) => {
  const requestObj = {
    path: `api/projects/client_project_listing?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
// done
export const addProject = async (data) => {
  const requestObj = {
    path: `api/projects/add_project_v1`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const allowedSalesActiveTeam = async (data) => {
  const requestObj = {
    path: `api/team/get_team_manager_team_list`,
    // path: `api/team/get_sale_enable_team_list`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const updateSaleAssociate = async (data) => {
  const requestObj = {
    path: `api/sales/update_associate`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
//
export const activeProjectCategories = async (data,page,limit) => {
  const requestObj = {
    path: `api/manager_team/get_manager_category_by_team?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
// done
export const detailProjectV2 = async (id) => {
  const requestObj = {
    path: `api/projects/detail_project_with_task/${id}`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
// done
export const addProjectTeam = async (data) => {
  const requestObj = {
    path: `api/team_project/add_project_by_team_v1`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const detailProject = async (data) => {
  const requestObj = {
    path: `api/projects/detail_project`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const userProfile = async (data) => {

  const requestObj = {
    path: `api/user_profile/detail_profile`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editProject = async (data) => {
  const requestObj = {
    path: `api/projects/edit_project`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editProjectTeam = async (data) => {
  const requestObj = {
    path: `api/team_project/edit_project_by_team`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteProject = async (data) => {
  const requestObj = {
    path: `api/projects/delete_project`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteProjectTeam = async (data) => {
  const requestObj = {
    path: `api/team_project/delete_project_by_team`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

// export const taskList = async (data) => {
//   const requestObj = {
//     path: `api/task/task_list`,
//     method: 'POST',
//     headers: {},
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };
/* for testing */
export const taskList = async (data) => {
  const requestObj = {
    path: `api/task/task_list_v1`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const verifiedTaskList = async (project_id) => {
  const requestObj = {
    path: `api/task/get_verified_task_list/${project_id}`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const allTeamTasks = async (data,page,limit) => {
  const requestObj = {
    path: `api/task/all_task_list_by_team?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const allCompletedTasks = async (data,page,limit) => {
  const requestObj = {
    path: `api/task/get_completed_task_list?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const allTargetDatePassedTasks = async (data) => {
  const requestObj = {
    path: `api/task/get_pass_target_date_task`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const allSalesListing = async () => {
  const requestObj = {
    path: `api/sales/user_sales_list_new`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const allSalesListingDetail = async (id,page,limit,search) => {
  const requestObj = {
    path: `api/sales/user_section_sales/${id}?page=${page}&limit=${limit}&search=${search}`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const updateOptionalSaleAssociates = async (data,sale_id) => {
  const requestObj = {
    path: `api/sales/update_optional_associate/${sale_id}`,
    method: "PUT",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const saleSectionsListing = async () => {
  const requestObj = {
    path: `api/sale_section/section_sale_list`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleTargetDate = async (data,sale_id) => {
  const requestObj = {
    path: `api/sales/update_sale_target_date/${sale_id}`,
    // path: `api/sales/user_sales_list`,
    method: "PUT",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleStatus = async (data,sale_id) => {
  const requestObj = {
    path: `api/sales/update_sale_completed_status/${sale_id}`,
    // path: `api/sales/user_sales_list`,
    method: "PUT",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleDescription = async (data,sale_id) => {
  const requestObj = {
    path: `api/sales/update_description/${sale_id}`,
    method: "PUT",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const activeSaleSectionListing = async () => {
  const requestObj = {
    path: `api/sale_section/section_sales_list_for_team`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const saleSectionListingForAddSale = async () => {
  const requestObj = {
    path: `api/sale_section/section_sales_list_for_team`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const addSaleByTeam = async (data) => {
  const requestObj = {
    path: `api/sales/add_sale_by_team`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const moveSaleToSection = async (data,sale_id) => {
  const requestObj = {
    path: `api/sales/update_section_id/${sale_id}`,
    method: "PUT",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const activeProductListing = async () => {
  const requestObj = {
    path: `api/product/active_products_list`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const addTask = async (data) => {
  const requestObj = {
    path: `api/task/add_task`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteTask = async (selectedRowTask_id) => {
  const requestObj = {
    path: `api/task/delete_task/${selectedRowTask_id}`,
    method: "DELETE",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const changePriority = async (data) => {
  const requestObj = {
    path: `api/task/change_task_priority`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

// export const changeStatus = async (data) => {
//   const requestObj = {
//     path: `api/task/change_task_status`,
//     method: 'POST',
//     headers: {},
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };
/* for testing */
export const changeStatus = async (data) => {
  const requestObj = {
    path: `api/task/change_task_status_v1`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addTaskMembers = async (data) => {
  const requestObj = {
    path: `api/task/add_member_task`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const markAllCommentsAsRead = async (task_id) => {
  const requestObj = {
    path: `api/task_comment/delete_view_comment/${task_id}`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteTaskMembers = async (data) => {
  const requestObj = {
    path: `api/task/delete_member_task`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addTaskTargetDate = async (data) => {
  const requestObj = {
    path: `api/task/change_target_date`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const taskFilter = async (data) => {
  const requestObj = {
    path: `api/task/task_filter`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const taskFilterTeam = async (data) => {
  const requestObj = {
    path: `api/task/task_filter_for_team`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const taskOrder = async (data,task_id) => {
  const requestObj = {
    path: `api/task/change_task_order/${task_id}`,
    method: "PUT",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const uploadTaskFiles = async (data) => {
  const requestObj = {
    path: `api/task/add_task_files`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const removeTaskFiles = async (data) => {
  const requestObj = {
    path: `api/task/delete_task_files`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const detailTaskFiles = async (task_id) => {
  const requestObj = {
    path: `api/task/task_files_detail/${task_id}`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const changeTaskDetail = async (data,task_id) => {
  const requestObj = {
    path: `api/task/edit_task_text/${task_id}`,
    method: "PUT",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const taskComments = async (task_id) => {
  const requestObj = {
    path: `api/task_comment/comment_list/${task_id}`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const addTaskComments = async (data) => {
  const requestObj = {
    path: `api/task_comment/add_comment`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditComment = async (data,editComment_id) => {
  const requestObj = {
    path: `api/task_comment/edit_task_comment/${editComment_id}`,
    method: "PUT",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteComment = async (comment_id) => {
  const requestObj = {
    path: `api/task_comment/delete_comment/${comment_id}`,
    method: "DELETE",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
/* <<<<<<<<<DASHBOARD>>>>>>>>> */
export const getUserDashboardTasks = async (data) => {
  const requestObj = {
    path: `api/team/user_dashboard`,
    method: "GET",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const uploadEditorImage = async (data) => {
  const requestObj = {
    path: `api/general_image/editor_images`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
/*  */
export const taskListCollapsable = async (data) => {
  const requestObj = {
    path: `api/task/task_list_for_team`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const taskSectionsList = async (data) => {
  const requestObj = {
    path: `api/task_section/task_section_list_for_team`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addTaskSection = async (data) => {
  const requestObj = {
    path: `api/task_section/add_task_section`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateTaskSectionOrder = async (data) => {
  const requestObj = {
    path: `api/task_section/update_task_section_order`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteTaskSection = async (data) => {
  const requestObj = {
    path: `api/task_section/delete_task_section`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const makeSectionAsDefault = async (section_id) => {
  const requestObj = {
    path: `api/task_section/update_is_default/${section_id}`,
    method: "PUT",
    headers: {'x-sh-auth': dc_token()},
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const moveTaskToSection = async (data) => {
  const requestObj = {
    path: `api/task/update_task_section`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateTaskSection = async (data) => {
  const requestObj = {
    path: `api/task_section/edit_task_section`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const allNotesList = async (data) => {
  const requestObj = {
    path: `api/notes/note_list`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addNotes = async (data) => {
  const requestObj = {
    path: `api/notes/add_note`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};


export const editNotes = async (data) => {
  const requestObj = {
    path: `api/notes/edit_note`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteNotes = async (data) => {
  const requestObj = {
    path: `api/notes/delete_note`,
    method: "POST",
    headers: {'x-sh-auth': dc_token()},
    postData: data,
  };
  return invokeApi(requestObj);
};
