import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { logmodeis } from "src/config/config";
import {
  Container,
  TextField,
  Grid,
  CircularProgress,
  IconButton,
  Button,
  Typography,
  FormLabel,
  Stack,
  NativeSelect,
} from "@mui/material";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { addProject, addProjectTeam } from "../../DAL/Project/Project";
import { teamList, getManagerTeam } from "../../DAL/Team/Team";
import CkEditor from "src/components/editor/CKEditor";
import { useSocket } from "src/Hooks/socketContext";
const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

// ============================================ styling=========================

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  projectTypeRadio: {
    "& label": {
      margin: "0px",
    },
    // '& span': {
    //   marginTop: '0.1rem',
    // },
  },
  // give this margin to fields below description
  formControlMargin: {
    marginTop: "8px",
    marginBottom: "4px",
  },
  img: {
    margin: "auto",
    width: "180px",
  },
}));

function AddProject(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getUserProfileData } = useSocket();
  const user = getUserProfileData();
  const [iswaiting, setIswaiting] = useState(false);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState(true);
  const [description, setDescription] = useState("");
  const [member, setMember] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [Image, setimage] = React.useState("");
  const [file, setProfileImage] = React.useState("");
  const [projectType, setProjectType] = useState(false);
  const [projectTemplate, setProjectTemplate] = useState();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMember(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("project_name", name);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("project_description", description);
    formData.append("status", status);
    formData.append("project_visibility_status", projectType);
    formData.append("project_template", projectTemplate);

    if (Image) {
      formData.append("project_image", Image);
    }
    const arr = member.map((val, key) => {
      return val.user_id;
    });
    formData.append(`team`, JSON.stringify(arr));
    let onGoingObj = {
      // token: dc_token,
      project_name: name,
      start_date: startDate,
      end_date: endDate,
      project_description: description,
      status: status,
      project_type: projectType,
      team: arr,
      project_template: projectTemplate,
    };
    if (process.env.NODE_ENV == logmodeis) {
      console.log(onGoingObj, "request-obj-create-proj");
      console.log([...formData], "formData-----");
    }
    const result = await addProjectTeam(formData);
    if (result.code === 200) {
      setIswaiting(true);
      if (process.env.NODE_ENV == logmodeis) {
        console.log(result, "response");
      }
      // setData(result.Project_list);
      navigate(-1);
      setIswaiting(false);
    } else {
      setIswaiting(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fileChangedHandler = async (e) => {
    // setiswaiting(true);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setimage(e.target.files[0]);
    // let formData = new FormData(); //formdata object
    // formData.append("directory", "images/");
    // formData.append("image", e.target.files[0]);
    // const upload_image_resp = await upload_image(formData);
    // console.log(upload_image_resp);
    // setimage(upload_image_resp.path);
    // if(upload_image_resp.code == 200){
    //   setiswaiting(false);
    // }
  };
  useEffect(async () => {
    const formData = new FormData();

    // const result = await teamList(formData);
    // if (result.code === 200) {
    //   setMembersData(result.Team_member_list);
    // } else {
    //   enqueueSnackbar(result.message, { variant: 'error' });
    // }

    // department_id: "0"
    // first_name: "Bilal"
    // image: "uploads/team/20220107062017_--sword3.jpg"
    // last_name: "Shahid"
    // role: "Developer"
    // status: "1"
    // user_id: "53"
    const result = await getManagerTeam(formData);
    if (result.code === 200) {
      if (process.env.NODE_ENV == logmodeis) {
        console.log(result, "res");
      }
      setMembersData(result.manager_team_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  let is_allow = user.allow_project;
  if (!is_allow) {
    return <Navigate to="/projects/categories" />;
  }
  if (is_allow) {
    if (is_allow === false) {
      return <Navigate to="/projects/categories" />;
    }
  }
  return (
    <>
      <Container maxWidth="lg">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ mb: 5 }} style={{ textAlign: "center" }}>
          Add Project
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                required
                margin="dense"
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="name"
                label="Name"
                type="name"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                id="name"
                label="Start Date"
                type="date"
                fullWidth
                variant="outlined"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                required
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                id="name"
                label="End Date"
                type="date"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormLabel>Project Description :</FormLabel>
              <div className="project-description">
                <CkEditor
                  content={description}
                  setContent={(value) => setDescription(value)}
                />
              </div>
              {/* <TextField
                margin='dense'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id='name'
                label='Description'
                type='description'
                fullWidth
                variant='outlined'
                multiline
                rows={4}
              /> */}
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center">
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Project type
                  </InputLabel>
                  <NativeSelect
                    variant="outlined"
                    className={classes.formControlMargin}
                    value={projectType}
                    onChange={(e) => setProjectType(e.target.value)}
                    defaultValue="true"
                    inputProps={{
                      name: "Project type",
                      id: "controlled-native",
                    }}
                  >
                    <option value={true}>Team</option>
                    <option value={false}>Personal</option>
                  </NativeSelect>
                </FormControl>
              </Stack>
            </Grid>
            {projectType === "true" && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-chip-label">
                    Team Member
                  </InputLabel>
                  <Select
                    className={classes.formControlMargin}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    required={projectType === "true" ? true : false}
                    multiple
                    value={member}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Team Member"
                      />
                    }
                    renderValue={(selected) => (
                      <>
                        {selected.map((value) => (
                          <Chip key={value.id} label={value.first_name} />
                        ))}
                      </>
                    )}
                  >
                    {membersData.map((x, i) => (
                      <MenuItem key={i} value={x}>
                        {x.first_name} {(" ", x.last_name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {file ? (
              <Grid item xs={12}>
                <>
                  <Box className="image-size">
                    <img className={classes.img} src={file} alt="team member" />
                  </Box>
                </>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <input
                className={classes.formControlMargin}
                accept="image/png,image/jpeg,image/jpg"
                hidden
                id="contained-button-file"
                multiple
                type="file"
                onChange={fileChangedHandler}
              />
              <label
                htmlFor="contained-button-file"
                style={{ display: "flex" }}
              >
                <Button
                  className={classes.formControlMargin}
                  component="span"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  startIcon={<DriveFolderUploadIcon />}
                >
                  Upload Image
                </Button>
              </label>
            </Grid>
            <Grid item xs={6}>
              Status :
              <Switch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button variant="contained" type="submit">
                Add Project
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default AddProject;
